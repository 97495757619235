import "jquery-ui/ui/widgets/autocomplete";
import { C } from "../../common/component";
import { Helper } from "../../common/helper";
import { Requester } from "../../common/requester";
import { EditControl } from "../form-row";

export class SuggestionsEditControl implements EditControl {
    private row: JQuery;
    private input: JQuery;

    constructor(typeData, getRowValue: (rowName: string) => any) {
        this.row = C.div.addClass("ui-front");
        this.input = C.input.addClass("form-control").prop("type", "text").appendTo(this.row);

        this.input.autocomplete({
            source: async (request, response) => {
                const params = { search: request.term };
                for (const parameter of typeData.SearchUrl.Parameters) {
                    const value = getRowValue(parameter.RowName);
                    if (Helper.hasValue(value)) {
                        params[parameter.Name] = value;
                    }
                }
                response(await Requester.get(typeData.SearchUrl.Url, {
                    delay: 250,
                    cache: true,
                    data: params
                }));
            },
            minLength: 0
        }).focus(() => {
            if (!Helper.hasValue(this.input.val().toString())) {
                this.input.autocomplete("search");
            }
        });
    }

    initialize(parent: HTMLElement) {
        parent.append(this.row[0]);
    }

    async setValue(value) {
        this.input.val(value);
    }

    getValue() {
        return this.input.val().toString();
    }

    onChange() {
    }

    focus() {
        this.input.focus();
    }
}
