import * as $ from "jquery";
import { Icon, IconHelper } from "./icon-helper";

export class C {
    static get body() {
        return $("body");
    }

    static get br() {
        return $("<br>");
    }

    static get nav() {
        return $("<nav />");
    }

    static get div() {
        return $("<div />");
    }

    static get button() {
        return $("<button />");
    }

    static get buttonsGroup() {
        return C.div.addClass("btn-group");
    }

    static get canvas() {
        return $("<canvas />");
    }

    static get img() {
        return $("<img />");
    }

    static get h4() {
        return $("<h4 />");
    }

    static get h5() {
        return $("<h5 />");
    }

    static get input() {
        return $("<input />");
    }

    static get a() {
        return $("<a />");
    }

    static get span() {
        return $("<span />");
    }

    static get li() {
        return $("<li />");
    }

    static get ul() {
        return $("<ul />");
    }

    static get label() {
        return $("<label />");
    }

    static get p() {
        return $("<p />");
    }

    static get table() {
        return $("<table />");
    }

    static get thead() {
        return $("<thead />");
    }

    static get tbody() {
        return $("<tbody />");
    }

    static get tr() {
        return $("<tr />");
    }

    static get th() {
        return $("<th />");
    }

    static get td() {
        return $("<td />");
    }

    static get legend() {
        return $("<legend />");
    }

    static get form() {
        return $("<form />").attr("role", "form").attr("novalidate", "true");
    }

    static get object() {
        return $("<object />");
    }

    static get select() {
        return $("<select />");
    }

    static get option() {
        return $("<option />");
    }

    static get textarea() {
        return $("<textarea />");
    }

    static get fieldset() {
        return $("<fieldset />");
    }

    static get i() {
        return $("<i />");
    }

    static get b() {
        return $("<b />");
    }

    static get map() {
        return $("<map />");
    }

    static get area() {
        return $("<area />");
    }

    static get container(): JQuery {
        return C.div.addClass("container quickways");
    }

    static get row(): JQuery {
        return C.div.addClass("row relative");
    }

    static get col(): JQuery {
        return C.div.addClass("col-md-6 zero-padding");
    }

    static column(width: number, useZeroPadding: boolean): JQuery {
        return C.div.addClass(`col-md-${width} ${useZeroPadding ? "zero-padding" : ""}`);
    }

    static get panel(): JQuery {
        return C.div.addClass("panel panel-default statistics");
    }

    static get header(): JQuery {
        return C.h4.addClass("statistics-title");
    }

    static circularRightButton(icon: Icon, distanceFromTop?: number): JQuery {
        return C.div.addClass("btn btn-primary absolute-button")
            .css("top", ((distanceFromTop ?? 0) - 15).toString() + "px")
            .css("right", "-15px").append(IconHelper.getIcon(icon));
    }

    static circularLeftButton(icon: Icon, distanceFromTop?: number): JQuery {
        return C.div.addClass("btn btn-primary absolute-button")
            .css("top", ((distanceFromTop ?? 0) - 15).toString() + "px")
            .css("left", "-15px").append(IconHelper.getIcon(icon));
    }

    static normalButton(icon: Icon) {
        return C.div.addClass("btn btn-primary normal-btn").append(IconHelper.getIcon(icon));
    }

    static getPrimaryButton(text: string, onClick: () => void) {
        return C.button.addClass("btn btn-primary main-button").attr("type", "button").text(text).click(onClick);
    }

    static get plusButton() {
        return C.div.addClass("img-circle clickable plus-button")
            .append(IconHelper.getIcon(Icon.Plus).addClass("plus-inside-button"));
    }

    static get formGroup() {
        return C.div.addClass("form-group");
    }

    static get buttonGroup() {
        return C.div.addClass("button-group");
    }

    static get small() {
        return $("<small/>");
    }

    static get focusedTextArea() {
        return $("textarea:focus");
    }

    static get clockpickerHours() {
        return $(".clockpicker-span-hours");
    }

    static get clockpickerMinutes() {
        return $(".clockpicker-span-minutes");
    }

    static get openedDropdown() {
        return $(".dropdown.open");
    }

    static get window() {
        return $(window);
    }

    static get navbar() {
        return $(".navbar");
    }

    static get navEl() {
        return $(".nav");
    }

    static get leftTable() {
        return $(".left-table");
    }

    static get rightTable() {
        return $("table.table-bordered.table-sm.schedule-table");
    }

    static get hotTableHeader() {
        return $(".htCore thead");
    }

    static get hotClone() {
        return $(".ht_clone_top.handsontable");
    }

    static create(element: HTMLElement) {
        return $(element);
    }
}
