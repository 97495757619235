import { Chart, ChartTableCreator } from "../../common/chart-table-creator";
import { C } from "../../common/component";
import { DashboardPanelContent } from "./dashboard-panel";

export class ChartPanel implements DashboardPanelContent {
    private readonly chartContainer = C.div;
    private getData: () => Promise<Chart>;

    appendTo(parent: JQuery, getData: () => Promise<any>) {
        this.getData = getData;
        this.chartContainer.appendTo(parent);
    }

    async refresh(): Promise<void> {
        this.chartContainer.empty();
        const data = await this.getData();
        if (data != null) {
            ChartTableCreator.createChart(this.chartContainer, data);
        } else {
            // Show error
        }
    }
}
