import { C } from "../../common/component";
import { DashboardPanelContent } from "./dashboard-panel";
import { ItemsMap } from "../../maps/items-map";

export class MapPanel implements DashboardPanelContent {
    private readonly bigVersion: boolean;
    private map: ItemsMap;
    private getData: () => Promise<any>;

    constructor(bigVersion: boolean) {
        this.bigVersion = bigVersion;
    }

    appendTo(parent: JQuery, getData: () => Promise<any>) {
        this.getData = getData;
        const container = C.div.appendTo(parent);
        if (this.bigVersion) {
            container.height(800);
        } else {
            container.height(300);
        }
        this.map = new ItemsMap();
        this.map.appendTo(container);
    }

    async refresh(): Promise<void> {
        const data = await this.getData();
        await this.map.refresh(null, data);
    }
}
