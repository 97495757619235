import { Application } from "./application";
import { C } from "../common/component";
import { Txt } from "../common/text";
import { Helper } from "../common/helper";
import { Icon, IconHelper } from "../common/icon-helper";
import { Notifications } from "../common/notifications";
import { AuthenticationHandler } from "../common/authentication-handler";
import { TimerService } from "../timer/timer-service";
import { TimerButton } from "../timer/timer-button";
import { MenuBuilder } from "./menu-builder";
import { PageInfo, PageNavigator } from "./page-navigator";
import { SearchBox } from "./search-box";
import { DomainHelper } from "../common/domain-helper";
import { Loader } from "../common/loader";
import { Pwa } from "./pwa";

interface DropdownItem {
    link: any;
    menu: any;
}

interface NavbarItem {
    left: any;
    right: any;
}

// ReSharper disable InconsistentNaming
export interface NavigationItem {
    Name: string;
    DisplayName: string;
    Icon: string;
    Pages: string[];
}

export interface NavigationBarItem {
    DisplayName: string;
    Icon: string;
    Children: NavigationItem[];
}
// ReSharper restore InconsistentNaming

export class Navbar {
    private readonly data: NavigationBarItem[];
    private readonly hasTimer: boolean;
    private readonly timerPage: string;
    private readonly pages: PageInfo[];
    private readonly profileData;
    private readonly notificationsPage: string;

    constructor(data: NavigationBarItem[], hasTimer: boolean, timerPage: string, pages: PageInfo[], profileData,
                notificationsPage: string) {
        this.data = data;
        this.hasTimer = hasTimer;
        this.timerPage = timerPage;
        this.pages = pages;
        this.profileData = profileData;
        this.notificationsPage = notificationsPage;
    }

    appendTo(parent) {
        const mainBody = C.div.appendTo(parent);

        const {left, right} = this.createNavbar(C.div.addClass("container")
            .appendTo(C.nav.addClass("navbar navbar-default").appendTo(mainBody)));
        Application.mainElement.appendTo(mainBody);
        Application.secondaryElement.appendTo(mainBody);

        this.createLeftMenu(left);
        this.createRightMenu(right);
    }

    private createLeftMenu(parent): void {
        for (const item of this.data) {
            const li = C.li.addClass("dropdown").appendTo(parent);
            const link = C.a.addClass("dropdown-toggle").attr("data-toggle", "dropdown").attr("role", "button")
                .attr("aria-haspopup", "true").attr("aria-expanded", "false")
                .appendTo(li);
            IconHelper.addIconWithText(link, Icon[item.Icon], item.DisplayName);
            const dropdown = C.ul.addClass("dropdown-menu").appendTo(li);
            const increment = 100 / item.Children.length;
            let percentage = 0;
            for (const child of item.Children) {
                MenuBuilder.createMenuElement(child, dropdown, percentage);
                percentage += increment;
            }
        }
    }

    private async createRightMenu(parent): Promise<void> {
        new SearchBox(this.pages).appendTo(parent);
        const dropdown = this.createDropdown(parent);

        dropdown.link.addClass("user-menu");
        C.img.addClass("img-circle img-responsive").attr("src", this.profileData.photo || "images/profile.png")
            .appendTo(dropdown.link);
        const nameElement = C.span.text(this.profileData.name || Txt.nameless).appendTo(dropdown.link);
        let redDot;
        if (this.profileData.hasRedDot) {
            redDot = C.i.css("background-color", "red").css("position", "absolute")
                .css("top", "15px").css("right", "8px")
                .css("width", "8px").css("height", "8px").css("border-radius", "100%").appendTo(nameElement);
        }
        for (const pageName of this.profileData.pages) {
            this.createMenuItem(dropdown.menu, PageNavigator.getDisplayName(pageName), PageNavigator.getIcon(pageName), () => {
                if (this.profileData.redDotPage === pageName) {
                    redDot?.hide();
                }
                PageNavigator.navigateToPage(pageName);
            });
        }
        if (Helper.isMobile() && !Helper.isPWA()) {
            this.createMenuItem(dropdown.menu, Txt.downloadApp, Icon.download, Pwa.downloadApp);
        }
        this.createMenuItem(dropdown.menu, Txt.logout, Icon.off, () => {
            AuthenticationHandler.logout();
            Helper.refreshPage();
        });

        Notifications.initialize(parent, this.notificationsPage);

        if (this.hasTimer) {
            TimerService.button = new TimerButton(C.li.appendTo(parent));
            TimerService.timerPageName = this.timerPage;
            TimerService.updateCurrentState();
        }
        Loader.stopGlobally();
    }

    private createNavbar(parent): NavbarItem {
        const header = C.div.addClass("navbar-header").appendTo(parent);

        const menuButton = C.button.addClass("navbar-toggle collapsed")
            .attr("type", "button")
            .attr("data-toggle", "collapse")
            .attr("data-target", "#navbar")
            .attr("aria-expanded", "false")
            .attr("aria-controls", "navbar")
            .appendTo(header);
        C.span.addClass("sr-only").text("Menu").appendTo(menuButton);
        C.span.addClass("icon-bar").appendTo(menuButton);
        C.span.addClass("icon-bar").appendTo(menuButton);
        C.span.addClass("icon-bar").appendTo(menuButton);

        C.img.attr("src", DomainHelper.getDomain() === "rbe" ? "/images/rbelogo.svg" : "/images/logo.png")
            .attr("alt", "Wemply_official_logo")
            .attr("height", "50px")
            .appendTo(C.a.addClass("navbar-brand brand-image")
                .click(() => PageNavigator.navigateToHome()).appendTo(header));
        const smallCompanyName = C.small
            .css("display", "block")
            .css("font-size", "50%")
            .css("text-align", "right")
            .text(DomainHelper.getDomain());
        if (DomainHelper.getDomain() !== "rbe") {
            C.a.addClass("navbar-brand companyname").text("Wemply").append(smallCompanyName)
                .click(() => PageNavigator.navigateToHome()).appendTo(header);
        }

        const navbarElement = C.div.addClass("navbar-collapse collapse").attr("id", "navbar").appendTo(parent);

        return {
            left: C.ul.addClass("nav navbar-nav").appendTo(navbarElement),
            right: C.ul.addClass("nav navbar-nav navbar-right").appendTo(navbarElement)
        };
    }

    private createDropdown(parent): DropdownItem {
        const li = C.li.addClass("dropdown").appendTo(parent);
        return {
            link: C.a.addClass("dropdown-toggle usernameButton")
                .attr("data-toggle", "dropdown")
                .attr("role", "button")
                .attr("aria-haspopup", "true")
                .attr("aria-expanded", "false")
                .appendTo(li),
            menu: C.ul.addClass("dropdown-menu").appendTo(li)
        };
    }

    private createMenuItem(parent, text: string, icon: Icon, callback): void {
        const item = C.li.appendTo(parent);
        const link = C.a.appendTo(item);
        IconHelper.addIconWithText(link, icon, text);

        if (Helper.isMobileScreen()) {
            link.attr("data-toggle", "collapse").attr("data-target", "#navbar");
        }
        link.click(callback);
    }
}
