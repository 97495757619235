import { Form, FormDisplayer } from "../forms/form";
import { ViewPageCreator, ViewPageInfo } from "../views/view-page-creator";
import { FormAndViewPage } from "../common/form-and-view-page";
import { EditableTable } from "../common/editable-table";
import { TimerService } from "../timer/timer-service";
import { TimerPage } from "../timer/timer-page";
import { PageInfo, PageType } from "./page-navigator";
import { ReportsPage } from "../common/reports-page";
import { EmployeesView } from "../dashboard/employees-view";
import { ItemPage } from "../dashboard/item-page";
import { Dashboard } from "../dashboard/dashboard";
import { TimesheetPageCreator } from "../timesheets/timesheet-page-creator";
import { GraphCreator } from "../common/graph-creator";
import { C } from "../common/component";
import { ChartTableCreator } from "../common/chart-table-creator";
import {FormComponent} from "../forms/form-component";

export class PageBuilder {

    static async build(info: PageInfo, parent: any) {
        switch (info.Type) {
            case PageType.Form:
                const row = C.row.addClass("panel panel-default").appendTo(C.container.appendTo(parent));
                const panel = C.column(12, false).appendTo(row);
                await this.createForm(info.Url, panel);
                break;
            case PageType.View:
                await ViewPageCreator.createViewPage(<ViewPageInfo>info, parent);
                break;
            case PageType.EmployeesView:
                await new EmployeesView(info.Url).appendTo(parent);
                break;
            case PageType.Dashboard:
                await Dashboard.create(info.Url, parent);
                break;
            case PageType.FormAndView:
                const formAndViewPage = new FormAndViewPage(info);
                await formAndViewPage.appendTo(parent);
                break;
            case PageType.PrincipalsGraph:
                await new GraphCreator(info.Url).appendTo(parent);
                break;
            case PageType.Timer:
                const timerPage = new TimerPage(parent);
                await timerPage.addLastWorkingTimesPanel(info.Url);
                TimerService.page = timerPage;
                await TimerService.updateCurrentState();
                break;
            case PageType.Profile:
                await new ItemPage(info.Url, null).appendTo(parent);
                break;
            case PageType.Timesheet:
                const form = await this.createPageWithCompactForm(info.Url, parent, (data, mainArea) => timesheetPage.show(data, mainArea));
                const timesheetPage = new TimesheetPageCreator(form);
                break;
            case PageType.Report:
                await this.createPageWithCompactForm(info.Url, parent, ReportsPage.build);
                break;
            case PageType.Chart:
                await this.createPageWithCompactForm(info.Url, parent, (data, mainArea) => ChartTableCreator.createChart(mainArea, data));
                break;
            case PageType.EditableTable:
                await this.createPageWithCompactForm(info.Url, parent, EditableTable.build);
                break;
        }
    }

    private static async createForm(url: string, parent: any): Promise<void> {
        const form = new Form(url);
        form.onDataChanged = () => {
            parent.empty();
            this.createForm(url, parent);
        };
        await FormDisplayer.addForm(form, parent);
    }

    private static async createPageWithCompactForm(url: string, parent: any, mainAction: (data: any, mainArea: any, formDiv?: any) => void): Promise<FormComponent> {
        const formDiv = C.div.addClass("row schedule print-no").appendTo(parent);
        const mainArea = C.div.appendTo(parent);
        const form = new Form(url);
        form.isCompact = true;
        form.mainAction = data => {
            mainArea.empty();
            mainAction(data, mainArea, formDiv);
        };
        return await FormDisplayer.addForm(form, formDiv);
    }
}
