import { Icon, IconHelper } from "../common/icon-helper";
import { Txt } from "../common/text";
import { C } from "../common/component";
import { TimerService } from "./timer-service";

export class TimerButton {
    private readonly timerButton: JQuery;

    constructor(parent: JQuery) {
        this.timerButton =
            C.button.addClass("btn btn-primary navbar-btn timer").attr("type", "button").appendTo(parent);
        this.setState(false);
        this.timerButton.click(() => TimerService.buttonClicked());
    }

    setState(isStarted: boolean): void {
        if (isStarted) {
            this.timerButton.attr("title", Txt.stopLogging);
        } else {
            this.timerButton
                .html(`<span class="${IconHelper.getIconClass(Icon.Play)}"/><span class="mobile"> ${Txt.startLogging}</span>`)
                .attr("title", Txt.startLogging);
        }
    }

    setElapsedTimeText(text: string, isRunning: boolean): void {
        this.timerButton.html( `<span class="${IconHelper.getIconClass(isRunning ? Icon.pause : Icon.Play)}"/><span class="timer-text"> ${text}</span>`);
    }
}
