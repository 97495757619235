import { C } from "../common/component";
import { Txt } from "../common/text";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { NotificationModel, Notifications } from "../common/notifications";
import { Icon, IconHelper } from "../common/icon-helper";
import { Helper } from "../common/helper";
import { NavigationItem } from "../navigation/navbar";
import { PageNavigator } from "../navigation/page-navigator";
import { Form, FormDisplayer } from "../forms/form";
import { DashboardPanel } from "./panels/dashboard-panel";
import { Requester } from "../common/requester";

interface DashboardShortcutItem {
    Item: NavigationItem;
    Description: string;
}

interface DashboardPageInfo {
    Shortcuts: DashboardShortcutItem[];
    PanelsUrls: string[];
    SettingsUrl: string;
    ShowNotifications: boolean;
}

export class Dashboard {
    static async create(url: string, parent): Promise<void> {
        const data: DashboardPageInfo = await Requester.get(url);
        const container = C.container.appendTo(parent);
        const separatePageContainer = C.container.appendTo(parent);

        this.addSettings(container, data.SettingsUrl);

        if (data.ShowNotifications) {
            const notificationsRow = C.row.appendTo(container);
            const notificationsPanel = C.div.addClass("panel panel-default").appendTo(notificationsRow);
            const notificationsBody = C.div.css("padding", "15px").appendTo(notificationsPanel);
            IconHelper.addIconWithText(C.h4.appendTo(notificationsBody), Icon.Bell, Txt.notifications);
            const notificationsElement = C.div.appendTo(notificationsBody);
            Notifications.additionalProcessingAction = (notifications: NotificationModel[]) => {
                notificationsElement.empty();
                for (const notification of notifications.slice(0, 5)) {
                    C.p.html((notification.IsSeen ? "" : "<b>") + Helper.removeScriptTags(notification.Message) +
                        (notification.IsSeen ? "" : "</b>") + " (" + notification.CreationTime + ")<br>").appendTo(notificationsElement);
                }
            };
            Notifications.refresh();
        }

        const shortcutsRow = C.row.appendTo(container);
        for (let i = 0; i < data.Shortcuts.length; i++) {
            const panel = this.createShortcut(data.Shortcuts[i]);
            const width = this.getShortcutPanelWidth(i, data.Shortcuts.length);
            panel.addClass(`col-md-${width}`);
            shortcutsRow.append(panel);
        }

        const otherPanelsRow = C.row.appendTo(container);
        const currentPage = NavigationUrlHelper.getCurrentPage();
        for (const panelUrl of data.PanelsUrls) {
            const panel = new DashboardPanel(panelUrl);
            panel.registerChangeDisplayingWayCallback(() => {
                this.openInSeparatePage(panelUrl, container, separatePageContainer);
            });
            panel.appendTo(otherPanelsRow);
            if (currentPage.subPage === panelUrl) {
                this.openInSeparatePage(panelUrl, container, separatePageContainer);
            }
        }
    }

    private static addSettings(parent, url: string) {
        const row = C.row.appendTo(parent);
        const button = C.circularRightButton(Icon.Cog).appendTo(row);
        button.click(() => {
            const form = new Form(url);
            form.onDataChanged = () => Helper.refreshPage();
            FormDisplayer.openInModal(form);
        });
    }

    private static openInSeparatePage(panelUrl: string, container, sepratePageContainer) {
        container.hide();
        NavigationUrlHelper.setSubPage(panelUrl);
        const panel = new DashboardPanel(panelUrl, true);
        panel.registerChangeDisplayingWayCallback(() => {
            sepratePageContainer.empty();
            container.show();
            NavigationUrlHelper.setSubPage(null);
        });
        panel.appendTo(sepratePageContainer);
    }

    private static createShortcut(data: DashboardShortcutItem) {
        const column = C.div.addClass(`col-xs-12 col-sm-6`);
        const panel = C.div.addClass("panel panel-default").appendTo(column);
        const panelBody = C.div.addClass("panel-body").appendTo(panel);

        IconHelper.addIconWithText(C.h4.appendTo(panelBody), Icon[data.Item.Icon], data.Item.DisplayName);
        if (data.Description) {
            C.p.html(Helper.removeScriptTags(data.Description)).appendTo(panelBody);
        }
        panel.addClass("hover-panel").click(async () => PageNavigator.navigateToPage(data.Item.Pages[0], data.Item.Name));
        return column;
    }

    private static getShortcutPanelWidth(panelNr: number, panelsCount: number) {
        if (panelsCount === 1) {
            return 12;
        }
        if (panelsCount === 2) {
            return 6;
        }
        if (panelsCount === 5) {
            if (panelNr < 2) {
                return 6;
            }

            return 4;
        }
        const rowsCount = Math.floor((panelsCount + 3) / 4);
        const missingPanels = rowsCount * 4 - panelsCount;
        if (missingPanels * 3 > panelNr) {
            return 4;
        } else {
            return 3;
        }
    }
}
