import * as moment from "moment";
import { C } from "../../common/component";
import { Txt } from "../../common/text";
import { EditControl } from "../form-row";

export class DateRangeEditControl implements EditControl {
    private readonly isRequired: boolean;
    private readonly input: JQuery;
    private readonly inputRow: JQuery;
    private onChangeAction;

    constructor(isRequired: boolean) {
        this.isRequired = isRequired;
        this.inputRow = C.div;
        this.input = C.input.addClass("form-control").appendTo(
            C.div.addClass("input-group date").appendTo(
                this.inputRow
            )
        );

        this.input.daterangepicker(this.settings, (start, end) => {
            if (this.onChangeAction) {
                this.onChangeAction(`${start}|${end}`);
            }
        });
        if (!this.isRequired) {
            // Needed for picker to work when autoUpdateInput setting is false
            this.input.on("apply.daterangepicker", (ev, picker) => {
                this.input.val(picker.startDate.format("DD.MM.YYYY") + " - " + picker.endDate.format("DD.MM.YYYY"));
            });
        }
    }

    private get settings() {
        const settings = {
                ranges: {},
                showCustomRangeLabel: false,
                alwaysShowCalendars: true,
                autoApply: true,
                locale: {
                    firstDay: 1,
                    cancelLabel: Txt.cancel,
                    applyLabel: Txt.edit,
                    daysOfWeek: Txt.dayNamesShort,
                    monthNames: Txt.monthNames,
                    format: "DD.MM.YYYY",
                },
                autoUpdateInput: this.isRequired // Needed for picker to be empty initially and allow making empty afterwards
            };

        settings.ranges[Txt.rangeYesterday] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
        settings.ranges[Txt.rangeToday] = [moment(), moment()];
        settings.ranges[Txt.rangeTomorrow] = [moment().add(1, "days"), moment().add(1, "days")];
        settings.ranges[Txt.rangeLast7days] = [moment().subtract(6, "days"), moment()];
        settings.ranges[Txt.rangeNext7days] = [moment(), moment().add(6, "days")];
        settings.ranges[Txt.rangeLastMonth] =
            [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
        settings.ranges[Txt.rangeCurrentMonth] = [moment().startOf("month"), moment().endOf("month")];
        settings.ranges[Txt.rangeNextMonth] = [moment().add(1, "month").startOf("month"), moment().add(1, "month").endOf("month")];

        return settings;
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow[0]);
    }

    async setValue(value) {
        if (value) {
            const parts = value.split("|");
            this.input.data("daterangepicker").setStartDate(parts[0]);
            this.input.data("daterangepicker").setEndDate(parts[1]);
            if (!this.isRequired) {
                // Needed when autoUpdateInput setting is false
                this.input.val(parts[0] + " - " + parts[1]);
            }
        } else if (this.isRequired) {
            this.input.data("daterangepicker").setStartDate(moment().subtract(29, "days"));
            this.input.data("daterangepicker").setEndDate(moment());
        } else {
            this.input.val("");
        }
    }

    getValue() {
        if (!this.input.val() || this.input.val() === "") {
            return null;
        }
        const startDate = this.input.data("daterangepicker").startDate.format("DD.MM.YYYY");
        const endDate = this.input.data("daterangepicker").endDate.format("DD.MM.YYYY");
        return `${startDate}|${endDate}`;
    }

    onChange(action) {
        this.onChangeAction = action;
    }

    focus() {
        this.input.focus();
    }
}
