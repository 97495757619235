import { C } from "../../../common/component";
import { Icon, IconHelper } from "../../../common/icon-helper";
import { Txt } from "../../../common/text";
import { FileUploader } from "./file-uploader";

export class FileProgressControls {
    private readonly iconAddon: JQuery;
    private readonly successIcon: JQuery;
    private readonly errorIcon: JQuery;
    private readonly progressBarContainer: JQuery;
    private readonly progressBar: JQuery;

    constructor(iconParent, progressBarParent) {
        this.iconAddon = C.span.addClass("input-group-addon").appendTo(iconParent);
        this.successIcon = C.span.appendTo(this.iconAddon);
        this.errorIcon = C.span.appendTo(this.iconAddon).hide();
        IconHelper.addColorfulIcon(this.successIcon, Icon.Check, "green");
        IconHelper.addColorfulIcon(this.errorIcon, Icon.X, "red");

        this.progressBarContainer = C.div.addClass("progress").css("margin-bottom", "0").appendTo(progressBarParent).hide();
        this.progressBar = C.div.addClass("progress-bar progress-bar-striped active").attr("role", "progressbar")
            .attr("aria-valuemin", "2").attr("aria-valuemax", "100").appendTo(this.progressBarContainer);
    }

    hide() {
        this.iconAddon.hide();
        this.progressBarContainer.hide();
    }

    onProgress(file: FileUploader) {
        if (file.hasError()) {
            this.successIcon.hide();
            this.errorIcon.show();
            this.iconAddon.prop("title", Txt.fileUploadFailed);
            this.iconAddon.show();
            this.progressBarContainer.hide();
        } else if (file.isFinished()) {
            this.errorIcon.hide();
            this.successIcon.show();
            this.iconAddon.prop("title", Txt.fileUploadSuccessful);
            this.iconAddon.show();
            this.progressBarContainer.hide();
        } else {
            this.iconAddon.hide();
            let rounded = Math.round(file.getPercent());
            if (rounded < 2) {
                rounded = 2;
            }
            this.progressBar.prop("style", `width: ${rounded}%`);
            this.progressBar.prop("aria-valuenow", rounded);
            this.progressBarContainer.show();
        }
    }
}
