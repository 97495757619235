import { C } from "../common/component";
import { ModalBuilder } from "../common/modal-builder";
import { Requester } from "../common/requester";
import { Txt } from "../common/text";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { FormComponent } from "./form-component";
import { Icon } from "../common/icon-helper";
import { Dialog, Message, MessageDisplayer } from "../common/message-displayer";

// ReSharper disable InconsistentNaming
export interface FormModel {
    Item: { [rowName: string]: string };
    ChoicesDisplayValues: { [choiceId: string]: string };
    Rows: FormRowGroupModel[];
    Buttons: FormButtonModel[][];
    Title: string;
    Description: string;
}

export interface FormRowGroupModel {
    Name: string;
    Collapse: boolean;
    Children: FormRowModel[];
}

export interface FormRowModel {
    Name: string;
    MainData: FormRowMainData;
    TypeData;
    DisplayIf: { [rowName: string]: MatchValue };
    ShouldDisplayUrl;
}

export interface FormRowMainData {
    DisplayName: string;
    IsRequired: boolean;
    Tooltip: string;
    Unit: string;
    Description: string;
}

export interface MatchValue {
    Values: string[];
    IsNotIn: boolean;
    IsSubstring: boolean;
}

export interface FormButtonModel {
    Behavior: FormButtonBehavior;
    Name: string;
    Url: string;
    Color: string;
    Icon: Icon;
    ConfirmationMessage: string;
    ConfirmationUrl: string;
    Type: string;
    MainButton: boolean;
    TriggerOnChange: boolean;
    UseLoader: boolean;
}

export interface FormActionResult {
    Value: any;
    Message: Message;
    Dialog: Dialog;
    NewFormUrl: string;
    NewDialogTableUrl: string;
    MultipleItemsEdited: boolean;
    File: FormActionResultFile;
    RefreshPage: boolean;
    SoftRefreshPage: boolean;
    TriggerMainAction: boolean;
}

export interface FormActionResultFile {
    Name: string;
    Content: string;
}
// ReSharper restore InconsistentNaming

export enum FormButtonBehavior {
    Submit = "Submit",
    Click = "Click",
    CloseForMessage = "CloseForMessage",
    OpenModal = "OpenModal",
    OpenModalWithDataTransfer = "OpenModalWithDataTransfer",
    FileDownload = "FileDownload",
    Custom = "Custom",
    OpenViewSettings = "OpenViewSettings",
    OpenDialogTable = "OpenDialogTable"
}

export class Form {
    readonly url: string;
    defaultItem: { [rowName: string]: string };

    onDataChanged: (value, multipleItemsEdited: boolean) => void;
    onSave: (item) => void;
    mainAction: (item: any) => void;
    customAction: (item: { [rowName: string]: string }, type: string) => void;

    isCompact = false;

    constructor(url) { this.url = url; }
}

export class FormDisplayer {
    static async addForm(form: Form, parent): Promise<FormComponent> {
        const data = await this.getData(form);
        if (data) {
            const component = new FormComponent(form, () => {});
            await component.createForm(parent, data);
            return component;
        }
        return null;
    }

    static async openInModal(form: Form) {
        const data = await this.getData(form);
        if (!data) {
            return;
        }
        const modal = ModalBuilder.createModal(data.Title);
        modal.container.modal({ backdrop: "static", keyboard: true, show: true, });

        function resetUrl() {
            const currentPage = NavigationUrlHelper.getCurrentPage();
            if (currentPage.formUrl) {
                currentPage.formUrl = null;
                NavigationUrlHelper.setCurrentPage(currentPage);
            }
        }

        const closeButton = C.button
            .addClass("btn btn-default pull-right margin-top-5")
            .attr("type", "button")
            .attr("data-dismiss", "modal")
            .text(Txt.close);
        closeButton.click(resetUrl);
        modal.closeButton.click(resetUrl);

        const component = new FormComponent(form, () => {
            ModalBuilder.removeModal(modal.container);
            resetUrl();
        });
        await component.createForm(modal.body, data, closeButton);
    }

    private static async getData(form: Form): Promise<FormModel> {
        try {
            const data = await Requester.get(form.url);
            if (form.defaultItem) {
                for (const name in form.defaultItem) {
                    data.Item[name] = form.defaultItem[name];
                }
            }
            return data;
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
            return null;
        }
    }
}
