import { Form, FormDisplayer } from "../forms/form";
import { Urls } from "../common/urls";
import { LocationProvider } from "./location-provider";
import { Requester } from "../common/requester";
import { MessageDisplayer } from "../common/message-displayer";
import { TimerButton } from "./timer-button";
import { SeatingPlan } from "../common/seating-plan";
import { TimerPage } from "./timer-page";
import { PageNavigator } from "../navigation/page-navigator";

interface TimerState {
    Id: number;
    IsRunning: boolean;
    IsPaused: boolean;
    ElapsedSeconds: number;
    PauseSeconds: number;
    Url: string;
    StartFormUrl: string;
    SeatingPlanUrl: string;
    PageEndFormUrl?: string;
    ActiveTimerFormItem?: { [rowName: string]: string };
    PauseText: string;
}

export class TimerService {
    public static button: TimerButton;
    public static page: TimerPage;
    public static timerPageName: string;

    private static interval: any;
    private static currentId: number;
    private static isRunning: boolean;

    static async buttonClicked() {
        const currentId = this.currentId;
        const isRunning = this.isRunning;
        const newState = await this.updateCurrentState();
        // Do not do anything if ID or run state is different because it means that timer has been changed from somewhere else
        if (newState && newState.Id === currentId && newState.IsRunning === isRunning) {
            if (newState.IsRunning) {
                if (this.timerPageName) {
                    PageNavigator.navigateToPage(this.timerPageName);
                } else {
                    await this.save(newState);
                }
            } else {
                await this.start(newState);
            }
        }
    }

    private static async save(state: TimerState) {
        const form = new Form(state.Url);
        form.onDataChanged = () => {
            // Set timer state to empty so that user sees right away that timer has been ended and
            // in case there is error during updateCurrentState we will still show that timer has ended
            this.setTimerState();
            this.updateCurrentState();
        };
        FormDisplayer.openInModal(form);
    }

    private static async start(state: TimerState) {
        if (state.StartFormUrl) {
            const form = new Form(state.StartFormUrl);
            form.onSave = newState => this.setTimerState(newState);
            await FormDisplayer.openInModal(form);
        } else {
            if (state.ElapsedSeconds) {
                await this.startCore(state.Url, null);
                return;
            }
            const location = await LocationProvider.tryGetLocation();
            if (!state.SeatingPlanUrl) {
                await this.startCore(state.Url, location);
                return;
            }
            const plan = new SeatingPlan(state.SeatingPlanUrl, false, true);
            await plan.load(async (seats) => {
                return await this.startCore(Requester.getUrl(state.Url, {seat: seats ? seats[0] : null}), location);
            });
        }
    }

    private static async startCore(url: string, location): Promise<boolean> {
        try {
            const result = await Requester.get(Requester.getUrl(url, location));
            this.setTimerState(result.State);
            if (result.Message) {
                MessageDisplayer.showMessage(result.Message);
            }
            return true;
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
            return false;
        }
    }

    public static async updateCurrentState(): Promise<TimerState> {
        try {
            const state: TimerState = await Requester.get(Urls.timerState);
            this.setTimerState(state);
            return state;
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
            return null;
        }
    }

    public static setTimerState(state?: TimerState) {
        if (this.interval) {
            this.interval = clearInterval(this.interval);
        }
        this.isRunning = state?.IsRunning ?? false;
        this.currentId = state?.Id ?? null;

        this.button?.setState(this.isRunning);
        this.page?.setState(this.isRunning, state && state.IsPaused, state?.PageEndFormUrl, state?.ActiveTimerFormItem);
        this.page?.setElapsedTimeText(this.getElapsedTimeText(state?.ElapsedSeconds ?? 0));
        if (this.isRunning) {
            this.tickTimer(state.ElapsedSeconds, text => {
                this.button?.setElapsedTimeText(text, true);
                this.page?.setElapsedTimeText(text);
            });
        } else {
            if (state && state.ElapsedSeconds) {
                this.button?.setElapsedTimeText(this.getElapsedTimeText(state.ElapsedSeconds), false);
            }
            if (state && state.IsPaused) {
                this.tickTimer(state.PauseSeconds, text => {
                    this.page?.setPausedTimeText(state.PauseText + ": " + text);
                });
            }
        }
    }

    private static tickTimer(elapsedSeconds: number, setText: (text: string) => void) {
        let elapsed = elapsedSeconds;
        const tickTimer = () => {
            setText(this.getElapsedTimeText(elapsed));
            elapsed++;
        };
        tickTimer();
        this.interval = setInterval(tickTimer, 1000);
    }

    private static getElapsedTimeText(elapsedSeconds: number): string {
        const format = (value: number) => {
            const str = Math.floor(value).toString();
            return str.length < 2 ? `0${str}` : str;
        };
        return format(elapsedSeconds / 3600) + ":" + format((elapsedSeconds / 60) % 60) + "." + format(elapsedSeconds % 60);
    }
}
