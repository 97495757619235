import { C } from "../../common/component";
import { Txt } from "../../common/text";
import { SeatingPlan } from "../../common/seating-plan";
import { Urls } from "../../common/urls";
import { Requester } from "../../common/requester";
import { EditControl } from "../form-row";

export class SeatingPlanEditControl implements EditControl {
    private readonly button: JQuery;
    private selectedSeats: number[] = [];
    private excludedBooking: string;

    constructor(getRowValue?: (rowName: string) => any) {
        this.button = C.button.addClass("btn btn-primary pull-right").attr("type", "button").text(Txt.chooseDesk);
        this.button.click(() => {
            // TODO: HACK: StartTime and EndTime and LocalDate come from SeatBookingController.
            // Send them from there and remove this hardcoded thing.
            const start = getRowValue ? getRowValue("StartTime") : null;
            const end = getRowValue ? getRowValue("EndTime") : null;
            const date = getRowValue ? getRowValue("LocalDate") : null;
            const plan = new SeatingPlan(Requester.getUrl(Urls.seatingPlan, {
                startTime: start,
                endTime: end,
                date: date,
                excludedBooking: this.excludedBooking
            }), true, false, true);
            plan.load(async (seats) => {
                this.selectedSeats = seats;
                return true;
            }, this.selectedSeats);
        });
    }

    initialize(parent: HTMLElement) {
        parent.append(this.button[0]);
    }

    async setValue(value) {
        if (value) {
            const parts = value.split(";");
            this.excludedBooking = parts[1];
            for (const id of parts[0].split("|")) {
                this.selectedSeats.push(parseInt(id, 10));
            }
        } else {
            this.selectedSeats = null;
            this.excludedBooking = null;
        }
    }

    getValue() {
        return this.selectedSeats.join("|") + ";" + this.excludedBooking;
    }

    onChange(action) {
    }

    focus() {}
}
