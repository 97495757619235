import { C } from "../../../common/component";
import { Icon, IconHelper } from "../../../common/icon-helper";
import { Txt } from "../../../common/text";
import { FileUploader } from "./file-uploader";
import { FileProgressControls } from "./file-progress-controls";
import { ImagePreviewControl } from "./image-preview-control";
import { FileInfo } from "./file-info";
import { FileValidator } from "./file-validator";
import { EditControl } from "../../form-row";

export class FileEditControl implements EditControl {
    private readonly restrictToImages: boolean;
    private readonly unlimitedFile: boolean;

    private row: JQuery;
    private inputGroup: JQuery;
    private titleInput: JQuery;
    private clearButton: JQuery;
    private browseTitle: JQuery;
    private fileInput: JQuery;

    private progressControls: FileProgressControls;
    private imagePreview: ImagePreviewControl;

    private file: FileInfo;
    private readonly onChangeActions: (() => void)[] = [];

    constructor(restrictToImages: boolean, unlimitedFile: boolean = false) {
        this.restrictToImages = restrictToImages;
        this.unlimitedFile = unlimitedFile;

        this.row = C.div;
        this.inputGroup = C.div.addClass("input-group").appendTo(this.row);

        this.progressControls = new FileProgressControls(this.inputGroup, this.row);
        this.imagePreview = new ImagePreviewControl(this.inputGroup);
        this.progressControls.hide();

        this.titleInput = C.input.attr("type", "text").addClass("form-control").attr("disabled", "disabled")
            .appendTo(this.inputGroup);
        const buttonsContainer = C.span.addClass("input-group-btn").appendTo(this.inputGroup);

        this.clearButton = C.button
            .addClass("btn btn-default")
            .attr("type", "button")
            .hide()
            .click(() => this.resetToNoFile())
            .appendTo(buttonsContainer);
        IconHelper.addIconWithText(this.clearButton, Icon.remove, Txt.clear);

        const browseButton = C.div.addClass("btn btn-default").appendTo(buttonsContainer);
        C.span.addClass(IconHelper.getIconClass(Icon.folderOpen)).appendTo(browseButton);
        this.browseTitle = C.span.text(Txt.browse).appendTo(browseButton);

        this.fileInput = C.input.addClass("file-input file-hide").attr("type", "file")
            .change(e => this.uploadFile(e)).appendTo(browseButton);
    }

    private uploadFile(e) {
        const file = e.target.files[0];
        if (!FileValidator.validateFile(file, this.restrictToImages, this.unlimitedFile)) {
            return;
        }
        if (this.file && this.file.hasUploader()) {
            this.file.getUploader().cancel();
        }
        const uploader = new FileUploader(file);
        this.file = new FileInfo(file.name, uploader);
        this.progressControls.onProgress(uploader);
        uploader.upload(() => this.progressControls.onProgress(uploader),
            () => this.progressControls.onProgress(uploader),
            () => {
                this.progressControls.onProgress(uploader);
                for (const action of this.onChangeActions) {
                    action();
                }
            });

        this.updateUiElements(file.name);
        this.imagePreview.configure(file);
        e.target.value = "";
    }

    private resetToNoFile() {
        if (this.file && this.file.hasUploader()) {
            this.file.getUploader().cancel();
        }
        this.file = null;
        this.imagePreview.configure();
        this.progressControls.hide();

        this.titleInput.val("");
        this.browseTitle.text(Txt.browse);
        this.clearButton.hide();
        for (const action of this.onChangeActions) {
            action();
        }
    }

    private updateUiElements(name: string) {
        this.titleInput.val(name);
        this.browseTitle.text(Txt.change);
        this.clearButton.show();
    }

    initialize(parent: HTMLElement) { parent.append(this.row[0]); }

    async setValue(value) {
        if (value) {
            this.file = FileInfo.parseFromReference(value);
            this.updateUiElements(this.file.getFileName());
        } else {
            this.resetToNoFile();
        }
    }

    getValue() { return this.file ? this.file.getReference() : null; }

    onChange(action: () => void) { this.onChangeActions.push(action); }

    validate(): boolean {
        return !this.file || !this.file.hasUploader() || FileValidator.validateUpload(this.file.getUploader());
    }

    focus() { this.fileInput.focus(); }
}
