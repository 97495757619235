import { Txt } from "./text";
import { C } from "./component";

export class ModalBuilder {
    static createModal(title) {
        const container = C.div.addClass("modal fade").attr("role", "dialog").appendTo(C.body);
        const dialog = C.div.addClass("modal-dialog").appendTo(container);
        const content = C.div.addClass("modal-content").appendTo(dialog);
        const header = C.div.addClass("modal-header").appendTo(content);
        const body = C.div.addClass("modal-body").appendTo(content);
        const closeButton = C.button.addClass("close").attr("type", "button").attr("data-dismiss", "modal")
            .html("&times;").appendTo(header);
        const titleElement = C.h4.addClass("modal-title").text(title || Txt.changeForm).appendTo(header);

        return {
            container: container,
            dialog: dialog,
            body: body,
            title: titleElement,
            closeButton: closeButton,
            content: content
        };
    }

    static removeModal(modal) { modal.modal("hide"); }
}
