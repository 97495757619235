import { C } from "./component";
import "jquery-ui/ui/widgets/droppable";
import { Helper } from "./helper";

export class TableHelper {
    static createMonthsHeaderRowFromDates(startDate: Date, endDate: Date, skipColumnsCount: number = 1) {
        const headerRow = C.tr.addClass("right-header-row");
        C.th.attr("colspan", skipColumnsCount).appendTo(headerRow);
        const currentDate = new Date(startDate);
        let oldDate = new Date(startDate);
        let span = 0;
        let multipleMonth = false;
        for (let i = 0; i <= Helper.daysBetween(startDate, endDate); i++) {
            if (oldDate.getMonth() !== currentDate.getMonth()) {
                multipleMonth = true;
                C.th.attr("colspan", span).text(Helper.getMonthName(oldDate)).appendTo(headerRow);
                span = 0;
            } else {
                span++;
            }
            oldDate = new Date(currentDate.toDateString());
            currentDate.setDate(currentDate.getDate() + 1);
        }
        if (multipleMonth) {
            span++;
        }
        C.th.attr("colspan", span).text(Helper.getMonthName(oldDate)).appendTo(headerRow);
        return headerRow;
    }

    static getDateCellHtml(date: string, color: string): string {
        const currentDate = Helper.stringToDate(date);
        let text = `${currentDate.getDate()} <br>${Helper.getWeekdayName(currentDate)}`;
        if (color) {
            text = `<div style="border-radius: 10px; background-color: ${color} !important">${text}</div>`;
        }
        return text;
    }
}
