import Timeout = NodeJS.Timeout;
import { SimpleTable, SimpleTableData } from "../../common/simple-table";
import { DashboardPanelContent } from "./dashboard-panel";
import { Disposable } from "../../navigation/page-navigator";

export class TablePanel implements DashboardPanelContent, Disposable {
    private readonly table = new SimpleTable();
    private readonly onRefresh: () => void;
    private getData: () => Promise<SimpleTableData>;
    private refreshTimeout: Timeout;
    private isDisposed = false;

    constructor(onRefresh?: () => void) {
        this.onRefresh = onRefresh;
    }

    appendTo(parent: JQuery, getData: () => Promise<any>) {
        this.getData = getData;
        this.table.appendTo(parent);
    }

    async refresh(): Promise<void> {
        if (this.isDisposed) {
            return;
        }
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        const data = await this.getData();
        if (data == null) {
            // Clear table and show error
            return;
        }
        this.table.setData(data, () => this.refresh());
        if (data.NextRefresh) {
            this.refreshTimeout = setTimeout(() => this.refresh(), data.NextRefresh * 1000);
        }
        if (this.onRefresh) {
            this.onRefresh();
        }
    }

    dispose() {
        this.isDisposed = true;
    }

    enable(): void {
    }

    disable(): void {
    }
}
