import { C } from "./component";

// ReSharper disable InconsistentNaming
interface ReportData {
    Pages: ReportPage[];
}

interface ReportPage {
    Elements: (ReportRow | ReportTable)[];
    Table: ReportTable;
}

interface ReportRow {
    TopPadding: number;
    First: string;
    Second: string;
    Third: string;
}

interface ReportTable {
    Rows: ReportTableRow[];
}

interface ReportTableRow {
    Cells: ReportTableCell[];
}

interface ReportTableCell {
    ContentText: string;
    Content: any;
    Color: string;
    Bold: boolean;
    BoldBorder: boolean;
    Rowspan: number;
    Colspan: number;
}
// ReSharper restore InconsistentNaming

export class ReportsPage {
    static async build(data: ReportData, tableParent: any) {
        for (const page of data.Pages) {
            const container = C.div.css("padding", "15px").appendTo(tableParent);
            for (const element of page.Elements) {
                if ((<any>element).IsRow) {
                    const reportRow = <ReportRow>element;
                    const infoRow = C.div.css("padding-top", reportRow.TopPadding + "px").appendTo(container);
                    C.b.text(reportRow.First).appendTo(C.span.css("margin-bottom", "0px").appendTo(infoRow));
                    C.span.css("padding-left", "25px").css("margin-bottom", "0px").text(reportRow.Second).appendTo(infoRow);
                } else {
                    const reportTable = <ReportTable>element;
                    const table = C.table.css("width", "auto").addClass("table table-bordered table-sm").appendTo(container);
                    const tableBody = C.tbody.appendTo(table);
                    for (const rowData of reportTable.Rows) {
                        const row = C.tr.appendTo(tableBody);
                        for (const cellData of rowData.Cells) {
                            const cell = C.td.appendTo(row);
                            if (cellData.Rowspan && cellData.Rowspan > 1) {
                                cell.attr("rowspan", cellData.Rowspan);
                            }
                            if (cellData.Colspan && cellData.Colspan > 1) {
                                cell.attr("colspan", cellData.Colspan);
                            }
                            if (cellData.Color) {
                                cell.css("background-color", cellData.Color + " !important");
                            }
                            if (cellData.Bold) {
                                C.b.text(cellData.ContentText).appendTo(cell);
                            } else {
                                cell.text(cellData.ContentText);
                            }
                        }
                    }
                }
            }
        }
    }
}
