import { Dom } from "../../common/dom";
import { EditControl } from "../form-row";

export class TextareaControl implements EditControl {
    private readonly input: HTMLTextAreaElement;

    constructor() {
        this.input = Dom.create("textarea", "form-control");
        this.input.setAttribute("rows", "3");
    }

    initialize(parent: HTMLElement) {
        parent.append(this.input);
    }

    async setValue(value: string) {
        this.input.value = value;
    }

    getValue() {
        const text = this.input.value;
        return text === "" ? null : text;
    }

    onChange(action: () => void) {
        this.input.addEventListener("change", action);
    }

    focus() {
        this.input.focus();
    }
}
