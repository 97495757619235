import "jquery-ui/ui/widgets/draggable";
import { C } from "../common/component";
import { Helper } from "../common/helper";
import { Txt } from "../common/text";
import { TimesheetShortcutsSet } from "./timesheet-shortcuts-set";
import { TimesheetShortcut } from "./timesheet-models";
import { ButtonBuilder } from "../common/button-builder";
import { StorageHelper, StorageItem } from "../common/storage-helper";

export class TimesheetShortcutsPanel {
    private readonly panelParent;

    constructor(parent) {
        const buttonParent = C.buttonsGroup.css("margin-top", "21px").css("margin-left", "10px").appendTo(parent);
        this.panelParent = C.div.addClass("schedule").appendTo(parent);

        const toggleButton = ButtonBuilder.get({
            name: Txt.hideShortcuts
        }).appendTo(buttonParent);
        const setShortcutsVisibility = (show: boolean) => {
            toggleButton.text(show ? Txt.hideShortcuts : Txt.showShortcuts);
            this.panelParent.collapse(show ? "show" : "hide");
        };
        this.panelParent.collapse();
        let shown = !StorageHelper.get(StorageItem.hideTimesheetShortcuts);
        setShortcutsVisibility(shown);
        if (!shown) {
            // This is hack because collapse("hide") does not work initially for some reason
            this.panelParent.hide();
        }
        toggleButton.click( () => {
            // This is hack because collapse("hide") does not work initially for some reason
            this.panelParent.show();

            shown = !shown;
            StorageHelper.set(StorageItem.hideTimesheetShortcuts, !shown);
            setShortcutsVisibility(shown);
        });
    }

    set(shortcuts: TimesheetShortcutsSet) {
        this.panelParent.empty();
        const container = C.div.addClass("form-group").appendTo(this.panelParent);
        C.label.addClass("schedule-label").text(Txt.vactionAndShiftLegend).appendTo(container);

        for (const group of shortcuts.getDisplayGroups()) {
            const abbreviations = C.div.css("margin-bottom", "5px").appendTo(container);
            for (const item of group.displayedShortcuts) {
                const titleSpan = this.addShortcut(item, abbreviations);
                if (item.Start || item.End) {
                    const tooltipText = Txt.abbreviationHelp.replace("{0}", item.Abbreviation)
                        .replace("{1}", item.Start)
                        .replace("{2}", item.End)
                        .replace("{3}", Helper.hourString(item.Hours));
                    titleSpan.attr("title", tooltipText).tooltip({html: true, container: "body"});
                }
            }
        }
    }

    private addShortcut(item: TimesheetShortcut, parent) {
        const span = C.span.addClass("img-circle span-shortcut")
            .css("background-color", item.Color)
            .text(item.Abbreviation)
            .click(() => false)
            .draggable({revert: "invalid", helper: "clone"});
        const titleSpan = C.span.text(` ${item.Name}`);
        C.div.addClass("btn btn-primary btn-shortcut")
            .css("cursor", "auto")
            .append(span)
            .append(titleSpan)
            .appendTo(parent);
        return titleSpan;
    }
}
