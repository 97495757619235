import { C } from "../common/component";
import { ModalBuilder } from "../common/modal-builder";
import { Application } from "../navigation/application";
import { Helper } from "../common/helper";
import { Txt } from "../common/text";
import { Google } from "./google-maps";
import { LocationDisplayValue } from "../common/displayer";

export class MapCell {
    private createMap(modal, data: LocationDisplayValue) {
        const map = new Google.maps.Map(C.div.addClass("map").appendTo(modal.body)[0], {
            disableDefaultUI: true,
            zoomControl: true,
            zoom: 12,
            center: {
                lat: 58.686057,
                lng: 25.842476
            }
        });

        let infoWindow;
        let markerPosition;

        const createMarker = (lat, lng, acc, icon, name) => {
            const position = {
                lat,
                lng,
            };

            const marker = new Google.maps.Marker({
                position,
                map: map,
                icon,
            });

            const circle = new Google.maps.Circle({
                map: map,
                radius: acc,
                fillColor: "mediumpurple",
            });

            circle.bindTo("center", marker, "position");

            Google.maps.event.addListener(marker, "click", () => {
                if (infoWindow !== undefined) {
                    infoWindow.close();
                }
                infoWindow = new Google.maps.InfoWindow({
                    content: `<p>${name}</p>`
                });
                infoWindow.open(map, marker);
            });

            markerPosition = marker.getPosition();
        };

        const startLocation = data.StartLocation;
        const endLocation = data.EndLocation;
        const objectLocation = data.ProjectLocation;
        if (Helper.hasValue(objectLocation)) {
            createMarker(
                objectLocation.Latitude,
                objectLocation.Longitude,
                objectLocation.Accuracy || Application.defaultRadius,
                "../images/random_marker.png",
                Txt.objectLocation);
        }
        if (Helper.hasValue(startLocation) && Helper.hasValue(endLocation)) {
            if (startLocation.Latitude === endLocation.Latitude && startLocation.Longitude === endLocation.Longitude) {
                createMarker(startLocation.Latitude, startLocation.Longitude, startLocation.Accuracy,
                    "../images/project_marker.png", `${Txt.startLocation}/${Txt.endLocation}`);
            } else {
                createMarker(startLocation.Latitude, startLocation.Longitude, startLocation.Accuracy,
                    "../images/project_marker.png", Txt.startLocation);
                createMarker(endLocation.Latitude, endLocation.Longitude, endLocation.Accuracy,
                    "../images/project_marker.png", Txt.endLocation);
            }
        } else if (Helper.hasValue(startLocation)) {
            createMarker(startLocation.Latitude, startLocation.Longitude, startLocation.Accuracy,
                "../images/project_marker.png", Txt.startLocation);
        } else if (Helper.hasValue(endLocation)) {
            createMarker(endLocation.Latitude, endLocation.Longitude, endLocation.Accuracy,
                "../images/project_marker.png", Txt.endLocation);
        }

        modal.container.on("shown.bs.modal", () => {
            Google.maps.event.trigger(map, "resize", {});
            map.setCenter(markerPosition);
        });
    }

    showLocation(displayValue: LocationDisplayValue) {
        const modal = ModalBuilder.createModal(Txt.locationOnMap);
        this.createMap(modal, displayValue);
        modal.container.modal({
            show: true
        });
    }
}
