import { HtmlRenderer, Parser } from "commonmark";
import { C } from "../../common/component";
import { Helper } from "../../common/helper";
import { EditControl } from "../form-row";

export class MarkdownEditControl implements EditControl {
    private inputRow: JQuery;
    private input: JQuery;

    constructor() {
        const markdownReader = new Parser();
        const markdownWriter = new HtmlRenderer();
        this.inputRow = C.div;
        this.input = C.textarea.addClass("form-control").attr("row", "6").attr("cols", "60").bind("keyup", () => {
            htmlRow.html(Helper.removeScriptTags(markdownWriter.render(markdownReader.parse(this.input.val()))));
        }).focusin(() => {
            htmlRow.show();
        }).focusout(() => {
            htmlRow.hide();
        }).appendTo(this.inputRow);
        const htmlRow = C.div.addClass("markdown-preview").hide().appendTo(this.inputRow);
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow[0]);
    }

    async setValue(value) {
        this.input.val(value);
    }

    getValue() {
        return this.input.val().toString();
    }

    onChange(action) {
        this.input.on("input", action);
    }

    focus() {
        this.input.focus();
    }
}
