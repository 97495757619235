import { C } from "../../common/component";
import { Helper } from "../../common/helper";
import { Icon, IconHelper } from "../../common/icon-helper";
import { Language } from "../../common/text";
import { StorageHelper, StorageItem } from "../../common/storage-helper";
import { EditControl } from "../form-row";

export class DateEditControl implements EditControl {
    private input: JQuery;
    private inputRow: JQuery;
    private dateGroup: JQuery;

    constructor(placeholder?: string) {
        this.inputRow = C.div;
        this.input = C.input.addClass("form-control");

        this.dateGroup = C.div.addClass("input-group date").appendTo(this.inputRow);
        this.input.attr("placeholder", placeholder).attr("type", "text").appendTo(this.dateGroup);
        const addon = C.span.addClass("input-group-addon").appendTo(this.dateGroup);
        C.span.addClass(IconHelper.getIconClass(Icon.calendar)).appendTo(addon);

        const language = StorageHelper.get(StorageItem.language);
        const dp = this.dateGroup.datetimepicker({
            locale: language === Language.estonian
                ? "et"
                : language === Language.russian
                    ? "ru"
                    : language === Language.latvian
                        ? "lv"
                        : language === Language.lithuanian
                            ? "lt"
                            : language === Language.finnish
                                ? "fi"
                                : "en-gb",
            format: "DD.MM.YYYY",
            useCurrent: false
        });

        this.input.focusin(() => {
            if (!Helper.isMobile()) {
                dp.data("DateTimePicker").show();
            }
        });
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow[0]);
    }

    async setValue(value: string) {
        this.input.val(value);
    }

    getValue() {
        const value = this.input.val().toString();
        return value === "" ? null : value;
    }

    onChange(action) {
        this.dateGroup.on("dp.change", action);
    }

    focus() { return this.input.focus(); }
}
