import { Helper } from "../../common/helper";
import { DateEditControl } from "./date-edit-control";
import { TimeEditControl } from "./time-edit-control";
import { EditControl } from "../form-row";
import { Dom } from "../../common/dom";

export class DateTimeEditControl implements EditControl {
    private readonly inputGroup: HTMLDivElement;
    private readonly timeDiv: HTMLDivElement;
    private readonly dateDiv: HTMLDivElement;
    private timeControl: TimeEditControl;
    private dateControl: DateEditControl;

    constructor(timeAccuracy) {
        this.inputGroup = Dom.createDiv("input-group");
        this.inputGroup.style.width = "100%";

        this.timeDiv = Dom.addDiv(this.inputGroup);
        this.timeDiv.style.width = "40%";
        this.timeDiv.style.float = "left";

        this.dateDiv = Dom.addDiv(this.inputGroup);
        this.dateDiv.style.width = "57%";
        this.dateDiv.style.float = "right";

        this.timeControl = new TimeEditControl(timeAccuracy);
        this.dateControl = new DateEditControl();
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputGroup);
        this.timeControl.initialize(this.timeDiv);
        this.dateControl.initialize(this.dateDiv);
    }

    async setValue(value: string) {
        if (Helper.hasValue(value)) {
            const parts = value.split(" ");
            await this.timeControl.setValue(parts[1]);
            await this.dateControl.setValue(parts[0]);
        } else {
            await this.timeControl.setValue(null);
            await this.dateControl.setValue(null);
        }
    }

    getValue() {
        const date = this.dateControl.getValue();
        const time = this.timeControl.getValue();
        return date === null || time === null ? null : date + " " + time;
    }

    onChange(action: () => void) {
        this.timeControl.onChange(action);
        this.dateControl.onChange(action);
    }

    validate(): boolean {
        return this.timeControl.validate();
    }

    focus() { this.timeControl.getValue() === null ? this.timeControl.focus() : this.dateControl.focus(); }
}
