import { C } from "./component";
import { Form, FormDisplayer } from "../forms/form";
import { TableHelper } from "./table-helper";
import { ViewCellBuilder, ViewCellInfo } from "../views/view-cell-builder";
import { Helper } from "./helper";
import { Loader } from "./loader";
import { DisplayValue, Displayer } from "./displayer";
import { Icon, IconHelper } from "./icon-helper";

// ReSharper disable InconsistentNaming
export interface EditableTableData {
    Headers: EditableTableHeader[];
    RightColumns: EditableTableDateColumn[];
    LeftColumnNames: string[];
    InnerRowNames: string[];

    Rows: EditableTableRowData[];
}

export interface EditableTableCell {
    Value: DisplayValue;
    CalculationTagsWithCoefficient: { [tag: string]: number };
    Rowspan: number;
    Colspan: number;

    Color?: string;
    Threshold?: number;
    LessThanThresholdColor?: string;
    MoreThanThresholdColor?: string;
}

export interface EditableTableCalculationsData {
    Id: string;
    ValuesByTag: { [tag: string]: EditableTableCalculationValue };
}

export interface EditableTableCalculationValue {
    Value: number;
    Text?: string;
    OverrideIcon?: string;
    OverrideTooltip?: string;
}

export interface EditableTableHeader {
    Name: string;
    Colspan: number;
}

export interface EditableTableDateColumn {
    Date: string;
    Color: string;
    Colspan: number;
}

export interface EditableTableRowData {
    LeftColumnsData: EditableTableCell[];
    DateDatas: EditableTableDateData[];
}

export interface EditableTableDateData {
    CellInfos: ViewCellInfo[];
    CalculationsData: EditableTableCalculationsData;
    Colspan: number;
}
// ReSharper restore InconsistentNaming

export class EditableTableCalculator {
    private readonly useLoadingIconForDefault: boolean;

    constructor(useLoadingIconForDefault: boolean) { this.useLoadingIconForDefault = useLoadingIconForDefault; }

    private rawData: { [tag: string]: { [id: string]: EditableTableCalculationValue } } = {};
    private data: { [tag: string]: EditableTableCalculationValue } = {};
    private changedTags: { [tag: string]: boolean } = {};

    private cellsByTag: { [tag: string]: CalculatedCell[] } = {};

    setCalculationValue(value: EditableTableCalculationsData) {
        for (const tag in value.ValuesByTag) {
            let tagData = this.rawData[tag];
            if (!tagData) {
                this.rawData[tag] = tagData = {};
            }
            tagData[value.Id] = value.ValuesByTag[tag];
            this.changedTags[tag] = true;
        }
    }

    getValue(tag: string): EditableTableCalculationValue {
        if (this.changedTags[tag]) {
            let result = 0;
            let text = "";
            const tagData = this.rawData[tag];
            for (const id in tagData) {
                const calculationsValue = tagData[id];
                if (calculationsValue.OverrideIcon) {
                    return this.data[tag] = calculationsValue;
                }
                result += calculationsValue.Value;
                if (calculationsValue.Text) {
                    text += calculationsValue.Text;
                }
            }
            return this.data[tag] = { Value: Helper.round(result), Text: text };
        }
        const value = this.data[tag];
        if (!value) {
            return { Value: 0 };
        }
        return value;
    }

    addCell(cell, cellData: EditableTableCell) {
        const calculatedCell = new CalculatedCell(cell, cellData);
        for (const tag in cellData.CalculationTagsWithCoefficient) {
            if (!this.cellsByTag[tag]) {
                this.cellsByTag[tag] = [];
            }
            this.cellsByTag[tag].push(calculatedCell);
        }
        if (this.useLoadingIconForDefault) {
            IconHelper.getIconColorful(Icon.Load, "#cccccc").appendTo(cell);
        } else {
            cell.text("0");
        }
    }

    refreshCalculations() {
        for (const tag in this.changedTags) {
            if (this.changedTags[tag]) {
                const cells = this.cellsByTag[tag];
                if (cells) {
                    for (const cell of cells) {
                        cell.updateValue(this);
                    }
                }
                this.changedTags[tag] = false;
            }
        }
    }
}

export class CalculatedCell {
    private readonly cell;
    private readonly calculationTagsWithCoefficient: { [tag: string]: number };
    private readonly color?: string;
    private readonly threshold?: number;
    private readonly lessThanThresholdColor?: string;
    private readonly moreThanThresholdColor?: string;

    constructor(cell, cellData: EditableTableCell) {
        this.cell = cell;
        this.calculationTagsWithCoefficient = cellData.CalculationTagsWithCoefficient;
        this.color = cellData.Color;
        this.threshold = cellData.Threshold;
        this.lessThanThresholdColor = cellData.LessThanThresholdColor;
        this.moreThanThresholdColor = cellData.MoreThanThresholdColor;
    }

    updateValue(calculator: EditableTableCalculator) {
        let numberValue = 0;
        let text = "";
        this.cell.empty();
        for (const tag in this.calculationTagsWithCoefficient) {
            const value = calculator.getValue(tag);
            if (value.OverrideIcon) {
                IconHelper.getIconColorful(Icon[value.OverrideIcon], "#cccccc").appendTo(this.cell);
                this.cell.attr("data-container", "body").attr("title", value.OverrideTooltip).tooltip();
                return;
            }
            numberValue += this.calculationTagsWithCoefficient[tag] * value.Value;
            text += value.Text;
        }
        if (this.color) {
            this.cell[0].style["color"] = this.color;
        }
        if (this.threshold != null) {
            if (numberValue > this.threshold && this.moreThanThresholdColor) {
                this.cell[0].style["color"] = this.moreThanThresholdColor;
                this.cell[0].style["font-weight"] = "bold";
            } else if (numberValue < this.threshold && this.lessThanThresholdColor) {
                this.cell[0].style["color"] = this.lessThanThresholdColor;
                this.cell[0].style["font-weight"] = "bold";
            } else {
                if (!this.color) {
                    this.cell[0].style.removeProperty("color");
                }
                this.cell[0].style.removeProperty("font-weight");
            }
        }
        if (numberValue === 0) {
            this.cell.text(text);
            return;
        }
        if (Helper.hasValue(text)) {
            text = ` ${text}`;
        }
        this.cell.text(Helper.round(numberValue) + text);
    }
}

export class EditableTable {
    static async build(data: EditableTableData, tableParent: any, formDiv: any) {
        const loader = Loader.startLocally(tableParent);
        try {
            tableParent = C.div.addClass("editable-table").appendTo(tableParent);
            const tableResponsive = C.div
                .addClass("resource-table table-responsive")
                .css("height", Helper.mainHeight - formDiv.height() + 20)
                .appendTo(tableParent);
            const table = C.table.addClass("table table-bordered").appendTo(tableResponsive);

            const tableHead = C.thead.appendTo(table);
            const headerRow = C.tr.addClass("right-header-row").appendTo(tableHead);
            for (const header of data.Headers) {
                C.th.text(header.Name).attr("colspan", header.Colspan).appendTo(headerRow);
            }

            const datesHeader = C.tr.appendTo(tableHead);
            for (const columnName of data.LeftColumnNames) {
                C.th.text(columnName).appendTo(datesHeader);
            }
            C.th.appendTo(datesHeader);
            for (const column of data.RightColumns) {
                C.th.html(TableHelper.getDateCellHtml(column.Date, column.Color))
                    .attr("colspan", column.Colspan).appendTo(datesHeader);
            }

            const calculator = new EditableTableCalculator(false);
            const tableBody = C.tbody.appendTo(table);
            let isOddRow = true;
            for (const rowData of data.Rows) {
                let row = C.tr;
                let firstColumn = true;
                for (const value of rowData.LeftColumnsData) {
                    const cell = C.td.attr("rowspan", value.Rowspan).appendTo(row);
                    if (firstColumn) {
                        cell.addClass("fixed-col");
                        firstColumn = false;
                    }
                    if (value.Value) {
                        Displayer.setValue(cell, value.Value);
                    } else {
                        calculator.addCell(cell, value);
                    }
                }
                const innerRows = [];
                for (let i = 0; i < data.InnerRowNames.length; i++) {
                    if (isOddRow) {
                        row.addClass("darker-row");
                    }
                    row.appendTo(tableBody);
                    innerRows.push(row);
                    C.td.addClass("row-title-cell").text(data.InnerRowNames[i]).appendTo(row);
                    row = C.tr;
                }
                let cellCount = 0;
                for (const dateData of rowData.DateDatas) {
                    const cells = [];
                    for (const innerRow of innerRows) {
                        cells.push(C.td.attr("colspan", dateData.Colspan).appendTo(innerRow));
                    }
                    EditableTable.createCell(cells, dateData, cellCount, calculator, false);
                    cellCount++;
                }
                isOddRow = !isOddRow;
            }
            calculator.refreshCalculations();
        }
        finally {
            loader.stop();
        }
    }

    private static createCell(cells, dateData: EditableTableDateData, cellCount: number,
        calculator: EditableTableCalculator, refresh: boolean) {
        if (dateData.CalculationsData) {
            calculator.setCalculationValue(dateData.CalculationsData);
            if (refresh) {
                calculator.refreshCalculations();
            }
        }
        for (let i = 0; i < dateData.CellInfos.length; i++) {
            cells[i].empty();
            ViewCellBuilder.createCell(cells[i], dateData.CellInfos[i], newDateData => {
                if (newDateData) {
                    this.createCell(cells, newDateData, cellCount, calculator, true);
                }
            }, false, true, true);
        }
    }
}
