import TomSelect from "tom-select";
import { Requester } from "../../common/requester";
import { EditControl } from "../form-row";
import { MessageDisplayer } from "../../common/message-displayer";
import { Txt } from "../../common/text";
import { LocalLoader } from "../../common/loader";
import { Dom } from "../../common/dom";

export interface SelectOption {
    id: string;
    text: string;
    disabled: boolean;
}

export class SelectControl implements EditControl {
    private readonly getFieldValue?: (rowName: string) => string;
    private readonly isMultiSelect: boolean;
    private readonly choicesDisplayValuesUrl?: string;
    private readonly searchUrl?: any;
    private readonly choices: SelectOption[];
    private readonly isCompact: boolean;
    private readonly placeholder: string;
    private readonly noInputHeightLimit: boolean;
    private tom: TomSelect;

    constructor(canSelectMany: boolean, choices: SelectOption[], searchUrl?: any, choicesDisplayValuesUrl?: string,
                getFieldValue?: (rowName: string) => string, isCompact: boolean = false, placeholder?: string,
                noInputHeightLimit?: boolean) {
        this.getFieldValue = getFieldValue;
        this.isMultiSelect = canSelectMany;
        this.choicesDisplayValuesUrl = choicesDisplayValuesUrl;
        this.searchUrl = searchUrl;
        this.choices = choices;
        this.isCompact = isCompact;
        this.placeholder = placeholder;
        this.noInputHeightLimit = noInputHeightLimit
    }

    initialize(parent: HTMLElement) {
        const subDiv = Dom.addDiv(parent);
        subDiv.style.all = "unset";
        const select = Dom.add("select", subDiv, "w-select", this.isCompact ? "w-select-compact" : "w-select-normal");
        if (this.noInputHeightLimit !== true) {
            select.classList.add("w-select-height-limit");
        }
        const hasInputInDropdown = !this.isMultiSelect || this.isCompact;
        const plugins = ["no_backspace_delete"];
        if (hasInputInDropdown) {
            plugins.push("clear_button");
            plugins.push("dropdown_input");
        } else {
            plugins.push("remove_button");
            plugins.push("drag_drop");
        }
        const settings: any = {
            plugins: plugins,
            valueField: "id",
            labelField: "text",
            disabledField: "disabled",
            maxItems: this.isMultiSelect ? null : 1,
            maxOptions: null,
            hideSelected: false,
            options: this.choices,
            placeholder: this.placeholder,
            // Needed for displaying no results text even if user has not typed anything yet
            shouldLoad: () => true,
            render: {
                no_results: () => "<div class=\"no-results\">" + Txt.noResults + "</div>"
            }
        };
        let selectAllButton: HTMLElement = null;
        if (this.isMultiSelect) {
            const nrOfItemsElement = Dom.createDiv("w-select-nr");

            selectAllButton = Dom.createDiv("w-select-all");
            selectAllButton.innerText = Txt.all;
            selectAllButton.onclick = () => {
                const options = [];
                console.log();
                for (const option of this.tom.search(this.tom.inputValue()).items) {
                    if ((<SelectOption>this.tom.options[option.id]).disabled !== true) {
                        options.push(option.id);
                    }
                }
                this.tom.addItems(options);
            };

            if (this.isCompact) {
                settings.onInitialize = function () {
                    this.control.append(nrOfItemsElement);
                    this.wrapper.querySelector(".dropdown-input-wrap").append(selectAllButton);
                };
            } else {
                settings.onInitialize = function () {
                    const rightSide = Dom.addDiv(this.wrapper, "w-select-right-side");

                    const clearButton = Dom.addDiv(rightSide, "w-select-right-side-clear");
                    clearButton.innerText = "⨯";
                    clearButton.addEventListener("click", evt => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.clear();
                    });

                    rightSide.append(nrOfItemsElement);
                    this.wrapper.querySelector(".ts-dropdown").append(selectAllButton);
                };
            }
            const setCount = () => {
                const count = this.tom.getValue().length;
                nrOfItemsElement.innerText = count < (this.isCompact ? 1 : 10) ? "" : count;
            };
            settings.onItemAdd = setCount;
            settings.onItemRemove = setCount;
        }
        this.tom = new TomSelect(select, settings);
        if (this.searchUrl) {
            let urlWithFilters = "";
            let loader: LocalLoader;
            let textInput: HTMLInputElement;
            let dropdownContent: HTMLElement;
            this.tom.on("dropdown_open", async (dropdown: HTMLElement) => {
                const newUrlWithFilters = this.getUrlWithFilters();
                if (urlWithFilters !== newUrlWithFilters) {
                    urlWithFilters = newUrlWithFilters;
                    if (!loader) {
                        dropdownContent = dropdown.querySelector(".ts-dropdown-content");
                        if (hasInputInDropdown) {
                            textInput = dropdown.querySelector(".dropdown-input");
                            loader = new LocalLoader(dropdown.querySelector(".dropdown-input-wrap"));
                            loader.setStyle("top", "-34px");
                        } else {
                            textInput = this.tom.control.querySelector("input");
                            loader = new LocalLoader(this.tom.wrapper);
                            loader.setStyle("background", "white");
                            loader.setStyle("box-shadow", "0 0 60px 20px white");
                            loader.setStyle("top", "-31px");
                        }
                        loader.setStyle("transform", "scale(0.25)");
                        loader.setStyle("left", "-30px");
                    }
                    loader.start();
                    textInput.readOnly = true;
                    dropdownContent.style.display = "none";
                    if (selectAllButton) {
                        selectAllButton.style.display = "none";
                    }
                    try {
                        const data = await Requester.get(urlWithFilters);
                        this.tom.clearOptions();
                        this.tom.addOptions(data);
                        this.tom.refreshOptions();
                    } catch (err) {
                        MessageDisplayer.showErrorFromServer(err);
                    } finally {
                        loader.stop();
                        textInput.readOnly = false;
                        dropdownContent.style.display = "";
                        if (selectAllButton) {
                            selectAllButton.style.display = "";
                        }
                    }
                }
            });
        }
        if (this.isMultiSelect) {
            if (this.isCompact) {
                // HACK: Open dropdown on item click
                this.tom.hook("instead", "setActiveItem", (item: any) => {
                    if (item) {
                        this.tom.open();
                    }
                });
                this.tom.hook("instead", "selectAll", () => {
                });
            }
            // HACK: This is for deselecting items with click on already selected item
            const orig_onOptionSelect = this.tom.onOptionSelect;
            this.tom.hook("instead", "onOptionSelect", (evt: KeyboardEvent, option: HTMLElement) => {
                if (this.tom.getValue().includes(option.dataset.value)) {
                    option.classList.remove("selected");
                    this.tom.removeItem(option.dataset.value);
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }
                orig_onOptionSelect.call(this.tom, evt, option);
            });
        }
    }

    hideSelectedItemsControl() {
        this.tom.control.style.display = "none";
    }

    private getUrlWithFilters(): string {
        const queryParams = {};
        for (const parameter of this.searchUrl.Parameters) {
            const value = this.getFieldValue(parameter.RowName);
            if (value) {
                queryParams[parameter.Name] = value;
            }
        }
        return Requester.getUrl(this.searchUrl.Url, queryParams);
    }

    async setValue(serializedValue: string, choicesDisplayValues?: { [choiceId: string]: string }) {
        if (serializedValue) {
            const values = this.isMultiSelect ? JSON.parse(serializedValue) : [serializedValue];
            if (!choicesDisplayValues) {
                choicesDisplayValues = {};
            }
            const currentOptions = new Set();
            for (const choice of this.choices) {
                currentOptions.add(choice.id);
            }
            const newOptions: SelectOption[] = [];
            const missingOptions: string[] = [];
            for (const value of values) {
                if (!currentOptions.has(value)) {
                    if (!choicesDisplayValues[value]) {
                        missingOptions.push(value);
                    } else {
                        newOptions.push({id: value, text: choicesDisplayValues[value], disabled: false});
                    }
                }
            }
            if (missingOptions.length > 0 && this.choicesDisplayValuesUrl) {
                const displayValues: { [choiceId: string]: string } = await Requester.post(this.choicesDisplayValuesUrl, missingOptions);
                for (const id in displayValues) {
                    choicesDisplayValues[id] = displayValues[id];
                }
            }
            for (const value of missingOptions) {
                newOptions.push({id: value, text: choicesDisplayValues[value] || value, disabled: false});
            }
            if (newOptions.length > 0) {
                this.tom.addOptions(newOptions);
            }
            this.tom.addItems(values, true);
        } else {
            this.tom.clear();
        }
    }

    getValue(): string {
        const value = this.tom.getValue();
        return this.isMultiSelect ? JSON.stringify(this.tom.getValue()) : value === "" ? null : value;
    }

    onChange(action: () => void) {
        this.tom.on("change", () => action());
    }

    focus(): void { this.tom.focus(); }

    onBlur(action: () => void) {
        this.tom.on("blur", action);
    }
}
