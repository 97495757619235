import Swal from "sweetalert2";
import { Helper } from "./helper";
import { Requester } from "./requester";
import { Txt } from "./text";
import { MessageDisplayer } from "./message-displayer";
import { Loader } from "./loader";
import { DisplayValue } from "./displayer";

export class ConfirmationDialogHelper {
    static showDialog(confirmationMessage: string, url: string, onSuccess: (value: DisplayValue) => void,
        useLoader: boolean = false): void {
        async function confirmAction() {
            try {
                if (useLoader) {
                    Loader.startGlobally();
                }
                const message = await Requester.get(url);
                if (Helper.hasValue(onSuccess)) {
                    onSuccess(message.Value);
                }
                MessageDisplayer.showSuccessDialog(message);
            } catch (error) {
                MessageDisplayer.showErrorFromServer(error);
            } finally {
                if (useLoader) {
                    Loader.stopGlobally();
                }
            }
        }

        if (!Helper.hasValue(confirmationMessage)) {
            confirmAction();
        } else {
            const params: any = this.getParams(confirmationMessage);
            params.preConfirm = confirmAction;
            Swal.fire(params);
        }
    }

    static askConfirmation(confirmationMessage: string, onConfirmation: () => void, onCancel?: () => void): void {
        Swal.fire(this.getParams(confirmationMessage)).then(result => {
            if (result.isConfirmed) {
                onConfirmation();
            } else if (onCancel) {
                onCancel();
            }
        });
    }

    private static getParams(confirmationMessage: string) {
        return {
            title: Txt.areYouSure,
            text: confirmationMessage,
            imageUrl: "images/question.png",
            showCancelButton: true,
            confirmButtonText: Txt.yes,
            cancelButtonText: Txt.no,
            reverseButtons: true
        };
    }
}
