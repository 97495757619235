import Timeout = NodeJS.Timeout;
import { Requester } from "./requester";
import { ModalBuilder } from "./modal-builder";
import { SimpleTable, SimpleTableData } from "./simple-table";
import { Form, FormButtonBehavior, FormDisplayer } from "../forms/form";
import { ButtonBuilder } from "./button-builder";
import { ConfirmationDialogHelper } from "./confirmation-dialog-helper";
import { PanelDefinition } from "../dashboard/panels/dashboard-panel";
import { DownloadHelper } from "./download-helper";
import { Icon } from "./icon-helper";

export class DialogTable {
    private readonly table = new SimpleTable();
    private readonly url: string;
    private readonly onDataChanged: () => void;
    private refreshTimeout: Timeout;

    constructor(url: string, onDataChanged?: () => void) {
        this.url = url;
        this.onDataChanged = onDataChanged;
    }

    async open() {
        const definition: PanelDefinition = await Requester.get(this.url);
        const modal = ModalBuilder.createModal(definition.Title);
        modal.dialog.css("width", "880px");
        this.table.appendTo(modal.body);
        await this.reloadData(definition.DataUrl);

        for (const button of definition.Buttons) {
            let onClick;
            if (button.Behavior === FormButtonBehavior.CloseForMessage) {
                onClick = () => ConfirmationDialogHelper.showDialog(
                    button.ConfirmationMessage,
                    button.Url,
                    () => {
                        ModalBuilder.removeModal(modal.container);
                        if (this.onDataChanged) {
                            this.onDataChanged();
                        }
                    });
            } else if (button.Behavior === FormButtonBehavior.OpenModal) {
                onClick = async () => {
                    const form = new Form(button.Url);
                    form.onDataChanged = async () => {
                        await this.reloadData(definition.DataUrl);
                        if (this.onDataChanged) {
                            this.onDataChanged();
                        }
                    };
                    await FormDisplayer.openInModal(form);
                };
            } else if (button.Behavior === FormButtonBehavior.FileDownload) {
                onClick = async () => {
                    const file = await Requester.get(button.Url);
                    DownloadHelper.download(file);
                };
            }
            if (onClick) {
                ButtonBuilder.get({
                    name: button.Name,
                    icon: button.Icon ? Icon[button.Icon] : null,
                    isSecondary: true,
                    cssClass: "margin-top-5 margin-right-5",
                    onClick: onClick
                }).appendTo(modal.body);
            }
        }
        modal.container.modal({show: true});
    }

    private async reloadData(dataUrl: string): Promise<void> {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        const data = (<SimpleTableData>await Requester.post(dataUrl, null));
        this.table.setData(data, () => {
            this.reloadData(dataUrl);
            if (this.onDataChanged) {
                this.onDataChanged();
            }
        });
        if (data.NextRefresh) {
            this.refreshTimeout = setTimeout(() => this.reloadData(dataUrl), data.NextRefresh * 1000);
        }
    }
}
