import { C } from "./component";
import { Form, FormDisplayer } from "../forms/form";
import { ViewTable } from "../views/view-table";
import { PageNavigator } from "../navigation/page-navigator";
import { Icon } from "./icon-helper";

export class FormAndViewPage {
    private readonly info;
    private readonly container: JQuery;

    constructor(info: any) {
        this.info = info;
        this.container = C.container;
    }

    async appendTo(parent) {
        this.container.appendTo(parent);
        await this.refresh();
    }

    async refresh() {
        this.container.empty();
        if (this.info.SettingsFormUrl) {
            this.addSettingsButton(this.container, this.info.SettingsFormUrl);
        }
        const row = C.row.addClass("panel panel-default").appendTo(this.container);
        const panel = C.column(12, false).appendTo(row);
        const tablePanel = C.column(12, false).appendTo(row);
        if (this.info.ViewTitle) {
            C.div.text(this.info.ViewTitle).css("font-size", "26px").css("position", "absolute").appendTo(tablePanel);
            tablePanel.css("margin-top", "20px");
        }
        const tableBody = C.div.addClass("table-responsive").appendTo(tablePanel);
        const table = new ViewTable(tableBody, this.info.ViewColumnsUrl, this.info.ViewDataUrl, null, false);
        table.init();
        PageNavigator.addDisposableElement(table);
        const form = new Form(this.info.FormUrl);
        form.onDataChanged = () => { table.refresh(); };
        FormDisplayer.addForm(form, panel);
    }

    private addSettingsButton(parent, settingsFormUrl: string) {
        const row = C.row.appendTo(parent);
        const button = C.circularRightButton(Icon.Cog).appendTo(row);
        button.click(() => {
            FormDisplayer.openInModal(new Form(settingsFormUrl));
        });
    }
}
