import { C } from "../../common/component";
import { Dom } from "../../common/dom";
import { Displayer } from "../../common/displayer";
import { EditControl } from "../form-row";

export class ViewCellEditControl implements EditControl {
    private readonly control: HTMLSpanElement;
    private value: string;

    constructor() {
        this.control = Dom.createSpan("view-cell");
    }

    initialize(parent: HTMLElement) {
        parent.append(this.control);
    }

    async setValue(x: string) {
        Displayer.setValue(C.create(this.control), JSON.parse(x));
        this.value = x;
    }

    getValue() {
        return null;
    }

    onChange() {
    }

    focus() { this.control.focus(); }
}
