import "../../external/spectrum";
import { C } from "../../common/component";
import { Txt } from "../../common/text";
import { EditControl } from "../form-row";

export class ColorEditControl implements EditControl {
    private readonly input: JQuery;

    constructor() { this.input = C.input.addClass("form-control"); }

    initialize(parent: HTMLElement) {
        parent.append(this.input[0]);
        this.input.spectrum({
            type: "component",
            showAlpha: false,
            chooseText: Txt.choose,
            cancelText: Txt.cancel,
            clearText: Txt.clear
        });
    }

    async setValue(value) { this.input.spectrum("set", value); }

    getValue() {
        const value = this.input.spectrum("get");
        if (value) {
            return value.toHexString();
        }
        return null;
    }

    onChange(action) {}

    focus() {
        this.input.focus();
    }
}
