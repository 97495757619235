import { Helper } from "../common/helper";
import { Requester } from "../common/requester";
import { MessageDisplayer } from "../common/message-displayer";
import { EditControlBuilder } from "../forms/edit-control-builder";
import { Displayer, DisplayValue } from "../common/displayer";
import { SelectControl } from "../forms/edit-controls/select-control";
import { InputControl } from "../forms/edit-controls/input-control";
import { FormActionResult } from "../forms/form";

// ReSharper disable InconsistentNaming
export interface ViewCellInfo {
    DisplayValue: DisplayValue;
    EditTypeData;
    EditValue: string;
    ChoicesDisplayValues: { [choiceId: string]: string };
    SaveUrl;
}
// ReSharper restore InconsistentNaming

export class ViewCellBuilder {
    static createCell(cell, cellInfo: ViewCellInfo, onDataChanged: (newData: any, onChangeType: string) => void,
                      addEditIconWhenHovered: boolean = true, disableLinks: boolean = false, useDoubleClick: boolean = false) {
        Displayer.setValue(cell, cellInfo.DisplayValue, onChangeType => onDataChanged(null, onChangeType), disableLinks);

        const editEventName = useDoubleClick ? "dblclick" : "click";
        cell.off(editEventName);

        if (Helper.hasValue(cellInfo.EditTypeData)) {
            cell.addClass("editable-cell");
            if (addEditIconWhenHovered) {
                cell.addClass("editable-cell-icon");
            }

            let isInEditMode = false;
            const editValue = cellInfo.EditValue;
            const choicesDisplayValues = cellInfo.ChoicesDisplayValues;
            cell.on(editEventName, async () => {
                if (isInEditMode) {
                    return;
                }
                isInEditMode = true;
                const control = EditControlBuilder.create(cellInfo.EditTypeData);
                if (!(control instanceof SelectControl) && !(control instanceof InputControl)) {
                    isInEditMode = false;
                    return;
                }
                cell.off(editEventName);
                cell.empty();
                cell.addClass("cell-in-edit-form");
                control.initialize(cell[0]);
                await control.setValue(editValue, choicesDisplayValues);
                let isInSaveMode = false;
                control.focus();
                control.onBlur(async () => {
                    if (isInSaveMode) {
                        return;
                    }
                    isInSaveMode = true;
                    let response: FormActionResult;
                    let isError = false;
                    try {
                        response = await Requester.post(cellInfo.SaveUrl, control.getValue());
                    } catch (err) {
                        MessageDisplayer.showErrorFromServer(err);
                        isError = true;
                    }
                    cell.removeClass("cell-in-edit-form");
                    if (isError) {
                        ViewCellBuilder.createCell(cell, cellInfo, onDataChanged, addEditIconWhenHovered, disableLinks, useDoubleClick);
                        return;
                    }
                    onDataChanged(response.Value, null);
                    isInEditMode = false;
                    if (response.Message) {
                        MessageDisplayer.showMessage(response.Message);
                    }
                });
            });
        } else {
            cell.removeClass("editable-cell");
            cell.removeClass("editable-cell-icon");
        }
    }
}
