import { Network } from "vis-network";
import { DataSet } from "vis-data";
import { Requester } from "./requester";
import { C } from "./component";
import { Helper } from "./helper";
import { Options } from "vis-network/declarations/network/Network";

interface Graph {
    Nodes: any[];
    Edges: any[];
    Hierarchical: boolean;
}

export class GraphCreator {
    private readonly url: string;

    constructor(url: string) {
        this.url = url;
    }

    async appendTo(parent: JQuery) {
        const data: Graph = await Requester.get(this.url);
        const nodes = new DataSet(data.Nodes);
        const edges = new DataSet(data.Edges);

        const container = C.div.css("width", "100%").css("height", Helper.mainHeight).appendTo(parent);
        const options: Options = {
            edges: {
                arrows: {
                    to: {
                        enabled: true,
                        scaleFactor: 0.5,
                        type: "arrow",
                    },
                },
                color: {
                    color: "#666666",
                },
            },
            physics: {
                enabled: true,
            },
            groups: {
                EmployeesGroup: {
                    shape: "box",
                    color: "#ffffff",
                },
                Employee: {
                    image: "images/profile.png",
                    shape: "circularImage",
                    color: "#aa00ff",
                }
            }
        };
        if (data.Hierarchical) {
            options.layout = { hierarchical: { sortMethod: "directed", shakeTowards: "roots" } };
        }
        new Network(container[0], {nodes, edges}, options);
    }
}
