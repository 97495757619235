import { TimesheetShortcut } from "./timesheet-models";

export class TimesheetShortcutsDisplayGroup {
    readonly type: string;
    readonly displayName: string;
    readonly displayedShortcuts: TimesheetShortcut[];

    constructor(type: string, displayName: string, displayedShortcuts: TimesheetShortcut[]) {
        this.type = type;
        this.displayName = displayName;
        this.displayedShortcuts = displayedShortcuts;
    }
}

export class TimesheetShortcutsSet {
    private readonly groups: TimesheetShortcutsDisplayGroup[] = [];
    private shortcutsByAbbreviation = {};

    constructor(shortcuts: TimesheetShortcut[], shortcutTypeNames: { [type: string]: string }) {
        for (const item of shortcuts) {
            this.shortcutsByAbbreviation[item.Abbreviation] = item;
        }
        for (const type in shortcutTypeNames) {
            const displayedShortcuts: TimesheetShortcut[] = [];
            for (const item of shortcuts) {
                if (item.Type === type && item.ShowAsOption) {
                    displayedShortcuts.push(item);
                }
            }
            if (displayedShortcuts.length > 0) {
                this.groups.push(new TimesheetShortcutsDisplayGroup(type, shortcutTypeNames[type], displayedShortcuts));
            }
        }
    }

    tryGetByAbbreviation(abbreviation: string): TimesheetShortcut { return this.shortcutsByAbbreviation[abbreviation]; }

    getDisplayGroups(): TimesheetShortcutsDisplayGroup[] { return this.groups; }
}
