import { C } from "./component";
import { Requester } from "./requester";
import { AuthenticationHandler } from "./authentication-handler";
import { Loader } from "./loader";
import { MessageDisplayer } from "./message-displayer";
import { FormActionResultFile } from "../forms/form";

export class DownloadHelper {
    static getFileDownloadUrl(url: string, parameters?: { [parameterName: string]: string }) {
        if (!parameters) {
            parameters = {};
        }
        parameters[AuthenticationHandler.controlTokenParamName] = localStorage[AuthenticationHandler.controlTokenParamName];
        return Requester.getUrl(url, parameters);
    }

    static downloadFileWithPostRequest(url, postData) { this.getFileWithPostRequest(url, postData, this.downloadFile); }

    static download(file: FormActionResultFile) {
        const extension = file.Name.split(".").pop();
        const content = `data:application/${extension};base64,${file.Content}`;
        const downloadLink = C.a.attr("href", content).attr("download", file.Name).appendTo(C.body);
        downloadLink[0].click();
        downloadLink.remove();
    }

    private static downloadFile(name, content) {
        const downloadLink = C.a.attr("href", content).attr("download", name).appendTo(C.body);
        downloadLink[0].click();
        downloadLink.remove();
    }

    private static async getFileWithPostRequest(url, postData, callback) {
        Loader.startGlobally();
        try {
            const file = await Requester.post(url, postData);
            const extension = file.name.split(".").pop();
            const content = `data:application/${extension};base64,${file.content}`;
            callback(file.name, content);
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
        }
        finally {
            Loader.stopGlobally();
        }
    }
}
