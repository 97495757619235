import * as $ from "jquery";
import { C } from "../common/component";
import { TimesheetLeftTableData } from "./timesheet-models";
import { EditableTableCalculator } from "../common/editable-table";
import { Displayer } from "../common/displayer";

export class TimesheetLeftTable {
    private readonly leftTable: any;
    private readonly width: number;
    private readonly headerRow;

    constructor(leftTable: TimesheetLeftTableData, width: number, isCompact: boolean, calculator: EditableTableCalculator) {
        this.initDragScroll();

        this.width = width;
        this.leftTable = C.table.addClass("table table-bordered table-sm schedule-table");
        const leftBody = C.tbody;

        const header = C.thead.appendTo(this.leftTable);
        this.headerRow = C.tr.css("height", "27px").appendTo(header);
        let titleColspan = 0;
        for (const column of leftTable.Columns) {
            titleColspan += column.Colspan;
        }
        const titleCell = C.th.attr("colspan", titleColspan).attr("nowrap", "nowrap").appendTo(this.headerRow);
        titleCell.text(leftTable.Title);
        titleCell.attr("data-container", "body").attr("title", leftTable.TitleTooltip).tooltip();

        this.headerRow = C.tr.addClass("header-row").appendTo(header);
        for (const column of leftTable.Columns) {
            const headerCell = C.th.text(column.DisplayName).attr("colspan", column.Colspan).appendTo(this.headerRow);
            if (isCompact) {
                headerCell.attr("title", column.DisplayName).css("max-width", "50px").css("overflow-x", "hidden");
            }
            if (column.Tooltip) {
                headerCell.attr("data-container", "body").attr("title", column.Tooltip).tooltip();
            }
        }
        // HACK: Unfortunately the idiots who designed css could not see that somebody may want to use min-height property with something
        // else than div, which means we have to add this stupid and completely unnecessary div here, to force min-height to our header row
        C.div.css("width", "0").css("min-height", "72px").appendTo(this.headerRow);
        leftBody.appendTo(this.leftTable);

        for (const rowData of leftTable.Rows) {
            const row = C.tr.addClass("left-body-row").height(rowData.RowHeight)
                .appendTo(leftBody);
            for (const cellData of rowData.Cells) {
                const cell = C.td.css("white-space", "nowrap").attr("rowspan", cellData.Rowspan).appendTo(row);
                if (isCompact) {
                    cell.css("max-width", "100px").css("overflow-x", "hidden");
                }
                if (cellData.Value) {
                    Displayer.setValue(cell, cellData.Value, null, false, true);
                } else {
                    calculator.addCell(cell, cellData);
                }
            }
        }
    }

    appendTo(parent) {
        const leftColumn = C.div
            .addClass(`left-table col-xs-${this.width} schedule-col table-responsive schedule-border`).dragScroll();
        this.leftTable.appendTo(leftColumn.appendTo(parent));
        // HACK: Without this row if left table does not have horizontal scrollbar and right table has, then scrollbar is displayed over
        // last row in right table.This is needed to ensure there is always enough room for the scrollbar.
        C.div.css("height", "17px").appendTo(leftColumn);
    }

    getHeaderHeight() {
        return this.headerRow.height();
    }

    private initDragScroll() {
        $.fn.dragScroll = function () {
            let attachment = false, lastPosition, position, difference;
            C.window.on("mouseup", function () {
                attachment = false;
            });
            return C.create(this).css("cursor", "move").css("user-select", "none").on("mousedown mouseup mousemove",
                function (e) {
                    if (e.type === "mousedown") {
                        attachment = true, lastPosition = [e.clientX, e.clientY];
                    }
                    if (e.type === "mouseup") {
                        attachment = false;
                    }
                    if (e.type === "mousemove" && attachment === true) {
                        position = [e.clientX, e.clientY];
                        difference = [(position[0] - lastPosition[0]), (position[1] - lastPosition[1])];
                        C.create(this).scrollLeft(C.create(this).scrollLeft() - difference[0]);
                        C.create(this).scrollTop(C.create(this).scrollTop() - difference[1]);
                        lastPosition = [e.clientX, e.clientY];
                    }
                });
        };
    }
}
