import { FileUploader } from "./file-uploader";

export class FileInfo {
    private readonly fileName: string;
    private readonly uploader: FileUploader;
    private readonly reference: string;

    constructor(fileName: string, uploader: FileUploader, reference?: string) {
        this.fileName = fileName;
        this.uploader = uploader;
        this.reference = reference;
    }

    static parseFromReference(reference: string) {
        const parts = reference.split("|");
        return new FileInfo(parts[parts.length - 1], null, reference);
    }

    getReference(): string {
        if (this.reference) {
            return this.reference;
        }
        if (this.uploader.hasError() || !this.uploader.isFinished()) {
            throw new Error();
        }
        return this.uploader.getReference();
    }

    hasUploader(): boolean {
        return !!this.uploader;
    }

    getUploader(): FileUploader {
        return this.uploader;
    }

    getFileName(): string {
        return this.fileName;
    }
}
