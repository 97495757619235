import "../external/lightbox";
import { HtmlRenderer, Parser } from "commonmark";
import QRCode from "qrcode";
import { C } from "./component";
import { ConfirmationDialogHelper } from "./confirmation-dialog-helper";
import { DialogTable } from "./dialog-table";
import { Form, FormDisplayer } from "../forms/form";
import { Helper } from "./helper";
import { Icon, IconHelper } from "./icon-helper";
import { Requester } from "./requester";
import { MessageDisplayer } from "./message-displayer";
import { DownloadHelper } from "./download-helper";
import { SeatingPlan } from "./seating-plan";
import { MapCell } from "../maps/map-cell";
import { NavigationUrlHelper, PageNavigationInfo } from "../navigation/navigation-url-helper";
import { Disposable, PageNavigator } from "../navigation/page-navigator";
import { Application } from "../navigation/application";
import { ModalBuilder } from "./modal-builder";
import { ItemPage } from "../dashboard/item-page";
import { ButtonBuilder } from "./button-builder";

// ReSharper disable InconsistentNaming
export interface DisplayValue {
    Type: string;
    Text: string;
    Url: string;
    BackgroundColor: string;
    Description: string;
    Image: string;
    LeftIcon: IconInfo;
    Icons: IconInfo[];
    OnChangeType: string;
}

export interface IconInfo {
    Icon: string;
    Description: string;
    Color: string;
}

export interface LocationDisplayValue extends DisplayValue {
    StartLocation;
    EndLocation;
    ProjectLocation;
}

// ReSharper restore InconsistentNaming

class DisposeSecondary implements Disposable {
    dispose(): void {
        Application.secondaryElement.empty();
    }

    enable(): void {
    }

    disable(): void {
    }
}

export class Displayer {
    public static secondaryDisposer = new DisposeSecondary();
    private static imageGroupCounter = 0;

    static setValue(cell,
                    displayValue: DisplayValue,
                    onDataChanged?: (onChangeType: string) => void,
                    disableLinks: boolean = false,
                    forceNoWrap: boolean = false) {
        cell.empty();
        cell.unbind("click");
        cell.css("background-color", "");
        if (!displayValue) {
            return;
        }

        if (displayValue.Type === "Markdown") {
            if (Helper.hasValue(displayValue.Text)) {
                const markdownReader = new Parser();
                const markdownWriter = new HtmlRenderer();
                cell.html(markdownWriter.render(markdownReader.parse(Helper.removeScriptTags(displayValue.Text))));
            }
            return;
        }

        if (displayValue.BackgroundColor) {
            cell.css("background-color", displayValue.BackgroundColor);
        }
        const imageGroupCounter = this.imageGroupCounter++;
        if (displayValue.Type === "Array") {
            let isFirst = true;
            for (const child of (<any>displayValue).Values) {
                if (isFirst) {
                    isFirst = false;
                } else {
                    C.create((<any>displayValue).Separator).appendTo(cell);
                }
                for (const element of this.getDisplayElements(child, onDataChanged, disableLinks, forceNoWrap, cell, imageGroupCounter)) {
                    element.appendTo(cell);
                }
            }
        } else {
            for (const element of
                this.getDisplayElements(displayValue, onDataChanged, disableLinks, forceNoWrap, cell, imageGroupCounter)) {
                element.appendTo(cell);
            }
        }
    }

    private static getDisplayElements(displayValue: DisplayValue,
                                      onDataChanged: (onChangeType: string) => void,
                                      disableLinks: boolean,
                                      forceNoWrap: boolean,
                                      cell,
                                      imageGroupCounter: number): JQuery[] {
        if (displayValue.Type === "Link") {
            return [C.a.attr("href", displayValue.Url).attr("target", "_blank").text(displayValue.Text)];
        }
        if (displayValue.Type === "File") {
            return [this.getFileElement(displayValue, onDataChanged, cell, imageGroupCounter)];
        }

        const hasLinks = !disableLinks && displayValue.Type !== "Text";
        const elements = [];
        if (displayValue.Image) {
            elements.push(C.img.addClass("img-circle img-responsive").attr("src", displayValue.Image)
                .css("display", "inline-block"));
        }
        if (displayValue.LeftIcon) {
            elements.push(this.getIconElement(displayValue.LeftIcon, hasLinks));
        }
        if (displayValue.Text) {
            const text = (displayValue.Image ? " " : "") + displayValue.Text + (displayValue.Icons.length === 0 ? "" : " ");
            if (!hasLinks) {
                const span = C.span;
                if (forceNoWrap) {
                    span.css("white-space", "nowrap");
                } else {
                    // pre-wrap is needed to preserve line breaks in text
                    span.css("white-space", "pre-wrap");
                }
                if (displayValue.Type === "Text") {
                    span.html(Helper.urlify(Helper.escapeHtml(text)));
                } else {
                    span.text(text);
                }
                if (displayValue.Description) {
                    span.attr("data-container", "body").attr("title", displayValue.Description).tooltip();
                }
                elements.push(span);
            } else {
                elements.push(C.a.text(text).css("cursor", "pointer"));
            }
        }
        for (const iconInfo of displayValue.Icons) {
            elements.push(this.getIconElement(iconInfo, hasLinks));
        }
        if (!hasLinks) {
            return elements;
        }
        for (const element of elements) {
            element.click(async () => await this.DoClickAction(displayValue, onDataChanged, cell, imageGroupCounter));
        }
        return elements;
    }

    private static getIconElement(iconInfo: IconInfo, hasLinks: boolean): any {
        const element = C.span.addClass("icon-cell").attr("title", iconInfo.Description).tooltip();
        if (iconInfo.Color) {
            IconHelper.addColorfulIcon(element, Icon[iconInfo.Icon], iconInfo.Color);
        } else {
            IconHelper.addIcon(element, Icon[iconInfo.Icon]);
        }
        if (hasLinks) {
            element.css("cursor", "pointer");
        }
        return element;
    }

    private static async DoClickAction(displayValue: DisplayValue,
                                       onDataChanged: (onChangeType: string) => void,
                                       cell,
                                       imageGroupCounter: number) {
        const displayValueAny = <any>displayValue;
        if (displayValue.Type === "Form") {
            const form = new Form(displayValue.Url);
            if (onDataChanged) {
                form.onDataChanged = (value, multipleItemsEdited) => {
                    onDataChanged(displayValue.OnChangeType ?? (multipleItemsEdited ? "FullRefresh" : null));
                };
            }
            await FormDisplayer.openInModal(form);
        } else if (displayValue.Type === "Confirmation") {
            ConfirmationDialogHelper.showDialog(displayValueAny.ConfirmationText,
                displayValueAny.ConfirmedUrl,
                (newDisplayValue) => {
                    this.setValue(cell, newDisplayValue, onDataChanged);
                    if (onDataChanged) {
                        onDataChanged(displayValue.OnChangeType);
                    }
                }, true);
        } else if (displayValue.Type === "DialogTable") {
            await new DialogTable(displayValue.Url, () => onDataChanged(null)).open();
        } else if (displayValue.Type === "Location") {
            new MapCell().showLocation(<LocationDisplayValue>displayValue);
        } else if (displayValue.Type === "Page") {
            const pageInfo = new PageNavigationInfo();
            pageInfo.page = displayValueAny.Page;
            pageInfo.subPage = displayValueAny.SubPage;
            await PageNavigator.navigateToPageUsingInfo(pageInfo);
        } else if (displayValue.Type === "ItemPage") {
            PageNavigator.hideMainElement();
            Application.secondaryElement.empty();
            PageNavigator.addDisposableElement(this.secondaryDisposer);
            const currentPage = NavigationUrlHelper.getCurrentPage();
            currentPage.itemPage = displayValue.Url;
            NavigationUrlHelper.setCurrentPage(currentPage);
            await new ItemPage(displayValue.Url, () => {
                Application.secondaryElement.empty();
                PageNavigator.showMainElement();
            }).appendTo(Application.secondaryElement);
        } else if (displayValue.Type === "SeatingPlanButton") {
            await new SeatingPlan(displayValue.Url).load(async (seats) => {
                try {
                    await Requester.send(Requester.getUrl(displayValueAny.SaveUrl, {seat: seats[0]}));
                    return true;
                } catch (err) {
                    MessageDisplayer.showErrorFromServer(err);
                    return false;
                }
            });
        } else if (displayValue.Type === "QrCode") {
            const qrData = await QRCode.toDataURL(displayValue.Url);
            const link = C.a.attr("href", qrData).attr("data-lightbox", "group-" + imageGroupCounter).appendTo(C.body);
            link[0].click();
            link.remove();
        } else if (displayValue.Type === "Copy") {
            await navigator.clipboard.writeText(displayValue.Url);
        }
        return false;
    }

    private static getFileElement(displayValue: DisplayValue,
                                  onDataChanged: (onChangeType: string) => void,
                                  cell,
                                  imageGroupCounter: number) {
        const url = DownloadHelper.getFileDownloadUrl(displayValue.Url);
        const hasImagePreview = Helper.isImage(displayValue.Text);
        const hasPdfPreview = (displayValue.Text || "").toLowerCase().endsWith(".pdf") && !Helper.isMobile();
        if (!hasImagePreview && !hasPdfPreview) {
            return C.a.attr("href", url).text(displayValue.Text);
        }
        const linkContainer = C.span;
        const previewLink = C.a.text(displayValue.Text).appendTo(linkContainer);
        C.span.text(" ").appendTo(linkContainer);
        const downloadLink = C.a.attr("href", url).appendTo(linkContainer);
        const downloadIcon = C.span.addClass("icon-cell").appendTo(downloadLink);
        IconHelper.addIcon(downloadIcon, Icon.download);
        downloadIcon.css("cursor", "pointer");

        if (hasImagePreview) {
            previewLink.attr("href", url).attr("data-lightbox", "group-" + imageGroupCounter);
        } else if (hasPdfPreview) {
            previewLink.css("cursor", "pointer").click(async () => {
                this.openPdfPreview(displayValue, newDisplayValue => {
                    this.setValue(cell, newDisplayValue, onDataChanged);
                    if (onDataChanged) {
                        onDataChanged(displayValue.OnChangeType);
                    }
                });
            });
        }
        return linkContainer;
    }

    public static openPdfPreview(displayValue: DisplayValue, onChange: (newDisplayValue: DisplayValue) => void) {
        const modal = ModalBuilder.createModal(displayValue.Text);
        modal.dialog.css("width", "880px").css("height", "1400px");
        C.object.addClass("preview-pdf").attr("data", DownloadHelper.getFileDownloadUrl(displayValue.Url, {pdfPreview: "true"})).appendTo(modal.body);
        if ((<any>displayValue).ButtonText) {
            ButtonBuilder.get({
                name: (<any>displayValue).ButtonText,
                onClick: async () => ConfirmationDialogHelper.showDialog(
                    (<any>displayValue).ConfirmationText,
                    (<any>displayValue).ConfirmedUrl,
                    newDisplayValue => {
                        ModalBuilder.removeModal(modal.container);
                        onChange(newDisplayValue);
                    }, true)
            }).appendTo(modal.body);
        }
        modal.container.modal({show: true});
    }
}
