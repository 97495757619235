import { Urls } from "./urls";
import { Requester } from "./requester";
import { PushNotifications } from "./push-notifications";

export class AuthenticationHandler {
    static readonly controlTokenParamName = "wct";

    static async isLoggedIn(): Promise<boolean> {
        if (localStorage[AuthenticationHandler.controlTokenParamName]) {
            try {
                const shouldLogout = <boolean>await Requester.get(Urls.authenticationShouldLogOut);
                if (shouldLogout) {
                    await this.logout();
                    return false;
                }
            } catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }

    static async logout() {
        const endpoint = await PushNotifications.unsubscribeAndGetEndpoint();
        localStorage[AuthenticationHandler.controlTokenParamName] = null;
        await Requester.send(Requester.getUrl(Urls.authenticationLogOut, {pushToken: endpoint}));
    }
}
