import "maphilight";
import * as $ from "jquery";
import { ModalBuilder } from "./modal-builder";
import { C } from "./component";
import { Requester } from "./requester";
import { Txt } from "./text";
import { MessageDisplayer } from "./message-displayer";
import { ButtonBuilder } from "./button-builder";

// ReSharper disable InconsistentNaming
interface SeatingPlanModel {
    Floors: FloorModel[];
    DefaultFloor: number;
    SelectedSeat: number;
    RemoveBookingUrl: string;
}

interface FloorModel {
    Id: number;
    Name: string;
    Image: string;
    Seats: SeatModel[];
    HeightToWidthRatio: number;
}

interface SeatModel {
    Id: number;
    IsAvailable: boolean;
    X: number;
    Y: number;
    Occupier: string;
    OccupierInitials: string;
}

// ReSharper restore InconsistentNaming

export class SeatingPlan {
    private readonly url: string;
    private readonly hideWithoutSeatButton: boolean;
    private readonly returnIfSeatIsAlreadySelected: boolean;
    private readonly canSelectMultiple: boolean;

    constructor(
        url: string,
        hideWithoutSeatButton = true,
        returnIfSeatIsAlreadySelected: boolean = false,
        canSelectMultiple: boolean = false,
    ) {
        this.url = url;
        this.hideWithoutSeatButton = hideWithoutSeatButton;
        this.returnIfSeatIsAlreadySelected = returnIfSeatIsAlreadySelected;
        this.canSelectMultiple = canSelectMultiple;
    }

    async load(onSeatSelected: (seats: number[]) => Promise<boolean>, selectedSeats?: number[]) {
        $.fn.maphilight.defaults = {
            fill: true,
            fillColor: "008800",
            fillOpacity: 0.2,
            stroke: false,
            strokeColor: "ff0000",
            strokeOpacity: 1,
            strokeWidth: 1,
            fade: true,
            alwaysOn: true,
            neverOn: false,
            groupBy: false,
            wrapClass: false,
            shadow: false,
            shadowX: 0,
            shadowY: 0,
            shadowRadius: 6,
            shadowColor: "009900",
            shadowOpacity: 0.8,
            shadowPosition: "outside",
            shadowFrom: false
        };

        const seatingPlanData: SeatingPlanModel = await Requester.get(this.url);
        if (seatingPlanData.SelectedSeat && this.returnIfSeatIsAlreadySelected) {
            onSeatSelected(null);
            return;
        }
        if (!selectedSeats) {
            selectedSeats = [];
            if (seatingPlanData.SelectedSeat) {
                selectedSeats.push(seatingPlanData.SelectedSeat);
            }
        }

        const modal = ModalBuilder.createModal(Txt.chooseDesk);
        modal.container.on("hidden.bs.modal", () => modal.container.remove());
        const buttonGroup = C.buttonGroup.css("overflow", "auto").appendTo(modal.body);
        const container = C.div.attr("width", "500px").appendTo(modal.body);

        const floorPlans: { [id: number]: FloorPlan } = {};
        let activeFloorPlan: FloorPlan;
        let confirmButton = null;
        if (!seatingPlanData.SelectedSeat) {
            confirmButton = ButtonBuilder.get({
                name: Txt.confirmDeskSelection,
                cssClass: "main-button",
                onClick: async () => {
                    if (this.canSelectMultiple) {
                        let seats: number[] = [];
                        for (const id in floorPlans) {
                            seats = seats.concat(floorPlans[id].getSelectedSeats());
                        }
                        const success = await onSeatSelected(seats);
                        if (success) {
                            modal.container.modal("hide");
                        }
                        return;
                    }
                    if (activeFloorPlan) {
                        const seats = activeFloorPlan.getSelectedSeats();
                        if (seats && seats.length > 0) {
                            const success = await onSeatSelected(seats);
                            if (success) {
                                modal.container.modal("hide");
                            }
                        }
                    }
                }
            }).appendTo(modal.body);
            if (!this.canSelectMultiple) {
                confirmButton.attr("disabled", "disabled");
            }
            if (!this.hideWithoutSeatButton) {
                this.getStartWithoutSeatButton(onSeatSelected, modal).appendTo(modal.body);
                this.getStartWithoutSeatButton(onSeatSelected, modal).appendTo(buttonGroup);
            }
        } else if (seatingPlanData.RemoveBookingUrl) {
            ButtonBuilder.get({
                name: Txt.removeDeskBooking,
                isSecondary: true,
                cssClass: "margin-top-5",
                onClick: async () => {
                    try {
                        await Requester.send(seatingPlanData.RemoveBookingUrl);
                        modal.container.modal("hide");
                    } catch (err) {
                        MessageDisplayer.showErrorFromServer(err);
                    }
                }
            }).appendTo(modal.body);
        }

        const onChange = this.canSelectMultiple || !confirmButton ?
            onSelected => null :
            (onSelected => onSelected ? confirmButton.removeAttr("disabled") : confirmButton.attr("disabled", "disabled"));
        const defaultFloor = seatingPlanData.DefaultFloor;
        for (const floor of seatingPlanData.Floors) {
            ButtonBuilder.get({
                name: floor.Name,
                cssClass: "main-button",
                onClick: async () => {
                    for (const floorId in floorPlans) {
                        if (!seatingPlanData.SelectedSeat && !this.canSelectMultiple) {
                            floorPlans[floorId].unselectAll();
                        }
                        floorPlans[floorId].hide();
                    }
                    onChange(false);
                    activeFloorPlan = floorPlans[floor.Id];
                    if (!activeFloorPlan) {
                        activeFloorPlan =
                            floorPlans[floor.Id] = new FloorPlan(floor, container, !seatingPlanData.SelectedSeat,
                                onChange, this.canSelectMultiple, selectedSeats);
                    } else {
                        activeFloorPlan.show();
                    }
                }
            }).appendTo(buttonGroup);
            if (defaultFloor === floor.Id) {
                activeFloorPlan =
                    floorPlans[floor.Id] =
                        new FloorPlan(floor, container, !seatingPlanData.SelectedSeat, onChange, this.canSelectMultiple, selectedSeats);
            }
        }
        modal.container.modal({show: true});
    }

    private getStartWithoutSeatButton(onSeatSelected: (seats: number[]) => Promise<boolean>, modal) {
        return ButtonBuilder.get({
            name: Txt.continueWithoutChoosingDesk,
            isSecondary: true,
            cssClass: "margin-top-5",
            onClick: async () => {
                const success = await onSeatSelected(null);
                if (success) {
                    modal.container.modal("hide");
                }
            }
        });
    }
}

class FloorPlan {
    private readonly onChange: (isSelected: boolean) => {};
    private readonly container;
    private selectedSeats: number[] = [];
    private selectedSeatSquares: { [id: number]: any } = {};

    constructor(
        floor: FloorModel,
        parent,
        canChange: boolean,
        onChange: (isSelected: boolean) => {},
        canChooseMultiple: boolean,
        selectedSeats: number[],
    ) {
        this.onChange = onChange;
        this.container = C.div.css("position", "relative").appendTo(parent);

        let baseWidth = 500;
        let multiplier = 1;
        if (C.window.width() < 560 && C.window.width() >= 325) {
            this.container.css("margin-left", "-20px");
            baseWidth = C.window.width() - 25;
            baseWidth = Math.round(baseWidth / 10) * 10;
            multiplier = baseWidth / 500.0;
        }
        const baseUnit = Math.round(10 * multiplier);

        const image = C.img.attr("src", floor.Image).attr("usemap", `#floorMap${floor.Id}`)
            .attr("width", baseWidth + "px")
            // TODO: HACK: maphilight does not work if modal is opened multiple times and height is no set. Get rid of maphilight.
            .attr("height", (baseWidth * floor.HeightToWidthRatio) + "px")
            .appendTo(this.container);
        const floorMap = C.map.attr("name", `floorMap${floor.Id}`).appendTo(this.container);

        for (const seat of floor.Seats) {
            this.selectedSeatSquares[seat.Id] = this.getArea("-" + baseUnit + ",-" + baseUnit + ",-" + baseUnit + ",-" + baseUnit,
                "60a060", 1, true,
                () => this.unselect(seat.Id)).trigger("alwaysOn.maphilight").appendTo(floorMap);
            const x = Math.round(seat.X * multiplier / 2);
            const y = Math.round(seat.Y * multiplier / 2);
            const coords = x + "," + y + "," + (x + 3 * baseUnit) + "," + (y + 3 * baseUnit);
            if (seat.IsAvailable) {
                this.getArea(coords, "008800", 0.4, false, !canChange ? null : () => {
                    if (!canChooseMultiple) {
                        this.unselectAll();
                    }
                    this.selectedSeatSquares[seat.Id].attr("coords", coords).trigger("alwaysOn.maphilight");
                    this.selectedSeats.push(seat.Id);
                    onChange(true);
                }).appendTo(floorMap);
            } else if (seat.Occupier) {
                C.div.text(seat.OccupierInitials).css("position", "absolute").css("top", y + 2).css("left", x)
                    .css("width", (3 * baseUnit) + "px").css("height", (3 * baseUnit) + "px").css("text-align", "center")
                    .css("font-size", (2 * baseUnit) + "px").css("color", "white").attr("title", seat.Occupier)
                    .appendTo(this.container).tooltip();
            }
            this.getArea(coords, seat.IsAvailable ? "008800" : "880000", seat.IsAvailable ? 0.2 : 0.4, true).appendTo(floorMap);
            if (selectedSeats.includes(seat.Id)) {
                this.selectedSeatSquares[seat.Id].attr("coords", coords).trigger("alwaysOn.maphilight");
                this.selectedSeats.push(seat.Id);
            }
        }
        image.maphilight();
    }

    unselectAll() {
        for (const id of this.selectedSeats) {
            this.unselect(id);
        }
    }

    show() {
        this.container.show();
    }

    hide() {
        this.container.hide();
    }

    getSelectedSeats(): number[] {
        return this.selectedSeats;
    }

    private unselect(id: number) {
        this.selectedSeatSquares[id].attr("coords", "-10,-10,-10,-10").trigger("alwaysOn.maphilight");
        // tslint:disable-next-line: triple-equals
        this.selectedSeats = this.selectedSeats.filter(x => x != id);
        this.onChange(false);
    }

    private getArea(coords, color, opacity, alwaysOn, onClick?) {
        const area = C.area.attr("shape", "rect").attr("coords", coords);
        area.data("maphilight", {fillColor: color, alwaysOn: alwaysOn, fillOpacity: opacity});
        if (onClick) {
            area.attr("href", "javascript:;");
            area.click(() => onClick());
        }
        return area;
    }
}
