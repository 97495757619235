import { C } from "../common/component";
import { Icon } from "../common/icon-helper";
import { Requester } from "../common/requester";
import { Form, FormDisplayer } from "../forms/form";
import { ItemPageMainDataPanel } from "./panels/item-page-main-data-panel";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { DashboardPanel } from "./panels/dashboard-panel";

// ReSharper disable InconsistentNaming
export interface ItemPageMainPanelButton {
    Name: string;
    Url: string;
    Type: string;
}

export interface ItemPageMainPanelData {
    Properties: any[];
    Buttons: ItemPageMainPanelButton[];
    SettingsUrl: string;

    IsProjectPage: boolean;
    CustomData: any;
}

export interface ItemPageData {
    MainPanelData: ItemPageMainPanelData;
    PanelsUrls: string[];
    NewExtraTableUrl: string;
    NewExtraTableText: string;
}
// ReSharper restore InconsistentNaming

export class ItemPage {
    private readonly url;
    private readonly onClose;
    private readonly container;

    constructor(url: string, onClose: () => void, onRightClick?: () => void, onLeftClick?: () => void) {
        this.url = url;
        this.onClose = onClose;
        this.container = C.container;
        if (onClose) {
            ItemPage.addButton(this.container, C.circularRightButton(Icon.X), () => {
                const currentPage = NavigationUrlHelper.getCurrentPage();
                if (currentPage.itemPage) {
                    currentPage.itemPage = null;
                    NavigationUrlHelper.setCurrentPage(currentPage);
                }
                onClose();
            });
        }
        if (onRightClick) {
            ItemPage.addButton(this.container, C.circularRightButton(Icon.angleRight, 250), onRightClick);
        }
        if (onLeftClick) {
            ItemPage.addButton(this.container, C.circularLeftButton(Icon.angleLeft, 250), onLeftClick);
        }
    }

    private static addButton(parent, button, onClick: () => void) {
        const row = C.row.appendTo(parent);
        button.appendTo(row);
        button.click(onClick);
    }

    async appendTo(parent) {
        this.container.appendTo(parent);
        const panelsContainer = C.row.appendTo(this.container);
        await this.createPanels(panelsContainer);
    }

    private async refresh(parent) {
        parent.empty();
        await this.createPanels(parent);
    }
    private async createPanels(parent) {
        const data: ItemPageData = await Requester.get(this.url);
        if (!data) {
            if (this.onClose) {
                this.onClose();
            }
            return;
        }
        new ItemPageMainDataPanel(data.MainPanelData, this.url, () => this.refresh(parent)).appendTo(parent);
        for (const panelUrl of data.PanelsUrls) {
            const panel = new DashboardPanel(panelUrl);
            panel.appendTo(parent);
        }
        if (data.NewExtraTableUrl) {
            const panel = C.div.addClass("col-md-6 zero-padding panel panel-default statistics img-center").appendTo(parent);
            const plusButton = C.plusButton.css("margin-top", "110px").appendTo(panel);
            C.h4.css("height", "34px").text(data.NewExtraTableText).appendTo(panel);
            plusButton.click(async () => {
                const form = new Form(data.NewExtraTableUrl);
                form.onDataChanged = async () => await this.refresh(parent);
                await FormDisplayer.openInModal(form);
            });
        }
    }
}
