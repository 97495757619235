import { C } from "../../common/component";
import { Icon, IconHelper } from "../../common/icon-helper";
import { Loader } from "../../common/loader";
import { ViewSettings } from "../../views/view-settings";
import { Form, FormButtonBehavior, FormButtonModel, FormDisplayer } from "../../forms/form";
import { PanelFilterInfo } from "../../common/simple-table";
import { Requester } from "../../common/requester";
import { DashboardHelper } from "../dashboard-helper";
import { TablePanel } from "./table-panel";
import { ChartPanel } from "./chart-panel";
import { MapPanel } from "./map-panel";
import { PageNavigator } from "../../navigation/page-navigator";

export enum PanelType {
    SimpleTable = "SimpleTable",
    Chart = "Chart",
    Map = "Map"
}

export interface PanelDefinition {
    Title: string;
    SettingsUrl: string;
    Buttons: FormButtonModel[];
    Filters: PanelFilterInfo[];

    Type: PanelType;
    DataUrl: string;
}

export interface DashboardPanelButton {
    name: string;
    onClick: () => void;
}

export interface DashboardPanelContent {
    appendTo(parent: JQuery, getData: () => Promise<any>): void;
    refresh(): Promise<void>;
}

export class DashboardPanel {
    private readonly url: string;
    private readonly inSeparatePage;

    private readonly container;
    private readonly displayingWayChangeIconPlace = C.span.css("cursor", "pointer").css("margin-left", "5px");

    private definition: PanelDefinition;
    private filterControls = {};
    private content: DashboardPanelContent;
    private buttons;

    constructor(url: string, inSeparatePage: boolean = false, containerCss: string = null) {
        this.url = url;
        this.inSeparatePage = inSeparatePage;
        if (!containerCss) {
            containerCss = inSeparatePage
                ? "col-md-12 zero-padding panel panel-default"
                : "col-md-6 zero-padding panel panel-default statistics";
        }
        this.container = C.div.addClass(containerCss);
    }

    show() { this.container.show(); }

    hide() { this.container.hide(); }

    async appendTo(parent) {
        const loader = Loader.startLocally(this.container);
        try {
            this.container.appendTo(parent);
            this.definition = await Requester.get(Requester.getUrl(this.url, {bigVersion: this.inSeparatePage}));

            const headerRow = C.row.css("margin", "0").appendTo(this.container);
            const header = C.span.css("padding", "0").appendTo(headerRow);
            const titleElement = C.header.css("display", "inline-block").text(this.definition.Title).appendTo(header);
            this.displayingWayChangeIconPlace.appendTo(header);

            if (this.definition.SettingsUrl) {
                ViewSettings.addSettingsButton(titleElement, Icon.Cog, null,
                    () => {
                        const form = new Form(this.definition.SettingsUrl);
                        form.onDataChanged = () => this.refresh();
                        FormDisplayer.openInModal(form);
                    });
            }

            this.buttons = C.span.appendTo(headerRow);
            for (const button of this.getButtons().reverse()) {
                C.button.addClass("btn btn-primary btn-statistics").css("margin-right", "5px")
                    .attr("type", "button").text(button.name).click(() => button.onClick()).appendTo(this.buttons);
            }

            this.filterControls =
                DashboardHelper.addFiltersRow(this.definition.Filters, this.container, () => this.refresh());

            if (this.definition.Type === PanelType.SimpleTable) {
                const table = new TablePanel();
                this.content = table;
                PageNavigator.addDisposableElement(table);
            } else if (this.definition.Type === PanelType.Chart) {
                this.content = new ChartPanel();
            } else if (this.definition.Type === PanelType.Map) {
                this.content = new MapPanel(this.inSeparatePage);
            } else {
                throw new Error("Panel type " + this.definition.Type + " not supported");
            }
            this.content.appendTo(this.container, () => this.getData());
            await this.refresh();
        } finally {
            loader.stop();
        }
    }

    async refresh(): Promise<void> { await this.content.refresh(); }

    registerChangeDisplayingWayCallback(changeDisplayingWay: () => void): void {
        IconHelper.getIcon(this.inSeparatePage ? Icon.Minimize : Icon.Maximize)
            .appendTo(this.displayingWayChangeIconPlace);
        this.displayingWayChangeIconPlace.click(() => changeDisplayingWay());
    }

    private getData(): Promise<any> {
        const filterValues = {};
        for (const filterName in this.filterControls) {
            filterValues[filterName] = this.filterControls[filterName].getValue();
        }
        try {
            return Requester.post(this.definition.DataUrl, filterValues);
        } catch {
            return null;
        }
    }

    private getButtons(): DashboardPanelButton[] {
        const buttons: DashboardPanelButton[] = [];
        for (const button of this.definition.Buttons) {
            buttons.push({
                name: button.Name, onClick: async () => {
                    if (button.Behavior === FormButtonBehavior.OpenModal) {
                        const form = new Form(button.Url);
                        form.onDataChanged = async () => {
                            await this.refresh();
                        };
                        await FormDisplayer.openInModal(form);
                    }
                }
            });
        }
        return buttons;
    }
}
