import { Txt } from "./text";
import { C } from "./component";

export class Helper {
    static setColor(element, color) {
        element.css("background-color", color);
    }

    static setColors(element, colors) {
        if (colors.length === 1) {
            Helper.setColor(element, colors[0]);
        } else if (colors.length > 1) {
            let value = "linear-gradient(135deg";
            for (let i = 0; i < colors.length; i++) {
                value += ", " + colors[i % colors.length] + " " +
                    Math.floor(i * 100 / colors.length) + "%" + " " + Math.floor((i + 1) * 100 / colors.length) + "%";
            }
            element.css("background-image", value + ")");
        }
    }

    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent);
    }

    static isApple() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    static get isInternetExplorer() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        }

        return false;
    }

    static isMobileScreen() {
        return C.window.width() < 768;
    }

    static isFloat(value) {
        if (typeof value === "string") {
            value = value.replace(",", ".");
        }
        // tslint:disable-next-line
        return (parseFloat(value) >= 0 && parseFloat(value) == value);
    }

    static parseFloat(value) {
        if (typeof value === "string") {
            value = value.replace(",", ".");
        }
        return parseFloat(value);
    }

    static getDifferenceInDays(firstDate: string, secondDate: string) {
        const differenceInMilliseconds = Helper.stringToDate(firstDate).getTime() - Helper.stringToDate(secondDate).getTime();
        return Math.floor((differenceInMilliseconds + 1000 * 3600 * 12) / (1000 * 3600 * 24));
    }

    static stringToDate(dateString): Date {
        const day = dateString.split(".")[0];
        const month = dateString.split(".")[1];
        const year = dateString.split(".")[2];
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    static dateToString(d) {
        const day = `0${d.getDate()}`.slice(-2);
        const month = `0${d.getMonth() + 1}`.slice(-2);
        return `${day}.${month}.${d.getFullYear()}`;
    }

    static dateTimeToString(d) {
        const hours = `0${d.getHours()}`.slice(-2);
        const minutes = `0${d.getMinutes()}`.slice(-2);
        return Helper.dateToString(d) + " " + hours + ":" + minutes;
    }

    static getMonthName(date) {
        const month = date.getMonth();
        return Txt.monthNames[month];
    }

    static getWeekdayName(date) {
        const weekDay = date.getDay();
        return Txt.dayNamesShort[weekDay];
    }

    static daysBetween(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000;
        return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / (oneDay)));
    }

    static round(nr, precision?) {
        const precisionNr = Math.pow(10, precision || 2);
        return Math.round(nr * precisionNr) / precisionNr;
    }

    static guid() {
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };

        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    }

    static refreshPage() {
        location.reload();
    }

    static hasValue(item) {
        return item !== undefined && item !== null && item !== "";
    }

    static hourString(hour) {
        return (hour < 0) ? `${Math.ceil(hour)} h ${Math.abs(Math.round((hour - Math.ceil(hour)) * 60))} min`
            : `${Math.floor(hour)} h ${Math.round((hour - Math.floor(hour)) * 60)} min`;
    }

    static get isDev() {
        return location.hostname === "localhost" || location.hostname === "127.0.0.1";
    }

    static async loadScript(src: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let s;
            s = document.createElement("script");
            s.src = src;
            s.onload = resolve;
            s.onerror = reject;
            document.head.appendChild(s);
        });
    }

    static getUrlParam(key: string) {
        let itm = null;
        const qs = location.search.substring(1);
        const pattern = /([^&=]+)=([^&]*)/g;
        while (itm = pattern.exec(qs)) {
            if (decodeURIComponent(itm[1]) === key) {
                return decodeURIComponent(itm[2]);
            }
        }
        return null;
    }

    static get mainHeight() {
        const windowHeight = C.window.height();
        const headerHeight = C.navbar.height() + C.navEl.height() + 150;
        return windowHeight - headerHeight;
    }

    static isPWA() {
        return window.matchMedia("(display-mode: standalone)").matches;
    }

    static isImage(fileName): boolean {
        const nameParts = fileName.split(".");
        const imageExtensions = ["jpeg", "jpg", "png"];
        const extension = nameParts[nameParts.length - 1].toLowerCase();
        return imageExtensions.includes(extension);
    }

    static escapeHtml(text: string) {
        if (!text) {
            return null;
        }
        return text.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    static removeScriptTags(text: string) {
        if (!text) {
            return null;
        }
        const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        return text.replace(regex, "");
    }

    static urlify(text: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return `<a href="${url}" target="_blank">${url}</a>`;
        });
    }

    static capitalize(text: string): string {
        if (!text || text.length === 0) {
            return null;
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
