import { Txt } from "./text";
import { C } from "./component";
import { Displayer, DisplayValue } from "./displayer";

// ReSharper disable InconsistentNaming
export interface SimpleTableData {
    Columns: SimpleTableColumn[];
    Data: { [name: string]: DisplayValue }[];
    NextRefresh?: number;
}

export interface PanelFilterInfo {
    Name: string;
    DisplayName: string;
    TypeData: any;
}

export interface SimpleTableColumn {
    Name: string;
    DisplayName: string;
}

// ReSharper restore InconsistentNaming

export class SimpleTable {
    private container = C.div.addClass("chart-div");

    appendTo(parent) {
        this.container.appendTo(parent);
    }

    setData(data: SimpleTableData, onDataChanged?: () => void) {
        this.container.empty();
        const table = C.table.addClass("table table-striped table-responsive").appendTo(this.container);
        this.addHeader(table, data.Columns);

        const tbody = C.tbody.appendTo(table);

        if (data && data.Data.length) {
            for (const rowInfo of data.Data) {
                const dataRow = C.tr.appendTo(tbody);
                for (const columnInfo of data.Columns) {
                    const cell = C.td.appendTo(dataRow);
                    Displayer.setValue(cell, rowInfo[columnInfo.Name], onDataChanged);
                }
            }
        } else {
            const row = C.tr.appendTo(tbody);
            C.td.attr("colspan", data.Columns.length).text(Txt.noRecords).appendTo(row);
        }
    }

    private addHeader(table, columns: SimpleTableColumn[]) {
        const thead = C.thead.appendTo(table);
        const row = C.tr.appendTo(thead);
        for (const columnInfo of columns) {
            C.th.text(columnInfo.DisplayName).appendTo(row);
        }
    }
}
