import { C } from "../../common/component";
import { Helper } from "../../common/helper";
import { Icon, IconHelper } from "../../common/icon-helper";
import { EditControl } from "../form-row";

export class TimeEditControl implements EditControl {
    private static readonly patternRegex = new RegExp("^((0|1|)[0-9]|2[0-3]):[0-5][0-9]$");

    private input: JQuery;
    private inputGroup: JQuery;
    private onChangeAction = () => {};

    constructor(timeAccuracy) {
        this.inputGroup = C.div.addClass("input-group clockpicker");
        this.input = C.input
            .addClass("form-control")
            .attr("type", "text")
            .appendTo(this.inputGroup);

        const addon = C.span.addClass("input-group-addon").appendTo(this.inputGroup);
        C.span.addClass(IconHelper.getIconClass(Icon.time)).appendTo(addon);

        this.inputGroup.clockpicker({
            autoclose: true,
            openIfInputFocused: false,
            afterShow: function () {
                C.clockpickerHours.text("--");
                C.clockpickerMinutes.text("--");
            },
            afterHourSelect: function () {
                C.clockpickerMinutes.text("--");
            }
        });

        this.input.on("keyup keypress", (e) => {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                this.inputGroup.clockpicker("hide");
            }
        });

        this.input.focusin(() => {
            if (!Helper.isMobile()) {
                this.inputGroup.clockpicker("show");
            }
        });

        this.input.on("change", () => {
            if (Helper.hasValue(timeAccuracy)) {
                const value = this.getValue();
                if (value) {
                    const hourMinute = value.split(":");
                    let minute: string | number = Math.floor(parseInt(hourMinute[1]) / timeAccuracy) * timeAccuracy;
                    if (minute.toString().length < 2) {
                        minute = `0${minute}`;
                    }
                    this.input.val(`${hourMinute[0]}:${minute}`);
                }
            }
            this.onChangeAction();
        });
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputGroup[0]);
    }

    async setValue(value) {
        this.input.val(value);
    }

    getValue() {
        if (!this.validate()) {
            return null;
        }
        const value = this.input.val().toString();
        if (value === "" || value === null) {
            return null;
        }
        return value.length === 4 ? "0" + value : value;
    }

    onChange(action) {
        this.onChangeAction = action;
    }

    validate(): boolean {
        const value = this.input.val().toString();
        return !value || TimeEditControl.patternRegex.test(value);
    }

    focus() { this.input.focus(); }
}
