import { C } from "../common/component";
import { EditControlBuilder } from "../forms/edit-control-builder";
import { PanelFilterInfo } from "../common/simple-table";

export class DashboardHelper {
    public static addFiltersRow(filtersInfo: PanelFilterInfo[], parent, onFiltersChange) {
        const filterControls = {};
        const filtersRow = C.div.addClass("row statistics-row").appendTo(parent);
        for (const filter of filtersInfo) {
            const control: any = EditControlBuilder.create(filter.TypeData, null, fieldName => {
                const filterControl = filterControls[fieldName];
                return filterControl ? filterControl.getValue() : null;
            }, filter.DisplayName);
            const column = C.div.addClass(`col-md-${12 / filtersInfo.length}`).appendTo(filtersRow);
            control.initialize(column);
            control.setValue(null);
            control.onChange(onFiltersChange);
            filterControls[filter.Name] = control;
        }
        return filterControls;
    }
}
