import * as $ from "jquery";
import { Form, FormDisplayer } from "../forms/form";
import { Urls } from "./urls";
import { Helper } from "./helper";
import { Icon, IconHelper } from "./icon-helper";
import { Requester } from "./requester";
import { Txt } from "./text";
import { C } from "./component";
import { ModalBuilder } from "./modal-builder";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { PageNavigator } from "../navigation/page-navigator";
import { Displayer, DisplayValue } from "./displayer";

export interface NotificationModel {
    Message: string;
    CreationTime: string;
    IsSeen: boolean;
    Id: string;
    FormUrl: string;
    PdfPreview: DisplayValue;
}

export class Notifications {
    private static notificationsPage: string;
    private static last;
    private static notificationCount: JQuery;
    private static dropdown;

    static additionalProcessingAction: (notifications: NotificationModel[]) => void;

    static initialize(parent, notificationsPage: string) {
        this.notificationsPage = notificationsPage;
        const notificationInterval = 600000;
        const li = C.li.addClass("dropdown").appendTo(parent);
        const a = C.a.addClass("dropdown-toggle notification-button").click(this.open.bind(this))
            .attr("data-toggle", "dropdown").attr("role", "button").attr("aria-haspopup", "true")
            .attr("aria-expanded", "false").appendTo(li);
        this.notificationCount = C.span.addClass("label notification-count").hide().appendTo(a);
        C.span.addClass(`${IconHelper.getIconClass(Icon.Bell)} notification-icon`)
            .attr("title", Txt.notifications).appendTo(a);
        C.span.addClass("mobile").text(` ${Txt.notifications}`).appendTo(a);

        if (!Helper.isMobileScreen()) {
            this.dropdown = C.ul.addClass("dropdown-menu notification scrollbar notification-list").appendTo(li);
        } else {
            const modal = ModalBuilder.createModal(Txt.notifications);
            this.dropdown = C.ul.addClass("list-unstyled notification-list").appendTo(modal.body);
            a.click(() => {
                modal.container.modal({
                    show: true
                });
            });
        }
        this.refresh();
        setInterval(async () => await this.refresh(), notificationInterval);
    }

    private static unreadCount(text) {
        this.notificationCount.text(text).show();
    }

    private static unreadNone() {
        this.notificationCount.text("").hide();
    }

    private static async open() {
        this.unreadNone();

        if (this.last) {
            await Requester.get(Requester.getUrl(Urls.markNotificationsAsSeen, {
                lastSeenId: this.last.Id,
            }), {
                dataType: null,
            });
        }
    }

    static async refresh() {
        const data = await Requester.get(Urls.notifications);
        this.process(data);
        if (this.additionalProcessingAction) {
            this.additionalProcessingAction(data);
        }
    }

    private static process(notifications) {
        this.dropdown.empty();

        if (notifications.length === 0) {
            C.p.text(Txt.noMessages).appendTo(C.li.appendTo(this.dropdown));
        } else {
            this.last = notifications[0];

            let unreadCount = 0;
            $.each(notifications, (_, notification) => {
                if (!notification.IsSeen) {
                    unreadCount++;
                }

                const container = C.li.appendTo(this.dropdown);
                C.p.html(`${Helper.removeScriptTags(notification.Message)}<br><small>${notification.CreationTime}</small>`)
                    .appendTo(container);

                if (notification.PdfPreview) {
                    container.addClass("clickable-notification");
                    container.click(() => {
                        Displayer.openPdfPreview(notification.PdfPreview, () => {});
                    });
                } else if (notification.FormUrl) {
                    container.addClass("clickable-notification");
                    container.click(() => {
                        const currentPage = NavigationUrlHelper.getCurrentPage();
                        currentPage.formUrl = notification.FormUrl;
                        NavigationUrlHelper.setCurrentPage(currentPage);
                        FormDisplayer.openInModal(new Form(notification.FormUrl));
                    });
                } 
            });

            if (unreadCount > 0) {
                this.unreadCount(unreadCount);
            }

            const showAllContainer = C.li.addClass("clickable-notification")
                .css("text-align", "center").css("font-weight", "bold").appendTo(this.dropdown);
            C.p.html(Txt.showAll).appendTo(showAllContainer);
            showAllContainer.click(() => {
                PageNavigator.navigateToPage(this.notificationsPage);
            });
        }
    }
}
