import { C } from "./component";

export class LocalLoader {
    private readonly overlay: any;

    constructor(parent) {
        this.overlay = C.div.addClass("loader-loading").hide().appendTo(parent);
        C.div.addClass("loader").appendTo(this.overlay);
        this.overlay.show();
    }

    start() { this.overlay.show(); }
    stop() { this.overlay.hide(); }
    setStyle(name: string, value: string) { this.overlay.css(name, value); }
}

export class Loader {
    private static overlay: any;
    private static logo: any;
    private static customText: any;

    private static showCount = 0;

    static create(parent) {
        Loader.overlay = C.div.addClass("overlay").hide().appendTo(parent);
        const element = C.div.addClass("loader-loading").appendTo(Loader.overlay);
        const imageContainer = C.div.addClass("img-container").appendTo(element);
        Loader.logo = C.img.attr("src", "images/loading.png").appendTo(imageContainer);
        Loader.customText = C.p.css("font-size", "large").css("color", "black").hide().appendTo(element);
        C.div.addClass("loader").appendTo(element);
    }

    static startGlobally(showLogo: boolean = false, customText: string = "") {
        Loader.overlay.show();
        if (showLogo) {
            Loader.logo.show();
        }
        if (customText) {
            Loader.customText.text(customText).show();
        }
        this.showCount++;
    }

    static stopGlobally() {
        if (--this.showCount === 0) {
            Loader.overlay.hide();
            Loader.logo.hide();
            Loader.customText.text("").hide();
        }
    }

    static startLocally(parent): LocalLoader {
        const loader = new LocalLoader(parent);
        loader.start();
        return loader;
    }
}
