import { Txt } from "../common/text";
import { C } from "../common/component";
import { Icon, IconHelper } from "../common/icon-helper";
import { TimerService } from "./timer-service";
import { Form, FormDisplayer } from "../forms/form";
import { DashboardPanel } from "../dashboard/panels/dashboard-panel";

export class TimerPage {
    private readonly container: JQuery;
    private readonly timerLabel: JQuery;
    private readonly pauseLabel: JQuery;
    private readonly startTimerButton: JQuery;
    private readonly formContainer: JQuery;
    private lastWorkingTimesPanel: DashboardPanel;

    private isUpdateInProgress = false;

    constructor(parent: JQuery) {
        this.container = C.container.appendTo(parent);
        const timersPanel = C.div.appendTo(C.col.appendTo(this.container)).css({
            "padding-bottom": "10px",
            "text-align": "center",
            "width": "100%"
        });

        this.timerLabel = C.div.css({
            "width": "100%",
            "text-align": "center",
            "padding": "10px",
            "color": "rebeccapurple",
            "font-size": "x-large"
        }).appendTo(timersPanel);
        this.pauseLabel = C.div.css({
            "width": "100%",
            "text-align": "center",
            "padding": "10px",
            "margin-top": "-15px",
            "color": "rebeccapurple",
            "font-size": "large"
        }).appendTo(timersPanel);

        this.startTimerButton =
            C.button.attr("type", "button").css({
                "height": "120px",
                "width": "120px",
                "border-radius": "50%",
                "border": "0px",
                "background-color": "rebeccapurple",
                "margin-bottom": "20px"
            }).attr("title", Txt.startLogging).appendTo(timersPanel).hide();
        this.startTimerButton.append(IconHelper.getIconColorful(Icon.Play, "#ffffff")
            .css("font-size", "50px")
            .css("margin-left", "10px"));
        this.startTimerButton.click(() => TimerService.buttonClicked());

        this.formContainer = C.div.appendTo(this.container);
    }

    async addLastWorkingTimesPanel( url: string) {
        this.lastWorkingTimesPanel = new DashboardPanel(url, false, "col-md-6 zero-padding");
        this.lastWorkingTimesPanel.hide();
        await this.lastWorkingTimesPanel.appendTo(this.container);
    }

    async setState(isRunning: boolean, isPaused: boolean, timerEndForm: string, activeTimerFormItem?: { [rowName: string]: string }) {
        if (isRunning) {
            if (this.isUpdateInProgress) {
                return;
            }
            this.isUpdateInProgress = true;
            this.pauseLabel.hide();
            this.startTimerButton.hide();
            this.formContainer.empty();
            this.lastWorkingTimesPanel?.hide();
            if (timerEndForm) {
                const form = new Form(timerEndForm);
                form.defaultItem = activeTimerFormItem;
                form.onDataChanged = state => {
                    if (state) {
                        TimerService.setTimerState(state);
                    }
                };
                await FormDisplayer.addForm(form, this.formContainer);
                this.isUpdateInProgress = false;
            }
        } else {
            if (isPaused) {
                this.pauseLabel.show();
                this.lastWorkingTimesPanel?.hide();
            } else {
                this.pauseLabel.hide();
                this.lastWorkingTimesPanel?.show();
                this.lastWorkingTimesPanel?.refresh();
            }
            this.startTimerButton.show();
            this.formContainer.empty();
        }
    }

    setElapsedTimeText(text: string): void {
        this.timerLabel.text(text);
    }

    setPausedTimeText(text: string): void {
        this.pauseLabel.text(text);
    }
}
