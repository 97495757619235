export enum StorageItem {
    calendarView = "calendarView",
    language = "language",
    page = "page",
    tab = "tab",
    arePushNotificationsConfigured = "arePushNotificationsConfigured",
    lastHomePageTab = "lastHomePageTab",
    timesheetWidth = "timesheetWidth",
    hideTimesheetShortcuts = "hideTimesheetShortcuts"
}

export class StorageHelper {
    private static loaded = false;
    private static storageItems = {};

    static set(key: StorageItem, value: any) {
        this.ensureLoaded();
        StorageHelper.storageItems[key] = value;
        StorageHelper.save();
    }

    static get(key: StorageItem): any {
        this.ensureLoaded();
        return StorageHelper.storageItems[key];
    }

    static remove(key: StorageItem): void {
        this.ensureLoaded();
        delete StorageHelper.storageItems[key];
        StorageHelper.save();
    }

    private static save(): void { localStorage["storageItems"] = JSON.stringify(StorageHelper.storageItems); }

    private static ensureLoaded(): void {
        if (!this.loaded) {
            if (localStorage["storageItems"]) {
                StorageHelper.storageItems = JSON.parse(localStorage["storageItems"]);
            }
            this.loaded = true;
        }
    }
}
