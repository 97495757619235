import { Txt } from "../../../common/text";
import { FileUploader } from "./file-uploader";
import { MessageDisplayer } from "../../../common/message-displayer";
import { Helper } from "../../../common/helper";

export class FileValidator {
    static validateFile(file, restrictToImages: boolean, unlimitedFile: boolean = false): boolean {
        if (restrictToImages && !Helper.isImage(file.name)) {
            MessageDisplayer.showError(Txt.onlyJpg);
            return false;
        }
        if (!unlimitedFile && file.size > 25000000) {
            MessageDisplayer.showError(Txt.over25MB);
            return false;
        }
        return true;
    }

    static validateUpload(uploader: FileUploader): boolean {
        if (uploader.hasError()) {
            MessageDisplayer.showError(Txt.fileUploadFailed + ". " + Txt.pleaseTryAgain);
            return false;
        }
        if (!uploader.isFinished()) {
            MessageDisplayer.showWarning(Txt.fileUploadNotFinished);
            return false;
        }
        return true;
    }
}
