import * as $ from "jquery";
import { Calendar as FullCalendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { C } from "./component";
import { Form, FormDisplayer } from "../forms/form";
import { Helper } from "./helper";
import { Requester } from "./requester";
import { Txt, Language } from "./text";
import { MessageDisplayer } from "./message-displayer";
import { ButtonBuilder } from "./button-builder";
import { Icon } from "./icon-helper";
import { DomainHelper } from "./domain-helper";
import { StorageHelper, StorageItem } from "./storage-helper";

const CalendarView = {
    month: "dayGridMonth",
    gridWeek: "dayGridWeek",
    week: "timeGridWeek",
    day: "timeGridDay",
    resourceDay: "resourceTimelineDay",
    resourceWeek: "resourceTimelineWeek",
    resource2Month: "resourceTimeline2Month",
    resource3Month: "resourceTimeline3Month",
    resourceYear: "resourceTimelineYear"
};

export class Calendar {
    private postData;
    private calendar;
    private titleElement;

    private readonly dataUrl: string;
    private readonly resourcesUrl: string;
    private readonly supportCopy: boolean;
    private readonly isProjectBased: boolean;
    private readonly calendarWithYearlyViews: boolean;
    private readonly hasMobileEmployeeBasedCalendar: boolean;
    private readonly editUrl: string;

    constructor(dataUrl: string, resourcesUrl: string, isProjectBased: boolean,
                calendarWithYearlyViews: boolean, hasMobileEmployeeBasedCalendar: boolean, editUrl: string) {
        this.dataUrl = dataUrl;
        this.resourcesUrl = resourcesUrl;
        this.supportCopy = true;
        this.isProjectBased = isProjectBased;
        this.calendarWithYearlyViews = calendarWithYearlyViews;
        this.hasMobileEmployeeBasedCalendar = hasMobileEmployeeBasedCalendar;
        this.editUrl = editUrl;
    }

    appendTo(parent) {
        this.calendar = new FullCalendar(C.div.appendTo(parent)[0], this.getSettings());
        this.calendar.render();
    }

    refresh(postData?) {
        this.calendar.render();
        if (postData) {
            this.postData = postData;
        }
        if (this.resourcesUrl) {
            this.calendar.refetchResources();
        }
        this.calendar.refetchEvents();
    }

    addExternalHeader(parent) {
        const titleButton = C.button.attr("type", "button")
            .addClass("btn dropdown")
            .css("text-shadow", "none")
            .css("background-color", "initial")
        .appendTo(parent);
        this.titleElement = C.a.addClass("dropdown-toggle")
            .attr("data-toggle", "dropdown")
            .attr("role", "button")
            .attr("aria-haspopup", "true")
            .attr("aria-expanded", "false")
            .css("color", "black")
            .css("text-decoration", "none")
            .appendTo(titleButton);
        this.setTitle(this.calendar?.view?.title);
        const titleDropdown = C.ul.addClass("dropdown-menu").css("min-width", "0px").appendTo(titleButton);
        const addButton = (name: string, view: string) => C.a.appendTo(C.li.appendTo(titleDropdown)).text(name).click(() => this.calendar.changeView(view));
        addButton(Txt.day, CalendarView.day);
        addButton(Txt.week, CalendarView.gridWeek);
        addButton(Txt.month, CalendarView.month);
        if (this.hasMobileEmployeeBasedCalendar) {
            addButton(Txt.employeeBasedCalendar, CalendarView.resource2Month);
        }

        const navButtons = C.buttonsGroup.css("float", "right").appendTo(parent);
        navButtons.append(ButtonBuilder.get({name: "", icon: Icon.angleLeft, onClick: () => this.calendar.prev()}));
        navButtons.append(ButtonBuilder.get({name: "", icon: Icon.angleRight, onClick: () => this.calendar.next()}));

        parent.append(ButtonBuilder.get({name: Txt.today, onClick: () => this.calendar.today()})
            .css("float", "right").css("margin-right", "10px"));
    }

    private setTitle(title: string) {
        if (this.titleElement) {
            const parts = title.split(" – ");
            if (parts.length === 2) {
                title = parts[1];
            }
            this.titleElement.text(Helper.capitalize(title + " "));
            this.titleElement.append(C.span.addClass("caret"));
        }
    }

    private getSettings() {
        const language = StorageHelper.get(StorageItem.language);
        const settings: any = {
            height: "auto",
            eventDisplay: "block",
            eventTextColor: "black",
            locale: language === Language.estonian
                ? "et"
                : language === Language.russian
                    ? "ru"
                    : language === Language.latvian
                        ? "lv"
                        : language === Language.lithuanian
                            ? "lt"
                            : language === Language.finnish
                                ? "fi"
                                : "en",
            plugins: [dayGridPlugin, momentPlugin, interactionPlugin, timeGridPlugin],
            buttonText: {
                today: Txt.today,
                month: Txt.month,
                week: Txt.week,
                day: Txt.day
            },
            allDayText: Txt.allDayText,
            titleFormat: { month: "long", year: "numeric" },
            weekNumberCalculation: "ISO",
            selectable: true,
            unselectAuto: true,
            navLinks: true,
            displayEventEnd: true,
            eventTimeFormat: {
                hour: "2-digit",
                minute: "2-digit",
                meridiem: false
            },
            weekText: Txt.week,
            resourceOrder: "title"
        };
        settings.events = async (info, callback) => {
            if (this.dataUrl) {
                try {
                    const data = await Requester.post(
                        Requester.getUrl(this.dataUrl, {
                            projectBased: this.isProjectBased,
                            start: Helper.dateToString(info.start),
                            end: Helper.dateToString(info.end)
                        }),
                        this.postData);
                    callback(data);
                } catch (err) {
                    MessageDisplayer.showErrorFromServer(err);
                    callback([]);
                }
            } else {
                callback([]);
            }
        };
        settings.eventClick = async ({event}) => await this.eventClicked(event);
        settings.select = async ({start, end, resource}) => await this.openNewItemModal(start, end, resource?.id);
        settings.navLinkDayClick = async date => await this.openNewItemModal(date, date);
        settings.eventDrop = async ({event, revert, newResource, jsEvent, el}) => {
            const copy = (this.supportCopy && jsEvent.shiftKey);
            if (copy) {
                revert();
            }
            const eventId = await this.eventEdited(event, revert, newResource, copy);
            if (eventId && eventId !== event.id) {
                this.calendar.refetchEvents();
            }
            $(el).parent().removeClass("force-visible");
        };
        settings.eventResize = async ({event, revert}) => await this.eventEdited(event, revert);

        if (this.supportCopy) {
            settings.eventDragStart = info => {
                if (info.jsEvent.shiftKey) {
                    $(info.el).parent().addClass("force-visible");
                }
            };
        }

        const addTooltip = eventInfo => {
            if (eventInfo.event.extendedProps.Description) {
                C.create(eventInfo.el).attr("title", eventInfo.event.extendedProps.Description).tooltip({container: "body"});
            }
        };
        const eventDidMount = eventInfo => {
            if (eventInfo.event.display === "background") {
                return;
            }
            // Hacky solution to support HTML titles
            let html = eventInfo.event.extendedProps.Content;
            if (eventInfo.event.extendedProps.ResourceText) {
                if (html) {
                    html += "<br>";
                }
                html += "(" + eventInfo.event.extendedProps.ResourceText + ")";
            }
            eventInfo.el.querySelector(".fc-event-title").innerHTML = html;
            addTooltip(eventInfo);
        };
        
        if (this.resourcesUrl && (this.hasMobileEmployeeBasedCalendar || !Helper.isMobileScreen())) {
            // TODO: Get license
            settings.schedulerLicenseKey = "GPL-My-Project-Is-Open-Source";
            settings.plugins.push(resourceTimelinePlugin);

            settings.resourceAreaColumns = [{
                field: "title",
                headerContent: (this.isProjectBased ? Txt.project : Txt.employee)
            }];
            settings.refetchResourcesOnNavigate = true;
            settings.resources = async (info, callback) => {
                if (this.resourcesUrl) {
                    callback(await Requester.post(Requester.getUrl(this.resourcesUrl, {
                        projectBased: this.isProjectBased,
                        start: Helper.dateToString(info.start),
                        end: Helper.dateToString(info.end)
                    }), this.postData));
                } else {
                    callback([]);
                }
            };
        }
        const resourceViewBaseSettings = {
            resourceAreaWidth: "140px",
            expandRows: true,
            nowIndicator: true,
            weekNumberFormat: {week: "short"},
            eventContent: eventInfo => {
                return {html: `<div>${eventInfo.timeText}</div><div>${eventInfo.event.extendedProps.Content}</div>`};
            },
            eventDidMount: addTooltip
        };
        if (Helper.isMobileScreen()) {
            settings.headerToolbar = false;
            settings.datesSet = info => this.setTitle(info.view.title);
            settings.views = {
                [CalendarView.month]: {
                    dayHeaderFormat: "ddd",
                    weekNumberFormat: {week: "numeric"},
                    weekNumbers: true,
                    eventDidMount: eventDidMount
                },
                [CalendarView.gridWeek]: {
                    dayHeaderFormat: "ddd DD",
                    weekNumberFormat: {week: "numeric"},
                    weekNumbers: true,
                    eventDidMount: eventDidMount
                },
                [CalendarView.day]: {
                    dayHeaderFormat: "ddd DD.MM",
                    dayHeaderContent: args => {
                        return {html: args.text.replace(" ", "<br>")};
                    },
                    slotLabelFormat: "HH:mm",
                    slotDuration: "00:30:00",
                    scrollTime: "07:00:00",
                    weekNumberFormat: {week: "short"},
                    weekNumbers: true,
                    eventDidMount: eventDidMount
                }
            };
            if (this.hasMobileEmployeeBasedCalendar) {
                settings.views[CalendarView.resource2Month] = {
                    ...resourceViewBaseSettings,
                    type: "resourceTimeline",
                    duration: {months: 1},
                    slotMinWidth: 80,
                    slotLabelFormat: [
                        {week: "short"},
                        "ddd",
                        "DD"
                    ]
                }
            }
        } else {
            settings.allDaySlot = true;
            settings.headerToolbar = {
                right: "today prev,next"
            };
            settings.views = {
                [CalendarView.month]: {
                    dayHeaderFormat: "ddd",
                    weekNumberFormat: {week: "numeric"},
                    weekNumbers: true,
                    eventDidMount: eventDidMount
                }
            };
            if (this.calendarWithYearlyViews) {
                settings.initialView = CalendarView.month;
            } else {
                settings.headerToolbar.center = CalendarView.month + "," + CalendarView.week;
                settings.views[CalendarView.week] = {
                    dayHeaderFormat: "ddd DD",
                    dayHeaderContent: args => {
                        return {html: args.text.replace(" ", "<br>")};
                    },
                    slotLabelFormat: "HH:mm",
                    slotDuration: "00:20:00",
                    scrollTime: "07:00:00",
                    weekNumberFormat: {week: "short"},
                    weekNumbers: true,
                    eventDidMount: eventDidMount
                };
            }
            if (this.resourcesUrl) {
                if (this.calendarWithYearlyViews) {
                    settings.buttonText[CalendarView.resourceYear] = Txt.year;
                    settings.buttonText[CalendarView.resource3Month] = 3 + " " + Txt.month;

                    settings.initialView = CalendarView.resourceYear;
                    settings.headerToolbar.center =
                        CalendarView.month + " " + CalendarView.resourceYear + "," + CalendarView.resource3Month;

                    settings.views[CalendarView.resourceYear] = {
                        ...resourceViewBaseSettings,
                        slotDuration: {days: 4},
                        slotLabelFormat:
                            [{month: "long"},
                                "DD"]
                    };
                    settings.views[CalendarView.resource3Month] = {
                        ...resourceViewBaseSettings,
                        type: "resourceTimeline",
                        duration: {months: 3},
                        dateIncrement: {months: 1},
                        businessHours: true,
                        slotLabelFormat: [
                            {month: "long"},
                            {week: "short"},
                            "ddd",
                            "DD"
                        ]
                    };
                } else {
                    const resourceViewName = this.isProjectBased ? Txt.projectBasedCalendar : Txt.employeeBasedCalendar;
                    settings.buttonText[CalendarView.resourceDay] = Txt.day + " (" + resourceViewName + ")";
                    settings.buttonText[CalendarView.resourceWeek] = Txt.week + " (" + resourceViewName + ")";
                    settings.buttonText[CalendarView.resource2Month] = Txt.month + " (" + resourceViewName + ")";
                    settings.headerToolbar.center += ` ${CalendarView.resource2Month},${CalendarView.resourceWeek},${CalendarView.resourceDay}`;

                    settings.views[CalendarView.resourceDay] = {
                        ...resourceViewBaseSettings,
                        slotMinWidth: 60,
                        slotLabelFormat: [
                            "DD.MM ([" + Txt.week + "] w)",
                            "HH"
                        ]
                    };
                    settings.views[CalendarView.resourceWeek] = {
                        ...resourceViewBaseSettings,
                        slotMinWidth: 65,
                        slotDuration: "04:00:00",
                        slotLabelFormat: [
                            {week: "short"},
                            "ddd",
                            "DD",
                            "HH"
                        ]
                    };
                    const monthlyView =  {
                        ...resourceViewBaseSettings,
                        type: "resourceTimeline",
                        duration: {months: 2},
                        dateIncrement: {months: 1},
                        slotMinWidth: 80,
                        slotLabelFormat: [
                            {month: "long"},
                            {week: "short"},
                            "ddd",
                            "DD"
                        ]
                    };
                    settings.views[CalendarView.resource2Month] = monthlyView;

                    if (DomainHelper.getDomain() === "iws") {
                        settings.headerToolbar.center += " iws";
                        settings.buttonText["iws"] = Txt.month + " (nädalavahetuseta)";
                        settings.views["iws"] = {
                            ...monthlyView,
                            weekends: false
                        };
                    }
                }
            }
        }
        if (!this.calendarWithYearlyViews) {
            // We have to use viewClassNames because viewDidMount does not get triggered when switching between
            // views of similar type (for example between two timeline views or between two timeGrid view)
            settings.viewClassNames = arg => StorageHelper.set(StorageItem.calendarView, arg.view.type);

            const initialView = StorageHelper.get(StorageItem.calendarView) ??
                (Helper.isMobileScreen() ? CalendarView.gridWeek : CalendarView.week);
            settings.initialView = Object.keys(settings.views).includes(initialView) ? initialView : Object.keys(settings.views)[0];
        }
        return settings;
    }

    private async eventClicked(event) {
        if (Helper.hasValue(event.extendedProps.Message)) {
            MessageDisplayer.showError(event.extendedProps.Message);
        } else if (Helper.hasValue(event.extendedProps.FormUrl)) {
            await this.openModal(event.extendedProps.FormUrl);
        }
    }

    private async openNewItemModal(start, end, resourceId?) {
        if (!this.editUrl) {
            return;
        }
        await this.openModal(Requester.getUrl(this.editUrl,
            { start: Helper.dateTimeToString(start), end: Helper.dateTimeToString(end), resourceId: resourceId }));
    }

    private async openModal(url) {
        const form = new Form(url);
        form.onDataChanged = () => {
            this.calendar.refetchEvents();
        };
        await FormDisplayer.openInModal(form);
    }

    private async eventEdited(event, revert, resource?, copy: boolean = false): Promise<string> {
        // It seems that end is null if event is moved so that allDay
        // property changes. Do not allow to change allDay property.
        if (!event.end) {
            revert();
            return null;
        }
        try {
            return <string>await Requester.get(Requester.getUrl(this.editUrl, {
                id: event.id,
                start: Helper.dateTimeToString(event.start),
                end: Helper.dateTimeToString(event.end),
                resourceId: resource?.id,
                copy: copy
            }), {dataType: null});
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
            revert();
            return null;
        }
    }
}
