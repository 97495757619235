import { Requester } from "../common/requester";
import { Google } from "./google-maps";
import { C } from "../common/component";
import { Form, FormDisplayer } from "../forms/form";

// ReSharper disable InconsistentNaming
export interface MapMarker {
    Latitude: number;
    Longitude: number;
    Radius: number;
    Name?: string;
    FormUrl?: string;
    Color?: string;
}

// ReSharper restore InconsistentNaming

export class ItemsMap {
    static centerLat = 58.68;
    static centerLng = 25.1;
    static zoom = 7;

    private readonly url?: string;

    private elementsOnMap: any[] = [];
    private map;
    private infoWindow;

    constructor(url?: string) { this.url = url; }

    async refresh(postData?: any, data?: MapMarker[], centerLat?: number, centerLng?: number, zoom?: number): Promise<void> {
        if (centerLat && centerLng) {
            this.map.setCenter({
                lat: centerLat,
                lng: centerLng
            });
        }
        if (zoom) {
            this.map.setZoom(zoom);
        }
        const markers: MapMarker[] = data ? data : await Requester.post(this.url, postData);
        this.setMarkers(markers);
    }

    appendTo(parent) {
        this.map = new Google.maps.Map(parent[0], {
            disableDefaultUI: true,
            zoomControl: true,
            zoom:  ItemsMap.zoom,
            center: {
                lat: ItemsMap.centerLat,
                lng: ItemsMap.centerLng
            }
        });
    }

    private setMarkers(markersInfo: MapMarker[]) {
        for (const element of this.elementsOnMap) {
            element.setMap(null);
        }
        this.elementsOnMap = [];
        for (const markerInfo of markersInfo) {
            this.addMarker(markerInfo);
        }
    }

    private addMarker(markerInfo: MapMarker) {
        const marker = new Google.maps.Marker({
            position: {
                lat: markerInfo.Latitude,
                lng: markerInfo.Longitude
            },
            map: this.map,
            icon: markerInfo.Color ? {
                path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
                scale: 1,
                fillOpacity: 1,
                fillColor: markerInfo.Color
            } : "images/random_marker.png",
        });
        this.elementsOnMap.push(marker);

        if (markerInfo.Radius) {
            const circle = new Google.maps.Circle({
                map: this.map,
                radius: markerInfo.Radius,
                fillColor: "mediumpurple",
            });
            circle.bindTo("center", marker, "position");
            this.elementsOnMap.push(circle);
        }

        if (markerInfo.Name || markerInfo.FormUrl) {
            Google.maps.event.addListener(marker, "click", () => {
                if (this.infoWindow) {
                    this.infoWindow.close();
                }
                const content = C.p.text(markerInfo.Name);
                if (markerInfo.FormUrl) {
                    content.css("cursor", "pointer").click(() => {
                        FormDisplayer.openInModal(new Form(markerInfo.FormUrl));
                    });
                }
                this.infoWindow = new Google.maps.InfoWindow({content: content[0]});
                this.infoWindow.open(this.map, marker);
            });
        }
    }
}
