export class PageNavigationInfo {
    parentPage: string;
    page: string;
    subPage?: string;
    formUrl?: string;
    itemPage?: string;
}

export class NavigationUrlHelper {
    private static currentHash: string;

    static getCurrentPage(): PageNavigationInfo { return this.hashToInfo(window.location.hash); }

    static setCurrentPage(info: PageNavigationInfo) {
        window.location.hash = this.getPageHash(info);
        this.currentHash = window.location.hash;
    }

    static setSubPage(subPage: string) {
        const currentPage = this.getCurrentPage();
        currentPage.subPage = subPage;
        this.setCurrentPage(currentPage);
    }

    static hasPageChanged(): boolean {
        return window.location.hash && this.currentHash && window.location.hash !== this.currentHash;
    }

    private static hashToInfo(hash: string): PageNavigationInfo {
        try {
            hash = hash.split("#")[1];
            hash = decodeURIComponent(hash);
            // tslint:disable-next-line: prefer-const
            let [parentPage, page, subPage, formUrl, itemPage] = hash.split("~");
            if (formUrl === "undefined") {
                formUrl = null;
            }
            const info = new PageNavigationInfo();
            info.parentPage = parentPage;
            info.page = page;
            info.subPage = subPage;
            info.formUrl = formUrl;
            info.itemPage = itemPage;
            return info;
        } catch (err) {
            return new PageNavigationInfo();
        }
    }

    static getPageHash(info: PageNavigationInfo): string {
        try {
            return encodeURIComponent((info.parentPage || "") + "~" + (info.page || "") + "~" + (info.subPage || "") +
                "~" + (info.formUrl || "") + "~" + (info.itemPage || ""));
        } catch (err) {
            return encodeURIComponent("~~~");
        }
    }
}
