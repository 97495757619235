export enum FormRowType {
    Text = "Text",
    Note = "Note",
    Email = "Email",
    Date = "Date",
    DateTime = "DateTime",
    Time = "Time",
    DateRange = "DateRange",
    Integer = "Integer",
    Float = "Float",
    Choice = "Choice",
    Boolean = "Boolean",
    Password = "Password",
    CurrentLocation = "CurrentLocation",
    FilterChoice = "FilterChoice",
    Suggestions = "Suggestions",
    File = "File",
    Files = "Files",
    Image = "Image",
    Markdown = "Markdown",
    Disabled = "Disabled",
    ViewCell = "ViewCell",
    Signature = "Signature",
    SeatingPlan = "SeatingPlan",
    Color = "Color",
    Translations = "Translations",
    Telephone = "Telephone",
    UnlimitedFile = "UnlimitedFile"
}
