import { TabsBuilder } from "./tabs-builder";
import { Application } from "./application";
import { PageBuilder } from "./page-builder";
import { NavigationBarItem, NavigationItem } from "./navbar";
import { PageNavigationInfo, NavigationUrlHelper } from "./navigation-url-helper";
import { Form, FormDisplayer } from "../forms/form";
import * as $ from "jquery";
import { VersionChecker } from "../common/version-checker";
import { Icon } from "../common/icon-helper";
import { StorageHelper, StorageItem } from "../common/storage-helper";
import { ItemPage } from "../dashboard/item-page";
import { Displayer } from "../common/displayer";

// ReSharper disable InconsistentNaming
export enum PageType {
    Form = "Form",
    View = "View",
    Dashboard = "Dashboard",
    Timesheet = "Timesheet",
    EmployeesView = "EmployeesView",
    FormAndView = "FormAndView",
    PrincipalsGraph = "PrincipalsGraph",
    Timer = "Timer",
    Profile = "Profile",
    Report = "Report",
    Chart = "Chart",
    EditableTable = "EditableTable"
}

export interface PageInfo {
    Name: string;
    Type: PageType;
    Url: string;
    DisplayName: string;
    Icon: string;
}
// ReSharper restore InconsistentNaming

class TabsPage {
    name: string;
    displayName: string;
    pages: string[];
}

export interface Disposable {
    disable: () => void;
    enable: () => void;
    dispose: () => void;
}

export class PageNavigator {
    private static pagesByName: { [page: string]: PageInfo };
    private static tabsPages: TabsPage[] = [];
    private static defaultHomePage: string;
    private static homePageParentName: string;
    private static currentPageDisposableElements: Disposable[] = [];

    static initialize(pages: PageInfo[], navigationItems: NavigationBarItem[], homePage: NavigationItem): void {
        this.pagesByName = {};
        for (const page of pages) {
            this.pagesByName[page.Name] = page;
        }
        for (const barItem of navigationItems) {
            for (const item of barItem.Children) {
                if (item.Pages.length > 1) {
                    this.tabsPages.push(this.getTabsPage(item));
                }
            }
        }
        if (homePage.Pages.length > 1) {
            this.tabsPages.push(this.getTabsPage(homePage));
        }
        this.defaultHomePage = homePage.Pages[0];
        this.homePageParentName = homePage.Name;
    }

    static addDisposableElement(disposable: Disposable) { this.currentPageDisposableElements.push(disposable); }

    private static removeDisposableElements() {
        for (const disposable of this.currentPageDisposableElements) {
            disposable.dispose();
        }
        this.currentPageDisposableElements = [];
    }

    static hideMainElement() {
        Application.mainElement.hide();
        for (const element of this.currentPageDisposableElements) {
            element.disable();
        }
    }

    static showMainElement() {
        Application.mainElement.show();
        for (const element of this.currentPageDisposableElements) {
            element.enable();
        }
    }

    private static getTabsPage(item: NavigationItem) {
        const tabsPage = new TabsPage();
        tabsPage.name = item.Name;
        tabsPage.displayName = item.DisplayName;
        tabsPage.pages = item.Pages;
        return tabsPage;
    }

    static navigateToHome() { this.navigateToPage(this.getDefaultHomePageTab(), this.homePageParentName); }

    static navigateToPage(page: string, parentPage?: string) {
        this.navigateToPageUsingInfo(this.getPageInfo(page, parentPage));
    }

    static getPageInfo(page: string, parentPage?: string) {
        const pageInfo = new PageNavigationInfo();
        pageInfo.page = page;
        pageInfo.parentPage = parentPage;
        return pageInfo;
    }

    static async navigateToPageUsingInfo(currentPage: PageNavigationInfo) {
        if (!(currentPage.page in this.pagesByName)) {
            currentPage.page = this.getDefaultHomePageTab();
            currentPage.parentPage = this.homePageParentName;
        }
        const page = currentPage.page;
        let tabsPage: TabsPage = null;
        for (const tabs of this.tabsPages) {
            if (tabs.pages.includes(page)) {
                if (tabs.name === currentPage.parentPage) {
                    tabsPage = tabs;
                    break;
                } else if (!tabsPage) {
                    tabsPage = tabs;
                }
            }
        }

        const pagesInfo: PageInfo[] = [];
        if (tabsPage) {
            for (const tabPage of tabsPage.pages) {
                pagesInfo.push(this.pagesByName[tabPage]);
            }
            this.setTitle(tabsPage.displayName, this.pagesByName[page].DisplayName);
            currentPage.parentPage = tabsPage.name;
        } else {
            pagesInfo.push(this.pagesByName[page]);
            this.setTitle(null, pagesInfo[0].DisplayName);
        }
        this.setCurrentPage(currentPage);

        const parent = Application.mainElement;
        this.removeDisposableElements();
        parent.empty();
        parent.show();

        if (pagesInfo.length === 1) {
            await PageBuilder.build(pagesInfo[0], parent);
        } else if (currentPage && currentPage.parentPage === "Vacations") {
            // TODO: If the header name in tabs is well received by customers, used it everywhere
            const headerName = tabsPage.displayName;
            await TabsBuilder.build(pagesInfo, page, parent, headerName);
        } else {
            await TabsBuilder.build(pagesInfo, page, parent);
        }

        if (currentPage.itemPage) {
            this.hideMainElement();
            Application.secondaryElement.empty();
            PageNavigator.addDisposableElement(Displayer.secondaryDisposer);
            await new ItemPage(currentPage.itemPage, () => {
                Application.secondaryElement.empty();
                this.showMainElement();
            }).appendTo(Application.secondaryElement);
        }
        if (currentPage.formUrl) {
            const form = new Form(currentPage.formUrl);
            await FormDisplayer.openInModal(form);
        }
    }

    static async navigateToTab(page: string, parent) {
        if (page === "Dashboard") {
            $("#fc_frame").show();
        } else {
            $("#fc_frame").hide();
        }
        this.removeDisposableElements();
        const currentPage = NavigationUrlHelper.getCurrentPage();
        if (currentPage.parentPage === this.homePageParentName) {
            StorageHelper.set(StorageItem.lastHomePageTab, page);
        }
        currentPage.page = page;
        this.setCurrentPage(currentPage);
        this.setTitle(this.tabsPages.find(p => p.name === currentPage.parentPage)?.displayName, this.pagesByName[page].DisplayName);
        await PageBuilder.build(this.pagesByName[page], parent);
    }

    private static setCurrentPage(page: PageNavigationInfo) {
        NavigationUrlHelper.setCurrentPage(page);

        // This is good place to refresh page because user is moving from one page to another,
        // so no data can be lost and user is expecting something similar to refresh anyway.
        // Don't await here because in most cases no refresh will happen which means
        // it is better to perform this in different thread in background and not waste time here.
        VersionChecker.checkAndRefreshIfNeeded();
    }

    private static getDefaultHomePageTab(): string {
        const lastHomePageTab = <string>StorageHelper.get(StorageItem.lastHomePageTab);
        return lastHomePageTab in this.pagesByName ? lastHomePageTab : this.defaultHomePage;
    }

    private static setTitle(parentPage: string, page: string): void {
        if (parentPage && page) {
            document.title = `Wemply > ${parentPage} > ${page}`;
        } else if (parentPage && !page) {
            document.title = `Wemply > ${parentPage}`;
        } else if (!parentPage && page) {
            document.title = `Wemply > ${page}`;
        } else {
            document.title = `Wemply`;
        }
    }

    static getDisplayName(page: string) { return this.pagesByName[page].DisplayName; }
    static getIcon(page: string): Icon { return Icon[this.pagesByName[page].Icon]; }
}
