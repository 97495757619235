import { C } from "../common/component";
import { ModalBuilder } from "../common/modal-builder";
import { Helper } from "../common/helper";

export class Pwa {
    static deferredPrompt;

    static downloadApp(e) {
        e.preventDefault();

        if (Pwa.deferredPrompt) {
            Pwa.deferredPrompt.prompt();
            return;
        }

        const modal = ModalBuilder.createModal(Helper.isApple() ? "iPhone" : "Android");
        if (Helper.isApple()) {
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124183770/f767fe9fd858bf36d80c1e55/image+%282%29.png").attr("width","100%").appendTo(modal.body);
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124183981/230c94d7e44263be431b7c0d/image+%283%29.png").attr("width","100%").appendTo(modal.body);
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124185896/c1be228a9768251eeb857138/image+%284%29.png").attr("width","100%").appendTo(modal.body);
        } else {
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124188341/9301ccfccd186b973725ff43/image+%285%29.png").attr("width","100%").appendTo(modal.body);
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124188502/c14887571653b7dfc9d186fa/image+%286%29.png").attr("width","100%").appendTo(modal.body);
            C.img.attr("src", "https://downloads.intercomcdn.com/i/o/1124188813/1450c12d45ad69c5ebfdb30f/image+%287%29.png").attr("width","100%").appendTo(modal.body);
        }

        modal.container.modal({show: true});
    }
}
