import { C } from "../../common/component";
import { Icon, IconHelper } from "../../common/icon-helper";
import { EditControl } from "../form-row";

export class BooleanEditControl implements EditControl {
    private readonly row: JQuery;
    private readonly input: JQuery;

    constructor(isCompact: boolean, emptySpaceLeft: boolean) {
        this.row = C.div.addClass("input-group").css("display", "inline").addClass("checkbox");
        if (emptySpaceLeft) {
            this.row[0].style.marginLeft = "25px";
        }
        const checkboxContainer = C.label.css("margin-bottom", "-5px").appendTo(this.row);
        this.input = C.input.attr("type", "checkbox").appendTo(checkboxContainer);
        C.span
            .addClass("cr")
            .css("margin-left", isCompact ? "5px" : "-20px")
            .append(C.i.addClass("cr-icon " + IconHelper.getIconClass(Icon.ok)))
            .appendTo(checkboxContainer);
    }

    initialize(parent: HTMLElement) {
        parent.append(this.row[0]);
    }

    async setValue(value) {
        this.input.prop("checked", value === "True");
    }

    getValue() {
        return this.input.is(":checked") ? "True" : "False";
    }

    onChange(action: () => void) {
        this.input.on("input", action);
    }

    focus() {
        this.input.focus();
    }
}
