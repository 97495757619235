import Sortable from "sortablejs";
import { C } from "../common/component";
import { Requester } from "../common/requester";
import { Icon, IconHelper } from "../common/icon-helper";
import { ModalBuilder } from "../common/modal-builder";
import { MessageDisplayer } from "../common/message-displayer";
import { Txt } from "../common/text";
import { ButtonBuilder } from "../common/button-builder";

interface ViewSettingsPageDataUnit {
    Key: string;
    DisplayName: string;
    Tooltip: string;
}

interface ViewSettingsPageData {
    Buttons: ViewSettingsPageDataUnit[];
    DisplayedColumns: ViewSettingsPageDataUnit[];
    HiddenColumns: ViewSettingsPageDataUnit[];
}

export class ViewSettings {
    static addSettingsButton(parent, icon: Icon, tooltip: string, onClick: () => void) {
        C.span.text(" ").appendTo(parent);
        const element = C.span.addClass("icon-cell small-icon").attr("title", tooltip).appendTo(parent);
        IconHelper.addIcon(element, icon);
        element.css("cursor", "pointer");
        element.tooltip();
        element.click(onClick);
    }

    static async openSettingsModal(url: string, title: string, onChange: () => void) {
        const data: ViewSettingsPageData = await Requester.get(url);
        const modal = ModalBuilder.createModal(title);
        modal.container.modal({show: true});

        const titlesContainer = C.div.css("margin-left", "0px").addClass("row").appendTo(modal.body);
        C.h4.addClass("col-xs-6").text(Txt.displayed).appendTo(titlesContainer);
        C.h4.addClass("col-xs-6").text(Txt.hidden).appendTo(titlesContainer);

        const containerHeight = 46 * (data.DisplayedColumns.length + data.HiddenColumns.length);
        const sortableContainer = C.div.css("height", containerHeight + "px")
            .css("margin-left", "0px").addClass("row").appendTo(modal.body);
        const displayedColumnsElement = C.div.css("height", "100%")
            .addClass("displayed-columns list-group col-xs-6").appendTo(sortableContainer);
        for (const column of data.DisplayedColumns) {
            this.createDraggableElement(column).appendTo(displayedColumnsElement);
        }
        const displayedSortable = Sortable.create(displayedColumnsElement[0], {
            group: "shared",
            animation: 150
        });

        const hiddenColumnsElement = C.div.css("height", "100%")
            .addClass("hidden-columns list-group col-xs-6").appendTo(sortableContainer);
        for (const column of data.HiddenColumns) {
            this.createDraggableElement(column).appendTo(hiddenColumnsElement);
        }
        const hiddenSortable = Sortable.create(hiddenColumnsElement[0], {
            group: "shared",
            animation: 150
        });

        for (const button of data.Buttons) {
            ButtonBuilder.get({
                name: button.DisplayName,
                cssClass: "main-button",
                onClick: async () => {
                    const displayedColumns = JSON.stringify(displayedSortable.toArray({attribute: "data-id"}));
                    const hiddenColumns = JSON.stringify(hiddenSortable.toArray({attribute: "data-id"}));
                    try {
                        await Requester.send(Requester.getUrl(button.Key, {
                            displayedColumns: displayedColumns,
                            hiddenColumns: hiddenColumns
                        }));
                    } catch (err) {
                        MessageDisplayer.showErrorFromServer(err);
                        return;
                    }
                    ModalBuilder.removeModal(modal.container);
                    onChange();
                }
            }).appendTo(modal.body);
        }
    }

    private static createDraggableElement(data: ViewSettingsPageDataUnit) {
        const element = C.div.addClass("list-group-item").attr("data-id", data.Key)
            .css("margin", "2px").css("cursor", "pointer")
            .text(data.DisplayName);
        if (data.Tooltip) {
            element.attr("title", data.Tooltip).tooltip();
        }
        return element;
    }
}
