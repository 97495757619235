import { C } from "../common/component";
import { Helper } from "../common/helper";
import { Requester } from "../common/requester";
import { ViewCellBuilder } from "./view-cell-builder";
import { ViewTableHelper } from "./view-table-helper";
import { DownloadHelper } from "../common/download-helper";
import { Disposable } from "../navigation/page-navigator";
import { Loader } from "../common/loader";

export class ViewTable implements Disposable {
    public viewDataUrl;
    private parent: any;
    private viewColumnsUrl: any;
    private viewFileUrl: any;
    private serverSide: any;
    private isDisposed = false;

    public onRefresh: (() => void)[] = [];

    private dataTable = null;
    private table = C.table
        .addClass("table table-striped table-bordered table-hover")
        .attr("cellspacing", "0")
        .attr("width", "100%");
    private options: any = {
        responsive: true,
        pageLength: 50,
        fixedHeader: true
    };
    public postData;
    private inactive = false;

    private rowDataRefreshUrl: string;
    private readonly hiddenRowsCellsByRowId: {[rowId: string]: {[columnName: string]: any}} = {};
    private readonly rowDataByRowId: {[rowId: string]: any} = {};

    constructor(parent, viewColumnsUrl, viewDataUrl, viewFileUrl, serverSide) {
        this.parent = parent;
        this.viewColumnsUrl = viewColumnsUrl;
        this.viewDataUrl = viewDataUrl;
        this.viewFileUrl = viewFileUrl;
        this.serverSide = serverSide;
    }

    dispose() {
        this.dataTable?.destroy();
        this.isDisposed = true;
    }

    enable() {
        this.dataTable?.fixedHeader.enable();
    }

    disable() {
        this.dataTable?.fixedHeader.disable();
    }

    async init() {
        const loader = Loader.startLocally(this.parent);
        try {
            this.table.appendTo(this.parent);
            ViewTableHelper.addSortingMethods();

            const columnsInfo = await Requester.get(this.viewColumnsUrl);
            this.rowDataRefreshUrl = columnsInfo.RowDataRefreshUrl;
            const names = ViewTableHelper.setColumns(columnsInfo, this.options);
            this.options.language = ViewTableHelper.getLanguage();
            this.options.createdRow = (row, data) => { this.updateRow(row, data, this.options.columns); };
            this.options.dom = `t<"row"<"col-sm-4"l><"col-sm-4"i><"col-sm-4"p>>`;
            if (this.serverSide) {
                this.options.processing = true;
                this.options.serverSide = true;
                this.options.searching = false;
                this.options.ajax = async (data, callback) => {
                    const params = {
                        draw: data.draw,
                        start: data.start,
                        length: data.length
                    };
                    if (data.order && data.order.length > 0) {
                        params["sortColumn"] = data.columns[data.order[0].column].name;
                        params["sortDescending"] = data.order[0].dir === "desc";
                    }

                    const result = await Requester.post(Requester.getUrl(this.viewDataUrl, params), this.postData);
                    callback(result);
                };
                this.dataTable = this.table.DataTable({
                    initComplete: () => loader.stop(),
                    ...this.options,
                });
                this.responsiveHandler(this.table, names, this.options.columns);
            } else {
                this.options.dom = (this.viewFileUrl ? "B" : "") + "f" + this.options.dom;
                this.options.buttons = [this.getDownloadButton("Xlsx"), this.getDownloadButton("Csv")];
                const items = (await Requester.post(this.viewDataUrl, null)).data;
                this.options.data = items;
                this.dataTable = this.table.DataTable({
                    initComplete: () => loader.stop(),
                    ...this.options,
                });
                this.responsiveHandler(this.table, names, this.options.columns);
            }
        } catch (err) {
            loader.stop();
        }
    }

    private getDownloadButton(format: string) {
        return {
            text: format.toUpperCase(),
            className: "btn-primary",
            action: () => {
                const downloadLink = C.a
                    .attr("href",
                        DownloadHelper.getFileDownloadUrl(this.viewFileUrl, { format: format, inactive: this.inactive.toString() }))
                    .css("display", "none").appendTo(C.body);
                downloadLink[0].click();
                downloadLink.remove();
            }
        };
    }

    private updateRow(row, data, columns) {
        if (Helper.hasValue(data.Warning)) {
            C.create(row).attr("title", data.Warning).tooltip().addClass("danger");
        }
        if (data.RefreshInterval) {
            setTimeout(() => this.refreshRow(row, null, columns, data.RefreshId, null), data.RefreshInterval * 1000);
        }
        const cells = C.create(row).children("td");
        let j = 0;
        for (let i = 0; i < cells.length; i++) {
            const cell = C.create(cells[i]);
            while (this.dataTable !== null && !this.dataTable.columns(`${columns[j].name}:name`).visible()[0]) {
                j++;
            }
            ViewCellBuilder.createCell(cell, data[columns[j].name],
                (newData, onChangeType) => this.refreshRow(row, newData, columns, data.RefreshId, onChangeType));
            j++;
        }
    }

    private updateChildRows(row, cellsByName: {[column: string]: any}, data, columns) {
        for (const name in cellsByName) {
            ViewCellBuilder.createCell(cellsByName[name], data[name],
                (newData, onChangeType) => this.refreshRow(row, newData, columns, data.RefreshId, onChangeType));
        }
    }

    private async refreshRow(row, rowData, columns, refreshId: string, onChangeType: string) {
        if (this.isDisposed) {
            return;
        }
        if (onChangeType === "FullRefresh") {
            await this.refresh();
            return;
        }
        if (!rowData) {
            if (!refreshId) {
                return;
            }
            rowData = await Requester.get(Requester.getUrl(this.rowDataRefreshUrl, {id: refreshId}));
        }
        if (!rowData) {
            // We will refresh in case the item was deleted
            await this.refresh();
        } else {
            this.rowDataByRowId[refreshId] = rowData;
            this.updateRow(row, rowData, columns);
            this.updateChildRows(row, this.hiddenRowsCellsByRowId[rowData.RefreshId], rowData, columns);
        }
    }

    private responsiveHandler(newTable, names, columns) {
        newTable.on("responsive-display.dt", (e, datatable, row, showHide, update) => {
            if (showHide) {
                const childRow = C.create(row.node()).next();
                const titles = childRow.find(".child .dtr-title");
                const cells = childRow.find(".child .dtr-data");
                const rowData = row.data();
                const cellsByName = {};
                for (let i = 0; i < titles.length; i++) {
                    const title = C.create(titles[i]);
                    const titleText = title.text();
                    const name = names[titleText.substr(0, titleText.length - 1)];
                    cellsByName[name] = C.create(cells[i]);
                }
                this.hiddenRowsCellsByRowId[rowData.RefreshId] = cellsByName;
                this.updateChildRows(row.node(), cellsByName, this.rowDataByRowId[rowData.RefreshId] || rowData, columns);
            }
        });
    }

    public async refresh(newViewDataUrl?: string, postData?, inactive?: boolean) {
        for (const onRefresh of this.onRefresh) {
            onRefresh();
        }
        if (newViewDataUrl) {
            this.viewDataUrl = newViewDataUrl;
        }
        if (postData) {
            this.postData = postData;
        }
        this.inactive = inactive ?? false;
        if (this.serverSide) {
            this.table.DataTable().draw();
        } else {
            const newDataTable = this.table.DataTable();
            const currentPage = newDataTable.page();
            newDataTable.clear();

            const items = (await Requester.post(this.viewDataUrl, null)).data;
            newDataTable.rows.add(items);
            newDataTable.draw();
            newDataTable.page(currentPage).draw(false);
        }
    }
}
