import { C } from "./component";

export class IconHelper {
    static getIconClass(icon) {
        return `fas fa-${icon}`;
    }

    static addIcon(element, icon) {
        element.addClass(IconHelper.getIconClass(icon));
    }

    static addColorfulIcon(element, icon, color) {
        element.addClass(IconHelper.getIconClass(icon)).css("color", color);
    }

    static addIconWithText(element, icon: Icon, text: string) {
        element.text(" " + text);
        element.prepend(IconHelper.getIcon(icon));
    }

    static getIconColorful(icon, color) {
        return C.i.addClass(IconHelper.getIconClass(icon)).css("color", color);
    }

    static getIcon(icon: Icon) {
        return C.i.addClass(IconHelper.getIconClass(icon));
    }
}

export enum Icon {
    signature = "file-contract",
    ok = "check",
    alert = "exclamation-triangle",
    warning = "exclamation-circle",
    thumbsUp = "thumbs-up",
    calendar = "calendar-alt",
    pause = "pause",
    angleDown = "angle-down",
    angleRight = "angle-right",
    angleLeft = "angle-left",
    remove = "times",
    folderOpen = "folder-open",
    time = "clock",
    book = "book",
    download = "download",
    cutlery = "utensils",
    questionSign = "question-circle",
    home = "home",
    off = "power-off",
    eye = "eye",
    language = "language",
    Edit = "edit",
    X = "times",
    Moon = "moon",
    User = "user",
    Book = "book",
    Cog = "cog",
    Time = "clock",
    Home = "home",
    Calendar = "calendar-alt",
    Bell = "bell",
    Hourglass = "hourglass-half",
    PiggyBank = "money-bill-alt",
    Briefcase = "briefcase",
    CheckboxList = "list-alt",
    Grid = "th",
    Timer = "clock",
    OfflineTimer = "cloud-download-alt",
    LunchBreak = "utensils",
    Smile = "smile",
    Ban = "ban",
    HR = "users",
    Check = "check",
    Document = "file-alt",
    DocumentContract = "file-contract",
    ChartBar = "chart-bar",
    FileMedical = "file-medical",
    Toolbox = "toolbox",
    Unlock = "unlock-alt",
    Plus = "plus",
    Key = "key",
    Yes = "check-circle",
    No = "times-circle",
    Trash = "trash-alt",
    Export = "file-export",
    Error = "exclamation-circle",
    Redo = "redo",
    Coins = "coins",
    Left = "caret-left",
    Right = "caret-right",
    Info = "info",
    InfoCircle = "info-circle",
    Fingerprint = "fingerprint",
    Desktop = "desktop",
    Stopwatch = "stopwatch",
    Minimize = "compress-arrows-alt",
    Maximize = "expand-arrows-alt",
    Location = "map-marker-alt",
    Sync = "sync-alt",
    Search = "search",
    Copy = "copy",
    CreditCard = "credit-card",
    UserLock = "user-lock",
    CalendarDay = "calendar-day",
    Envelope = "envelope",
    List = "list-ul",
    Money = "money-bill-wave",
    Scissors = "scissors",
    Invoice = "file-invoice",
    Phone = "phone",
    Shuffle = "shuffle",
    Refresh = "arrows-rotate",
    Filter = "filter",
    QrCode = "qrcode",
    Link = "link",
    Load = "spinner",
    Sign = "file-signature",
    Play = "play",
    AddressBook = "address-book"
}
