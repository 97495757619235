import { Helper } from "../common/helper";
import { Requester } from "../common/requester";
import { Txt } from "../common/text";
import { TimesheetShortcutsSet } from "./timesheet-shortcuts-set";
import { ConfirmationDialogHelper } from "../common/confirmation-dialog-helper";
import {
    TimesheetCellButton,
    TimesheetCellData, TimesheetChangedDates,
    TimesheetRightTableColumn,
    TimesheetRightTableRowData,
    TimesheetSaveData,
    TimesheetSaveRequest,
    TimesheetUpdateData,
    TimesheetUpdateRequest
} from "./timesheet-models";
import { TableHelper } from "../common/table-helper";
import { TimesheetSelectedCells } from "./timesheet-right-table-configurer";
import { TimesheetCommentsManager } from "./timesheet-comments-manager";
import { Icon, IconHelper } from "../common/icon-helper";
import { MessageDisplayer } from "../common/message-displayer";

export class TimesheetTableData {
    readonly saveUrl: string;
    readonly dateDataUrl: string;
    private readonly rows: TimesheetRightTableRowData[] = [];

    constructor(saveUrl: string, dateDataUrl: string) {
        this.saveUrl = saveUrl;
        this.dateDataUrl = dateDataUrl;
    }

    getRow(rowNumber: number): TimesheetRightTableRowData {
        return this.rows[rowNumber];
    }

    addRow(row: TimesheetRightTableRowData): void {
        this.rows.push(row);
    }

    getAllRows(): TimesheetRightTableRowData[] {
        return this.rows;
    }

    getCell(rowNumber: number, columnNumber: number): TimesheetCellData {
        return this.getRow(rowNumber).Cells[columnNumber];
    }
}

export class TimesheetChange {
    readonly rowNumber: number;
    readonly columnNumber: number;
    readonly oldValue: string;
    readonly newValue: string;

    constructor(rowNumber: number, columnNumber: number, oldValue: string, newValue: string) {
        this.rowNumber = rowNumber;
        this.columnNumber = columnNumber;
        this.oldValue = oldValue;
        this.newValue = newValue;
    }
}

export class TimesheetRightTableDataManager {
    private readonly columns: TimesheetRightTableColumn[];
    private readonly rows: string[];
    private readonly data: TimesheetTableData;
    private readonly shortcuts: TimesheetShortcutsSet;
    private readonly onChange: (updateData: TimesheetUpdateData) => void;

    constructor(columns: TimesheetRightTableColumn[], rows: string[], shortcuts: TimesheetShortcutsSet, data: TimesheetTableData,
                onChange: (updateData: TimesheetUpdateData) => void) {
        this.columns = columns;
        this.rows = rows;
        this.shortcuts = shortcuts;
        this.data = data;
        this.onChange = onChange;
    }

    getData() {
        return this.data;
    }

    save(changes: TimesheetChange[], restoreData: (change: TimesheetChange) => void) {
        const data: TimesheetChange[] = [];
        const saveData: TimesheetSaveData[] = [];
        const changedEmployees = new Set();
        let smallestColumnId = this.columns.length;
        let largestColumnId = 0;
        let showWarning = false;
        for (const change of changes) {
            if (change.newValue === change.oldValue && this.data.getCell(change.rowNumber, change.columnNumber).ValueMatchesAllData) {
                continue;
            }
            if (change.oldValue && change.oldValue.startsWith(change.newValue + "^*")) {
                restoreData(change);
                continue;
            }
            const saveInfo = this.tryParseAndSetErrors(change);
            if (!saveInfo) {
                restoreData(change);
                continue;
            }
            data.push(change);
            saveData.push(saveInfo);
            changedEmployees.add(this.data.getRow(change.rowNumber).EmployeeId);
            if (change.columnNumber < smallestColumnId) {
                smallestColumnId = change.columnNumber;
            }
            if (change.columnNumber > largestColumnId) {
                largestColumnId = change.columnNumber;
            }
            if (!showWarning && this.data.getCell(change.rowNumber, change.columnNumber).ShowWarningOnEdit) {
                showWarning = true;
            }
        }

        if (!data.length) {
            return;
        }

        const rowsToUpdate: string[] = [];
        for (let i = 0; i < this.rows.length; i++) {
            if (changedEmployees.has(this.data.getRow(i).EmployeeId)) {
                rowsToUpdate.push(this.rows[i]);
            }
        }
        const saveRequest: TimesheetSaveRequest = {
            saveInfo: saveData,
            updateInfo: {
                rows: rowsToUpdate,
                startDate: this.columns[smallestColumnId].Date,
                endDate: this.columns[largestColumnId].Date
            }
        };
        if (showWarning) {
            ConfirmationDialogHelper.askConfirmation(Txt.timesheetEditWarning,
                () => this.doSaveRequest(saveRequest),
                () => {
                    for (const change of data) {
                        this.data.getCell(change.rowNumber, change.columnNumber).Error = null;
                        restoreData(change);
                    }
                });
        } else {
            this.doSaveRequest(saveRequest);
        }
    }

    private async doSaveRequest(saveRequest: TimesheetSaveRequest) {
        let updateData: TimesheetUpdateData;
        try {
            updateData = await Requester.post(this.data.saveUrl, saveRequest);
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
        }
        this.onChange(updateData);
    }

    async reloadCellData(selectedCells: TimesheetSelectedCells, changedDates: TimesheetChangedDates, id?: string): Promise<void> {
        const changedEmployees = new Set();
        for (let rowNumber = selectedCells.startRow; rowNumber <= selectedCells.endRow; rowNumber++) {
            changedEmployees.add(this.data.getRow(rowNumber).EmployeeId);
        }
        const rowsToUpdate: string[] = [];
        for (let i = 0; i < this.rows.length; i++) {
            if (changedEmployees.has(this.data.getRow(i).EmployeeId)) {
                rowsToUpdate.push(this.rows[i]);
            }
        }

        let startColumn = selectedCells.startColumn;
        if (changedDates?.EarliestChange) {
            const dif = Helper.getDifferenceInDays(this.columns[startColumn].Date, changedDates.EarliestChange);
            if (dif > 0) {
                startColumn -= dif;
                if (startColumn < 0) {
                    startColumn = 0;
                }
            }
        }
        let endColumn = selectedCells.endColumn;
        if (changedDates?.LatestChange) {
            const dif = Helper.getDifferenceInDays(changedDates.LatestChange, this.columns[endColumn].Date);
            if (dif > 0) {
                endColumn += dif;
                if (endColumn >= this.columns.length) {
                    endColumn = this.columns.length - 1;
                }
            }
        }
        const updateRequest: TimesheetUpdateRequest = {
            rows: rowsToUpdate,
            startDate: this.columns[startColumn].Date,
            endDate: this.columns[endColumn].Date,
            editedItemId: id
        };
        const updateData = <TimesheetUpdateData>await Requester.post(this.data.dateDataUrl, updateRequest);
        this.onChange(updateData);
    }

    private tryParseAndSetErrors(change: TimesheetChange): TimesheetSaveData {
        let value = change.newValue ?? "";
        let extraSaveInfo = null;
        if (value.indexOf("^*") !== -1) {
            extraSaveInfo = value.substring(value.lastIndexOf("^*") + 2);
            value = value.substring(0, value.lastIndexOf("^*"));
        }
        let shortcut: string = null;
        let hours: number = null;
        let startTime: string = null;
        let endTime: string = null;
        const row = this.data.getRow(change.rowNumber);
        const shortcutItem = this.shortcuts.tryGetByAbbreviation(value);
        if (shortcutItem) {
            if (!row.AllowedShortcutTypes.includes(shortcutItem.Type)) {
                row.Cells[change.columnNumber].Error = Txt.cannotInsertThisShortcutToThisRowInTimesheet;
                return null;
            }
            shortcut = shortcutItem.Id;
        } else if (Helper.isFloat(value) && Helper.parseFloat(value) <= 24) {
            if (!row.AllowHours) {
                row.Cells[change.columnNumber].Error = Txt.cannotInsertWorkToThisRowInTimesheet;
                return null;
            }
            hours = Helper.parseFloat(value);
        } else if (value.indexOf("-") !== -1) {
            const parts = value.split("-");
            const tryParseTimeNumber = (timeToParse: string, parseMinutes: boolean = false): string => {
                if (timeToParse.length === 1 && !parseMinutes || timeToParse.length === 2) {
                    const parsedNumber = parseInt(timeToParse, 10);
                    if (parsedNumber >= (parseMinutes ? 60 : 24) || parsedNumber < 0) {
                        return null;
                    }
                    return parsedNumber < 10 ? "0" + parsedNumber.toString() : parsedNumber.toString();
                }
                return null;
            };
            const tryParseTime = (timeToParse: string): string => {
                if (timeToParse.length === 1 || timeToParse.length === 2) {
                    const parsed = tryParseTimeNumber(timeToParse);
                    return parsed === null ? null : parsed + ":00";
                }
                const timeParts = timeToParse.split(":");
                if (timeParts.length === 2) {
                    const hoursPart = tryParseTimeNumber(timeParts[0]);
                    const minutesPart = tryParseTimeNumber(timeParts[1], true);
                    return hoursPart === null || minutesPart === null ? null : hoursPart + ":" + minutesPart;
                }
                return null;
            };
            if (parts.length === 2) {
                startTime = tryParseTime(parts[0]);
                endTime = tryParseTime(parts[1]);
                if (startTime === null || endTime === null) {
                    row.Cells[change.columnNumber].Error = Txt.invalidValueInsertedInTimesheet;
                    return null;
                }
            }
        } else {
            if (value !== "") {
                row.Cells[change.columnNumber].Error = Txt.invalidValueInsertedInTimesheet;
                return null;
            }
        }
        return {
            date: this.columns[change.columnNumber].Date,
            row: this.rows[change.rowNumber],
            shortcut: shortcut,
            hours: hours,
            startTime: startTime,
            endTime: endTime,
            extraSaveInfo: extraSaveInfo
        };
    }

    getColumnHeader(columnNumber): string {
        const column = this.columns[columnNumber];
        return TableHelper.getDateCellHtml(column.Date, column.Color);
    }

    getRowHeader(rowNumber: number, commentsManager: TimesheetCommentsManager): string {
        const rowData = this.data.getRow(rowNumber);
        const comment = commentsManager.getComment(rowNumber, null);
        return comment ? `${rowData.ShortTitle} (${comment})` : rowData.ShortTitle;
    }

    getDisplayableData(): string[][] {
        const result: string[][] = [];
        for (const row of this.data.getAllRows()) {
            const rowData: string[] = [];
            for (const cell of row.Cells) {
                rowData.push(cell.Value);
            }
            result.push(rowData);
        }
        return result;
    }

    setCellData(rowNumber: number, columnNumber: number, value: TimesheetCellData) {
        this.data.getRow(rowNumber).Cells[columnNumber] = value;
    }

    formatCell(rowNumber, columnNumber, cell, value) {
        const row = this.data.getRow(rowNumber);
        if (row.HideBottomBorder) {
            cell.css("border-bottom", "0");
        }
        const cellData = this.data.getCell(rowNumber, columnNumber);
        if (value === cellData.Value) {
            let html = cellData.DisplayValue;
            if (!cellData.IsEditable) {
                html = "<i>" + html + "</i>";
            }
            if (cellData.Error) {
                cell.css("position", "relative");
                html += "<i style=\"color:red; top:0; right:0; position:absolute\" class=\"" + IconHelper.getIconClass(Icon.Error) + "\" /i>";
            }
            cell.html(html);
            cell.css("white-space", "nowrap");
            Helper.setColors(cell, cellData.Colors ?? [row.DefaultColor]);
            if (cellData.BorderColor) {
                cell.css("border", `2px dashed ${cellData.BorderColor}`);
            }
        } else if (value && value.indexOf("^*") !== -1) {
            cell.text(value.substring(0, value.lastIndexOf("^*")));
        }
        cell.tooltip({
            title: () => {
                const cellInfo = this.data.getCell(rowNumber, columnNumber);
                return cellInfo.Error ?? cellInfo.Tooltip;
            },
            container: "body",
            placement: "top"
        });
    }

    isCellEditable(rowNumber: number, columnNumber: number): boolean {
        return this.data.getCell(rowNumber, columnNumber).IsEditable;
    }

    getButtons(rowNumber: number, columnNumber: number): TimesheetCellButton[] {
        return this.data.getCell(rowNumber, columnNumber).Buttons;
    }

    getShortcuts(): TimesheetShortcutsSet {
        return this.shortcuts;
    }
}
