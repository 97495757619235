import * as $ from "jquery";
import { Requester } from "../../../common/requester";
import { Urls } from "../../../common/urls";

export class FileUploader {
    private readonly file;
    private isCancelled = false;
    private isFinishedBool = false;
    private hasErrorBool = false;
    private percentDone = 0;
    private reference: string;
    private jqxhr;

    constructor(file) { this.file = file; }

    cancel() {
        this.isCancelled = true;
        if (this.jqxhr) {
            this.jqxhr.abort();
        }
    }

    isFinished(): boolean { return this.isFinishedBool; }

    getReference(): string { return this.reference; }

    hasError(): boolean { return this.hasErrorBool; }

    getPercent(): number { return this.percentDone; }

    async upload(onProgress: (percent: number) => void, onError: () => void, onSuccess: () => void) {
        const urlData = await Requester.get(Requester.getUrl(Urls.uploadUrl, {fileName: this.file.name}));
        this.reference = urlData.Reference;
        this.jqxhr = $.ajax({
            url: urlData.Url,
            headers: {"x-amz-storage-class": urlData.StorageClass},
            type: "PUT",
            contentType: "binary/octet-stream",
            data: this.file,
            cache: false,
            processData: false,
            xhr: () => {
                const xhr = <any>new XMLHttpRequest();
                xhr.upload.addEventListener("progress",
                    (evt) => {
                        if (evt.lengthComputable && !this.isCancelled) {
                            this.percentDone = Math.round((evt.loaded / evt.total) * 100);
                            onProgress(this.percentDone);
                        }
                    },
                    false);
                return xhr;
            }
        }).done(() => {
            this.isFinishedBool = true;
            if (!this.isCancelled) {
                onSuccess();
            }
        }).fail((jqXhr, textStatus, errorThrown) => {
            if (errorThrown !== "abort" && !this.isCancelled) {
                this.hasErrorBool = true;
                onError();
            }
        });
    }
}
