import * as Handsontable from "handsontable";
import "jquery-ui/ui/widgets/droppable";
import { C } from "../common/component";
import { Helper } from "../common/helper";
import { TimesheetRightTableConfigurer, TimesheetSelectedCells } from "./timesheet-right-table-configurer";
import { TimesheetRightTableDataManager, TimesheetChange } from "./timesheet-right-table-data-manager";
import { TableHelper } from "../common/table-helper";
import { TimesheetCommentsManager } from "./timesheet-comments-manager";
import { TimesheetCellButton, TimesheetChangedDates, TimesheetRightTableColumn } from "./timesheet-models";
import { Form, FormDisplayer } from "../forms/form";
import { Requester } from "../common/requester";
import { Loader } from "../common/loader";
import { MessageDisplayer } from "../common/message-displayer";
import { EditableTableCalculator } from "../common/editable-table";
import { DomainHelper } from "../common/domain-helper";
import { GridSettings } from "handsontable";

class CustomEditor extends Handsontable.editors.TextEditor {
    prepare(row: number, col: number, prop: string | number, td: HTMLElement, originalValue: any, cellProperties: GridSettings): void {
        if (originalValue && originalValue.indexOf("^*") !== -1) {
            originalValue = originalValue.substring(0, originalValue.lastIndexOf("^*"));
        }
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }
}

export class TimesheetRightTable {
    private readonly doNotTriggerSave = "doNotTriggerSave";

    private readonly columns: TimesheetRightTableColumn[];
    private readonly rowHeight: number;
    private readonly rowHeaderWidth: number;
    private readonly width: number;
    private readonly multiCellButtons: TimesheetCellButton[];
    private readonly isCompact: boolean;

    private headerHeight: number;
    private rightTable: any;
    private hot: Handsontable;
    private additionalHeader: any;

    constructor(columns: TimesheetRightTableColumn[], rowHeight: number, rowHeaderWidth: number, width: number,
                multiCellButtons: TimesheetCellButton[], isCompact: boolean) {
        this.columns = columns;
        this.rowHeight = rowHeight;
        this.rowHeaderWidth = rowHeaderWidth;
        this.width = width;
        this.multiCellButtons = multiCellButtons;
        this.isCompact = isCompact;
    }

    appendTo(parent, dataManager: TimesheetRightTableDataManager, commentsManager: TimesheetCommentsManager,
             calculator: EditableTableCalculator) {
        const rightColumn = C.div.addClass(`col-xs-${12 - this.width} schedule-col`)
            .css("height", C.leftTable.height())
            .css("overflow", "hidden")
            .appendTo(parent);
        this.rightTable = C.table.addClass("table table-bordered table-sm schedule-table")
            .appendTo(rightColumn);

        const startDate = Helper.stringToDate(this.columns[0].Date);
        const endDate = Helper.stringToDate(this.columns[this.columns.length - 1].Date);
        const headerRow = TableHelper.createMonthsHeaderRowFromDates(startDate, endDate);
        const data = dataManager.getDisplayableData();

        const freeDays: any[] = [];
        let freeDaysBorderColor = null;
        for (let i = 0; i < this.columns.length; i++) {
            if (this.columns[i].BorderColor) {
                freeDays.push(i);
                freeDaysBorderColor = this.columns[i].BorderColor;
            }
        }
        this.additionalHeader = null;
        if (this.columns[0].AdditionalCell) {
            this.additionalHeader = C.tr.css("height", "24px");
            C.th.appendTo(this.additionalHeader);
            for (const column of this.columns) {
                calculator.addCell(C.div.appendTo(C.th.appendTo(this.additionalHeader)), column.AdditionalCell);
            }
        }

        const handsontableConfig: any = {
            editor: CustomEditor,
            autoWrapRow: true,
            beforeRender: () => C.hotTableHeader.empty(),
            afterRender: () => {
                const headers = C.hotTableHeader;
                headerRow.prependTo(headers);
                for (let i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].Tooltip) {
                        for (const header of <any>headers) {
                            const headerObject = C.create(header).children()[1];
                            const children = C.create(headerObject).children();
                            C.create(C.create(children[i + 1]).children()[0]).tooltip({
                                title: this.columns[i].Tooltip
                            });
                        }
                    }
                }
                if (this.additionalHeader) {
                    this.additionalHeader.appendTo(headers);
                    if (DomainHelper.getDomain() === "yolo") {
                        for (const header of <any>headers) {
                            const headerObject = C.create(header).children()[2];
                            const children = C.create(headerObject).children();
                            for (let i = 0; i < this.columns.length; i++) {
                                C.create(C.create(children[i + 1]).children()[0]).tooltip({
                                    title: () => {
                                        const shifts = {};
                                        for (const row of dataManager.getData().getAllRows()) {
                                            const cell = row.Cells[i];
                                            const shortcut = dataManager.getShortcuts().tryGetByAbbreviation(cell.Value);
                                            if (shortcut && shortcut.Type !== "VacationType") {
                                                if (!shifts[cell.Value]) {
                                                    shifts[cell.Value] = 0;
                                                }
                                                shifts[cell.Value] += 1;
                                            }
                                        }
                                        const array = Object.keys(shifts).map(key => [key, shifts[key]]);
                                        array.sort((first, second) => second[1] - first[1]);
                                        return array.map(x => x[0] + " " + x[1]).join(", ");
                                    }
                                });
                            }
                        }
                    }
                }
            },
            currentHeaderClassName: "",
            viewportColumnRenderingOffset: 1000,
            rowHeights: this.rowHeight,
            maxRows: data.length,
            maxCols: this.columns.length,
            colHeaders: (columnNumber) => dataManager.getColumnHeader(columnNumber),
            rowHeaders: (rowNumber) => dataManager.getRowHeader(rowNumber, commentsManager),
            rowHeaderWidth: this.rowHeaderWidth,
            data: data,
            cells: (rowNumber, columnNumber) => ({readOnly: !dataManager.isCellEditable(rowNumber, columnNumber)}),
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
                Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                const cell = C.create(td);
                if (freeDays.includes(col)) {
                    cell.css("border-bottom", "1px solid " + freeDaysBorderColor);
                    if (!freeDays.includes(col - 1)) {
                        cell.css("border-left", "2px solid " + freeDaysBorderColor);
                    }
                    if (!freeDays.includes(col + 1)) {
                        cell.css("border-right", "2px solid " + freeDaysBorderColor);
                    }
                }
                dataManager.formatCell(row, col, cell, value);
                commentsManager.configureComment(cell, row, col);
                return td;
            },
            afterChange: (changes, source) => {
                if (source !== "loadData" && source !== this.doNotTriggerSave) {
                    const convertedChanges: TimesheetChange[] = [];
                    for (const change of changes) {
                        // Workaround for handsontable bug https://github.com/handsontable/handsontable/issues/5477
                        if (change[3] && change[3].endsWith("\r")) {
                            change[3] = change[3].substring(0, change[3].length - 1);
                        }
                        convertedChanges.push(new TimesheetChange(change[0], change[1], change[2], change[3]));
                    }
                    dataManager.save(convertedChanges, change =>
                        this.hot.setDataAtCell(change.rowNumber, change.columnNumber, change.oldValue, this.doNotTriggerSave));
                }
            },
            contextMenu: {items: {}}
        };
        if (this.isCompact) {
            handsontableConfig.colWidths = 40;
        }
        TimesheetRightTableConfigurer.configureCopyAndPasteButtons(handsontableConfig);
        TimesheetRightTableConfigurer.configureComments(handsontableConfig, commentsManager, dataManager);

        this.addCustomButtons(handsontableConfig, dataManager);
        TimesheetRightTableConfigurer.configureShortcuts(handsontableConfig, dataManager.getShortcuts());

        this.hot = new Handsontable(this.rightTable[0], <any>handsontableConfig);

        this.setFixedHeader();

        this.rightTable.droppable({
            accept: ".ui-draggable",
            drop: (event, ui) => {
                ui.helper.hide();
                const destination = C.create(<HTMLElement>document.elementFromPoint(event.clientX, event.clientY));
                const tr = destination.closest("tr");
                const tbody = tr.closest("tbody");
                const col = tr.children().index(destination) - 1;
                const row = tbody.children().index(tr);
                if (col >= 0 && row >= 0) {
                    this.hot.setDataAtCell(row, col, ui.draggable.text());
                }
            },
        });
    }

    setHeaderHeight(height: number) {
        if (this.additionalHeader) {
            height -= 24;
        }
        if (this.headerHeight !== height) {
            (<any>this.hot).updateSettings({ columnHeaderHeight: height });
            this.headerHeight = height;
        }
    }

    private addCustomButtons(handsontableConfig, dataManager: TimesheetRightTableDataManager) {
        let maxButtonsCount = this.multiCellButtons.length;
        for (let rowNr = 0; rowNr < handsontableConfig.maxRows; rowNr++) {
            for (let columnNr = 0; columnNr < handsontableConfig.maxCols; columnNr++) {
                if (dataManager.getButtons(rowNr, columnNr).length > maxButtonsCount) {
                    maxButtonsCount = dataManager.getButtons(rowNr, columnNr).length;
                }
            }
        }

        const multiCellButtons = this.multiCellButtons;
        function getButton(selectedCells: TimesheetSelectedCells, buttonNumber: number): TimesheetCellButton {
            const buttons = selectedCells.isSingleCell()
                ? dataManager.getButtons(selectedCells.startRow, selectedCells.startColumn)
                : multiCellButtons;
            if (buttonNumber < buttons.length) {
                return buttons[buttonNumber];
            }
            return {Name: null, Url: null, GroupButtonId: null, IsDisabled: true, OpenForm: false};
        }

        // We use maxButtonsCount + 2 here to ensure that in case more buttons are added when timesheet is edited
        // then we still have slots available to show them
        for (let buttonNumber = 0; buttonNumber < maxButtonsCount + 2; buttonNumber++) {
            TimesheetRightTableConfigurer.addCustomButton(handsontableConfig, `customButton${buttonNumber}`,
                (selectedCells: TimesheetSelectedCells) => getButton(selectedCells, buttonNumber).Name,
                async (selectedCells: TimesheetSelectedCells) => {
                    const button = getButton(selectedCells, buttonNumber);
                    if (!button.Url) {
                        return;
                    }
                    if (button.OpenForm) {
                        const form = new Form(button.Url);
                        form.onDataChanged = id => dataManager.reloadCellData(selectedCells, button.ItemPeriod, id);
                        FormDisplayer.openInModal(form);
                    } else {
                        Loader.startGlobally();
                        const ids: string[] = [];
                        if (selectedCells.isSingleCell() && button.GroupButtonId) {
                            ids.push(button.GroupButtonId);
                        } else {
                            for (let rowNumber = selectedCells.startRow; rowNumber <= selectedCells.endRow; rowNumber++) {
                                for (let columnNumber = selectedCells.startColumn;
                                     columnNumber <= selectedCells.endColumn;
                                     columnNumber++) {
                                    for (let i = 0; i < maxButtonsCount; i++) {
                                        const cells = new TimesheetSelectedCells();
                                        cells.startRow = cells.endRow = rowNumber;
                                        cells.startColumn = cells.endColumn = columnNumber;
                                        const x = getButton(cells, i);
                                        if (button.Url === x.Url && x.GroupButtonId) {
                                            ids.push(x.GroupButtonId);
                                        }
                                    }
                                }
                            }
                        }
                        let changedDates: TimesheetChangedDates;
                        try {
                            changedDates = await Requester.post(button.Url, ids);
                        } catch (error) {
                            MessageDisplayer.showErrorFromServer(error);
                        }
                        await dataManager.reloadCellData(selectedCells, changedDates);
                        Loader.stopGlobally();
                    }
                },
                (selectedCells: TimesheetSelectedCells) => getButton(selectedCells, buttonNumber).IsDisabled,
                (selectedCells: TimesheetSelectedCells) => !getButton(selectedCells, buttonNumber).Url);
        }
    }

    setDataAtCellsWithoutSave(changes: any[][]) {
        this.hot.setDataAtCell(<any>changes, this.doNotTriggerSave);
    }

    render() {
        this.hot.render();
    }

    setFixedHeader() {
        const header = C.hotClone;
        const rightTable = C.rightTable;
        const windowQuery = C.window;

        windowQuery.on("scroll", () => {
            if (windowQuery.scrollTop() >= rightTable.offset().top) {
                header.css("position", "fixed")
                    .css("right", "14px")
                    .css("left", "auto");
            } else {
                header.css("position", "relative");
            }
        });
    }
}
