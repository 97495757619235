import { FormRowType } from "../form-row-type";
import { EditControl } from "../form-row";
import { Dom } from "../../common/dom";

export class InputControl implements EditControl {
    private readonly type: string;
    private readonly input: HTMLInputElement;
    private readonly inputRow: HTMLElement;

    constructor(type: FormRowType, unit: string, minValue: number, maxValue: number, triggerMainButtonOnEnter: boolean,
                triggerMainButton: () => void) {
        this.type = type;
        this.inputRow = Dom.createDiv();
        this.input = Dom.add("input", this.inputRow, "form-control", "w-input");

        if (unit) {
            this.inputRow.className = "input-group";
            const rightSpan = Dom.addSpan(this.inputRow, "input-group-addon");
            rightSpan.textContent = unit;

            if (unit === "%") {
                this.input.setAttribute("min", "0");
                this.input.setAttribute("max", "100");
            }
        }

        if (type === FormRowType.Integer || type === FormRowType.Float) {
            this.input.setAttribute("type", "number");
            if (minValue !== null) {
                this.input.setAttribute("min", minValue.toString());
            }
            if (maxValue !== null) {
                this.input.setAttribute("max", maxValue.toString());
            }
            if (type === FormRowType.Float) {
                this.input.setAttribute("step", "any");
            }
        } else if (type === FormRowType.Email) {
            this.input.setAttribute("type", "email");
        } else if (type === FormRowType.Telephone) {
            this.input.setAttribute("type", "tel");
            this.input.setAttribute("pattern", "\\+[0-9]{8,}");
            this.input.setAttribute("placeholder", "+372XXXXXXXX");
        } else {
            this.input.setAttribute("type", "text");
        }

        if (type === FormRowType.Disabled) {
            this.input.setAttribute("disabled", "true");
        }
        if (type === FormRowType.Text && triggerMainButtonOnEnter && triggerMainButton) {
            this.input.addEventListener("keyup", e => {
                if (e.key === "Enter") {
                    triggerMainButton();
                }
            });
        }
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow);
    }

    async setValue(value: string) {
        this.input.value = value;
    }

    getValue() {
        const value = this.input.value;
        if (value === "" || value === null) {
            return null;
        } else {
            if (this.type === FormRowType.Integer) {
                if (value.startsWith(".") || value.startsWith(",")) {
                    return "0";
                }
                return parseInt(value).toString();
            }
            return value;
        }
    }

    onChange(action: () => void) {
        this.input.addEventListener("change", action);
    }

    focus(): void { this.input.focus(); }

    onBlur(action: () => void) {
        this.input.addEventListener("blur", action);
    }

    validate(): boolean {
        return this.input.checkValidity();
    }
}
