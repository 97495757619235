import * as $ from "jquery";
import { Helper } from "./helper";

export class Requester {
    static get(url, ajaxConfig?): Promise<any> {
        return Requester.getWithoutAnything(Requester.getUrl(url), ajaxConfig);
    }

    // GET request, which is expected to respond without data
    static send(url): Promise<any> { return Requester.getWithoutAnything(Requester.getUrl(url), { dataType: null }); }

    static post(url, data, ajaxConfig?): Promise<any> {
        return Requester.postWithoutAnything(Requester.getUrl(url), data, ajaxConfig);
    }

    static getUrl(path: string, parameters?): string {
        if (!Helper.hasValue(parameters)) {
            parameters = {};
        }

        const parts = path.split("?");
        let url = `${parts[0]}?${Requester.combineQueryString(parts[1], parameters)}`;
        if (url.lastIndexOf("api/", 0) !== 0) {
            url = `api/${url}`;
        }
        return url;
    }

    private static getWithoutAnything(url: string, ajaxConfig?): Promise<any> {
        return new Promise((resolve, reject) => {
            const config = {
                type: "GET",
                url: url,
                dataType: "json",
                success: resolve,
                error: (error) =>
                    reject({
                        readyState: error.readyState,
                        status: error.status,
                        responseText: error.responseText,
                        url: url
                    }),
            };
            if (Helper.hasValue(ajaxConfig)) {
                $.each(ajaxConfig, function(k, v) { config[k] = v; });
            }
            $.ajax(config);
        });
    }

    private static postWithoutAnything(url: string, data, ajaxConfig?): Promise<any> {
        return new Promise((resolve, reject) => {
            const config = {
                type: "POST",
                url: url,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data),
                success: resolve,
                error: (error) =>
                    reject({
                        readyState: error.readyState,
                        status: error.status,
                        responseText: error.responseText,
                        url: url
                    }),
            };
            if (Helper.hasValue(ajaxConfig)) {
                $.each(ajaxConfig, function(k, v) { config[k] = v; });
            }
            $.ajax(config);
        });
    }

    private static combineQueryString(original: string, newParams): string {
        if (Helper.hasValue(original)) {
            const pairs = original.split("&");
            for (let i = 0; i < pairs.length; i++) {
                const pair = pairs[i].split("=");
                if (!Helper.hasValue(newParams[pair[0]])) {
                    newParams[pair[0]] = decodeURIComponent(pair[1].replace(/\+/g, " "));
                }
            }
        }
        return $.param(newParams);
    }
}
