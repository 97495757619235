import { C } from "./component";
import { Txt } from "./text";

export class ImagePreview {
    constructor(element, image) {
        element.popover({
            trigger: "manual",
            html: true,
            title: Txt.imagePreview,
            content: "Pole pilti kuvada...",
            placement: "bottom"
        });

        element.hover(
            () => {
                if (!element.attr("data-content")) {
                    const preview = C.img.addClass("preview-image").attr("src", image);
                    element.attr("data-content", preview[0].outerHTML);
                }
                element.popover("show");
            },
            () => {
                element.popover("hide");
            }
        );
    }
}
