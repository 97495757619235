import { C } from "../common/component";
import { FormRowType } from "./form-row-type";
import { Helper } from "../common/helper";
import { LocationProvider } from "../timer/location-provider";
import { Requester } from "../common/requester";
import { ConfirmationDialogHelper } from "../common/confirmation-dialog-helper";
import { MessageDisplayer } from "../common/message-displayer";
import { FormRow } from "./form-row";
import { EditControlBuilder } from "./edit-control-builder";
import { DownloadHelper } from "../common/download-helper";
import {
    Form,
    FormButtonBehavior,
    FormButtonModel,
    FormDisplayer,
    FormModel,
    FormActionResult, FormRowGroupModel
} from "./form";
import { Loader } from "../common/loader";
import { Icon } from "../common/icon-helper";
import { ViewSettings } from "../views/view-settings";
import { ButtonBuilder } from "../common/button-builder";
import { DialogTable } from "../common/dialog-table";
import { PageNavigator } from "../navigation/page-navigator";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";

export class FormComponent {
    private readonly conf: Form;
    private readonly closeModal: () => void;

    private form: JQuery<HTMLElement> = C.form;

    private item: { [rowName: string]: string };
    private currentClick;
    private rows: { [name: string]: FormRow } = {};
    private currentLocationFields = [];
    private submitButtonsActions: { [code: string]: () => Promise<void> } = {};
    private mainButtonElements = [];

    constructor(conf: Form, closeModal: () => void) {
        this.conf = conf;
        this.closeModal = closeModal;
    }

    async getItem(askLocation: boolean = true): Promise<{ [rowName: string]: string }> {
        for (const name in this.rows) {
            this.item[name] = this.rows[name].getValue();
        }
        if (askLocation && this.currentLocationFields.length > 0) {
            const location = await LocationProvider.tryGetLocation();
            if (location) {
                for (const field of this.currentLocationFields) {
                    this.item[field] = `${location.latitude}|${location.longitude}|${location.accuracy}`;
                }
            }
        }
        return this.item;
    }

    getRowValue(rowName: string): string {
        const row = this.rows[rowName];
        return row ? row.getValue() : null;
    }

    getFormElement(): JQuery { return this.form; }

    triggerMainButton(): void {
        if (this.mainButtonElements.length === 1) {
            this.mainButtonElements[0].click();
        } else {
            MessageDisplayer.showGenericError();
        }
    }

    async createForm(parent, data: FormModel, closeButton?) {
        if (!data) {
            return;
        }

        if (data.Description) {
            C.formGroup.html(Helper.removeScriptTags(data.Description)).appendTo(parent);
        }

        this.item = data.Item;
        this.form.appendTo(parent);
        if (this.conf.isCompact) {
            this.form.addClass("col-xs-12 form-inline");
        }

        await this.addRows(data.Rows, data.Item, data.ChoicesDisplayValues, data.Buttons);
        this.addButtons(data.Buttons, closeButton);

        let inMiddleOfSubmit = false;
        this.form.on("keyup keypress", (e) => {
                const keyCode = e.keyCode || e.which;
                if (keyCode === 13 && C.focusedTextArea.length === 0) {
                    e.preventDefault();
                }
            })
            .on("submit", async (e) => {
                if (!e.isDefaultPrevented()) {
                    e.preventDefault();

                    if (!inMiddleOfSubmit) {
                        inMiddleOfSubmit = true;
                        const name = this.currentClick.attr("name");
                        for (const rowName in this.rows) {
                            if (!this.rows[rowName].validate()) {
                                inMiddleOfSubmit = false;
                                return;
                            }
                        }
                        await this.submitButtonsActions[name]();
                        inMiddleOfSubmit = false;
                    }
                }
            });
    }

    private async addRows(rowGroups: FormRowGroupModel[],
                          item: { [rowName: string]: string },
                          choicesDisplayValues: { [choiceId: string]: string },
                          buttons: FormButtonModel[][]) {
        for (const group of rowGroups) {
            const parent =
                group.Children.length === 1 && group.Name === group.Children[0].Name
                    ? this.form
                    : EditControlBuilder.createGroupElement(this.form, group.Name, group.Collapse);
            for (const row of group.Children) {
                if (row.TypeData.Type === FormRowType.CurrentLocation) {
                    this.currentLocationFields.push(row.Name);
                } else {
                    this.rows[row.Name] = new FormRow(parent, row, this, this.conf.isCompact);
                }
            }
        }
        for (const name in this.rows) {
            await this.rows[name].setValue(item[name], choicesDisplayValues);
        }
        for (const name in this.rows) {
            await this.rows[name].initializeOtherRows(this.rows);
        }
        const triggerOnChangeButtons = [];
        for (const buttonsGroup of buttons) {
            for (const button of buttonsGroup) {
                if (button.TriggerOnChange) {
                    triggerOnChangeButtons.push(button);
                }
            }
        }
        if (triggerOnChangeButtons.length > 0) {
            for (const name in this.rows) {
                this.rows[name].onChange(async () => {
                    for (const button of triggerOnChangeButtons) {
                        await this.buttonClicked(button, false);
                    }
                });
            }
        }
    }

    private addButtons(buttonGroups: FormButtonModel[][], closeButton) {
        const buttonGroup = this.conf.isCompact
            ? this.form
            : C.buttonGroup.css("overflow", "auto")
                .css("padding-left", "15px")
                .css("padding-right", "15px").appendTo(this.form);

        for (const group of buttonGroups) {
            let buttonParent = buttonGroup;

            const hasButtonParent = group.length > 1 || this.conf.isCompact;
            if (hasButtonParent) {
                buttonParent = C.buttonsGroup.appendTo(buttonGroup);
                if (this.conf.isCompact) {
                    buttonParent.css("margin-top", "21px").css("margin-left", "10px");
                } else {
                    buttonParent.addClass("margin-right-5");
                }
            }

            for (const button of group) {
                if (button.TriggerOnChange) {
                    continue;
                }
                const isSubmit = [
                    FormButtonBehavior.Submit,
                    FormButtonBehavior.FileDownload,
                    FormButtonBehavior.Custom,
                    FormButtonBehavior.OpenModalWithDataTransfer
                ].includes(button.Behavior);
                const buttonElement = ButtonBuilder.get({
                    isSubmit: isSubmit,
                    isSecondary: !isSubmit,
                    name: button.Name,
                    icon: button.Icon ? Icon[button.Icon] : null,
                    onClick: isSubmit ? async () => {
                        this.currentClick = buttonElement;
                    } : () => this.buttonClicked(button),
                    cssClass: this.conf.isCompact ? "" : ("margin-top-5" + (hasButtonParent ? "" : " margin-right-5"))
                });
                if (button.Color) {
                    buttonElement.css("background", button.Color).css("border-color", button.Color);
                }
                if (isSubmit) {
                    const code = Helper.guid();
                    buttonElement.attr("name", code);
                    if (button.MainButton) {
                        this.mainButtonElements.push(buttonElement);
                    }
                    this.submitButtonsActions[code] = async () => await this.buttonClicked(button);
                }
                buttonElement.appendTo(buttonParent);
            }
        }

        if (closeButton) {
            closeButton.appendTo(buttonGroup);
        }
    }

    private async buttonClicked(button: FormButtonModel, askLocation: boolean = true) {
        if (button.Behavior === FormButtonBehavior.FileDownload) {
            DownloadHelper.downloadFileWithPostRequest(button.Url, await this.getItem(askLocation));
        } else if (button.Behavior === FormButtonBehavior.Custom) {
            this.conf.customAction(await this.getItem(askLocation), button.Type);
        } else if (button.Behavior === FormButtonBehavior.OpenModalWithDataTransfer) {
            this.closeModal();
            const newForm = new Form(button.Url);
            newForm.onDataChanged = this.conf.onDataChanged;
            newForm.defaultItem = await this.getItem(askLocation);
            await FormDisplayer.openInModal(newForm);
        } else if (button.Behavior === FormButtonBehavior.Submit) {
            const item = await this.getItem(askLocation);
            await this.mainClickAction(button, item);
        } else if (button.Behavior === FormButtonBehavior.Click) {
            await this.mainClickAction(button);
        } else if (button.Behavior === FormButtonBehavior.CloseForMessage) {
            ConfirmationDialogHelper.showDialog(
                button.ConfirmationMessage,
                button.Url,
                () => {
                    this.closeModal();
                    if (this.conf.onDataChanged) {
                        this.conf.onDataChanged(null, false);
                    }
                });
        } else if (button.Behavior === FormButtonBehavior.OpenModal) {
            this.closeModal();
            const newForm = new Form(button.Url);
            newForm.onDataChanged = this.conf.onDataChanged;
            await FormDisplayer.openInModal(newForm);
        } else if (button.Behavior === FormButtonBehavior.OpenViewSettings) {
            this.closeModal();
            await ViewSettings.openSettingsModal(button.Url, button.Name, () => this.conf.onDataChanged(null, false));
        } else if (button.Behavior === FormButtonBehavior.OpenDialogTable) {
            this.closeModal();
            await new DialogTable(button.Url, () => this.conf.onDataChanged(null, false)).open();
        }
    }

    private async mainClickAction(button: FormButtonModel, postItem?: { [rowName: string]: string }) {
        const onConfirmation = async () => {
            let result: FormActionResult;
            try {
                if (button.UseLoader) {
                    Loader.startGlobally();
                }
                if (postItem) {
                    result = await Requester.post(button.Url, postItem);
                } else {
                    result = await Requester.get(button.Url);
                }
            } catch (err) {
                MessageDisplayer.showErrorFromServer(err);
                return;
            }
            finally {
                if (button.UseLoader) {
                    Loader.stopGlobally();
                }
            }
            this.closeModal();
            if (result.Message) {
                MessageDisplayer.showMessage(result.Message);
            }
            if (result.Dialog) {
                MessageDisplayer.showDialog(result.Dialog);
            }
            if (result.File) {
                DownloadHelper.download(result.File);
            }
            if (result.TriggerMainAction) {
                if (this.conf.mainAction) {
                    this.conf.mainAction(result.Value);
                } else {
                    MessageDisplayer.showGenericError();
                }
            }
            if (button.Behavior === FormButtonBehavior.Submit && this.conf.onSave) {
                this.conf.onSave(result.Value);
            }
            if (this.conf.onDataChanged) {
                this.conf.onDataChanged(result.Value, result.MultipleItemsEdited);
            }
            if (result.NewFormUrl) {
                const newForm = new Form(result.NewFormUrl);
                newForm.onDataChanged = this.conf.onDataChanged;
                await FormDisplayer.openInModal(newForm);
            }
            if (result.NewDialogTableUrl) {
                await new DialogTable(result.NewDialogTableUrl, () => this.conf.onDataChanged(null, false)).open();
            }
            if (result.RefreshPage) {
                Helper.refreshPage();
            }
            if (result.SoftRefreshPage) {
                await PageNavigator.navigateToPageUsingInfo(NavigationUrlHelper.getCurrentPage());
            }
        };
        if (button.ConfirmationUrl) {
            let message;
            try {
                message = await Requester.post(button.ConfirmationUrl, postItem);
            } catch (err) {
                MessageDisplayer.showErrorFromServer(err);
                return;
            }
            if (message) {
                ConfirmationDialogHelper.askConfirmation(message, onConfirmation);
            } else {
                await onConfirmation();
            }
        } else if (button.ConfirmationMessage) {
            ConfirmationDialogHelper.askConfirmation(button.ConfirmationMessage, onConfirmation);
        } else {
            await onConfirmation();
        }
    }
}
