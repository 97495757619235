import { Helper } from "../../../common/helper";
import { ImagePreview } from "../../../common/image-preview";

export class ImagePreviewControl {
    private readonly parent;

    constructor(parent) { this.parent = parent; }

    configure(file?) {
        if (file && Helper.isImage(file.name)) {
            this.showImagePreview(file);
        } else {
            this.removeImagePreview();
        }
    }

    private removeImagePreview() {
        this.parent.unbind("mouseenter mouseleave");
        this.parent.popover("hide");
    }

    private showImagePreview(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            new ImagePreview(this.parent, e.target.result);
            this.parent.popover("show");
        };
    }
}
