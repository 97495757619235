import { C } from "../../common/component";
import { Icon, IconHelper } from "../../common/icon-helper";
import { Requester } from "../../common/requester";
import { Txt } from "../../common/text";
import { NavigationUrlHelper } from "../../navigation/navigation-url-helper";
import { Helper } from "../../common/helper";
import { Displayer } from "../../common/displayer";
import { Form, FormDisplayer } from "../../forms/form";
import { ViewSettings } from "../../views/view-settings";
import { ItemPageData, ItemPageMainPanelData } from "../item-page";
import { ModalBuilder } from "../../common/modal-builder";
import { MessageDisplayer } from "../../common/message-displayer";
import { ItemsMap, MapMarker } from "../../maps/items-map";
import { Application } from "../../navigation/application";
import { Google } from "../../maps/google-maps";

// ReSharper disable InconsistentNaming
interface EmployeePanelData {
    Name: string;
    PermissionLevel: string;
    JobTitle: string;
    Email: string;
    Telephone: string;
    Picture: string;
}

interface ProjectPanelData {
    Location: MapMarker;
}

// ReSharper restore InconsistentNaming

export class ItemPageMainDataPanel {
    private data: ItemPageMainPanelData;
    private readonly urlForRefresh: string;
    private readonly refreshPage: () => void;
    private readonly container = C.div.addClass("flex");

    private projectLocation: MapMarker;

    constructor(data: ItemPageMainPanelData, urlForRefresh: string, refreshPage: () => void) {
        this.data = data;
        this.urlForRefresh = urlForRefresh;
        this.refreshPage = refreshPage;
    }

    appendTo(parent) {
        this.container.appendTo(parent);
        this.initializeLeftPanel();
        this.initializeRightPanel();
    }

    private async refresh(): Promise<void> {
        this.container.empty();
        this.data = (<ItemPageData>await Requester.get(this.urlForRefresh)).MainPanelData;
        if (this.data) {
            this.initializeLeftPanel();
            this.initializeRightPanel();
        } else {
            NavigationUrlHelper.setSubPage(null);
            Helper.refreshPage();
        }
    }

    private initializeLeftPanel() {
        const leftContainer = C.column(4, false).addClass("panel panel-default").appendTo(this.container);
        const profileInfo = C.div.addClass("profile-info").appendTo(leftContainer);

        let map: ItemsMap = null;
        if (this.data.IsProjectPage) {
            const customData = <ProjectPanelData>this.data.CustomData;
            this.projectLocation = customData.Location;
            const mapContainer = C.div.prependTo(leftContainer);
            mapContainer.height(390);
            map = new ItemsMap();
            map.appendTo(mapContainer);
            this.refreshMap(map);
        } else {
            const customData = <EmployeePanelData>this.data.CustomData;
            C.img.addClass("img-circle img-responsive").attr("src", customData.Picture || "images/profile.png")
                .appendTo(profileInfo);

            const title = C.h4.appendTo(profileInfo);
            title.text(customData.Name);
            C.br.appendTo(title);
            C.small.text(customData.PermissionLevel).appendTo(title);
            if (customData.JobTitle) {
                C.label.addClass("label").text(customData.JobTitle).appendTo(profileInfo);
            }
            IconHelper.addIconWithText(C.h5.appendTo(profileInfo), Icon.Envelope, customData.Email);
            IconHelper.addIconWithText(C.h5.appendTo(profileInfo), Icon.Phone, customData.Telephone);
        }
        
        for (const button of this.data.Buttons) {
            C.getPrimaryButton(button.Name, async () => {
                if (button.Type === "Location") {
                    this.showLocationChangeModal(button.Url, map)
                    return;
                }
                const form = new Form(button.Url);
                if (button.Type === "FormWithRefresh") {
                    form.onDataChanged = () => this.refresh();
                }
                await FormDisplayer.openInModal(form);
            }).appendTo(profileInfo);
        }
    }

    private refreshMap(map: ItemsMap) {
        map.refresh(null, this.projectLocation ? [this.projectLocation] : [],
            this.projectLocation?.Latitude ?? ItemsMap.centerLat,
            this.projectLocation?.Longitude ?? ItemsMap.centerLng,
            this.projectLocation ? 10 : ItemsMap.zoom);
    }

    private initializeRightPanel() {
        const rightContainer = C.column(8, false).addClass("panel panel-default").appendTo(this.container);
        const titleElement = C.h4.text(Txt.mainData).appendTo(rightContainer);
        if (this.data.SettingsUrl) {
            ViewSettings.addSettingsButton(titleElement, Icon.Cog, null,
                () => {
                    const form = new Form(this.data.SettingsUrl);
                    form.onDataChanged = () => this.refreshPage();
                    FormDisplayer.openInModal(form);
                });
        }

        for (const property of this.data.Properties) {
            const col = C.div.addClass("col-md-6").appendTo(rightContainer);
            C.h5.addClass("profile-gray").text(property.Key).appendTo(col);
            Displayer.setValue(C.span.appendTo(col), property.Value);
        }
    }

    private showLocationChangeModal(changeUrl: string, displayMap: ItemsMap) {
        const modal = ModalBuilder.createModal(Txt.selectLocation);
        modal.body.addClass("map");

        const position = {
            lat: this.projectLocation?.Latitude ?? ItemsMap.centerLat,
            lng: this.projectLocation?.Longitude ?? ItemsMap.centerLng,
        };

        const map = new Google.maps.Map(modal.body.get(0), {
            disableDefaultUI: true,
            zoomControl: true,
            zoom: 7,
            center: position,
        });

        const marker = new Google.maps.Marker({
            position: position,
            map: map,
            title: Txt.projectLocation,
            icon: "../images/random_marker.png",
        });

        const circle = new Google.maps.Circle({
            map: map,
            radius: this.projectLocation?.Radius ?? Application.defaultRadius,
            fillColor: "mediumpurple",
        });
        circle.bindTo("center", marker, "position");

        const searchInput = C.input.addClass("map-input")
            .attr("type", "text")
            .attr("placeholder", Txt.searchLocation)
            .appendTo(modal.content);
        const radiusInput = C.input.addClass("map-input")
            .attr("type", "number")
            .attr("min", "100")
            .attr("max", "10000")
            .attr("step", "100")
            .val(circle.radius)
            .attr("placeholder", Txt.radius)
            .change(() => {
                let val = parseInt(<string>radiusInput.val(), 10) || Application.defaultRadius;

                if (val > 10000) {
                    radiusInput.val(10000);
                    val = 10000;
                }

                if (val < 100) {
                    radiusInput.val(100);
                    val = 100;
                }

                circle.setRadius(val);
            })
            .appendTo(modal.content);
        const modalFooter = C.div.addClass("modal-footer").appendTo(modal.content);
        const selectLoc = C.button.addClass("btn btn-primary").attr("type", "button").text(Txt.save)
            .appendTo(modalFooter);
        const deselectLoc = C.button.addClass("btn btn-primary").attr("type", "button").text(Txt.clearLocation)
            .appendTo(modalFooter);

        Google.maps.event.addListener(map, "click", (event) => {
            position.lat = event.latLng.lat();
            position.lng = event.latLng.lng();
            marker.setPosition(new Google.maps.LatLng(position.lat, position.lng));
        });

        const searchBox = new Google.maps.places.SearchBox(searchInput.get(0));
        map.controls[Google.maps.ControlPosition.TOP_LEFT].push(searchInput.get(0));
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        map.controls[Google.maps.ControlPosition.TOP_LEFT].push(radiusInput.get(0));

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            for (const place of places) {
                if (place.geometry) {
                    const bounds = new Google.maps.LatLngBounds();

                    position.lat = place.geometry.location.lat();
                    position.lng = place.geometry.location.lng();
                    marker.setPosition(new Google.maps.LatLng(position.lat, position.lng));

                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }

                    map.fitBounds(bounds);

                    break;
                }
            }
        });

        selectLoc.click(async () => {
            try {
                this.projectLocation = {
                    Latitude: position.lat,
                    Longitude: position.lng,
                    Radius: parseInt(<string>radiusInput.val(), 10) || Application.defaultRadius
                };
                const message = await Requester.get(Requester.getUrl(changeUrl, {
                    latitude: this.projectLocation.Latitude,
                    longitude: this.projectLocation.Longitude,
                    radius: this.projectLocation.Radius
                }));
                ModalBuilder.removeModal(modal.container);
                this.refreshMap(displayMap);
                MessageDisplayer.showMessage(message);
            } catch (err) {
                MessageDisplayer.showErrorFromServer(err);
            }
        });

        deselectLoc.click(async () => {
            try {
                this.projectLocation = null;
                const message = await Requester.get(changeUrl);
                ModalBuilder.removeModal(modal.container);
                this.refreshMap(displayMap);
                MessageDisplayer.showMessage(message);
            } catch (err) {
                MessageDisplayer.showErrorFromServer(err);
            }
        });

        modal.container.modal({
            backdrop: "static",
            keyboard: true,
            show: true,
        });
        Google.maps.event.trigger(map, "resize", {});
    }
}
