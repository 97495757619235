import { C } from "../common/component";
import { Icon } from "../common/icon-helper";
import { Timesheet } from "./timesheet";
import { TimesheetShortcutsSet } from "./timesheet-shortcuts-set";
import { TimesheetData } from "./timesheet-models";
import { TimesheetShortcutsPanel } from "./timesheet-shortcuts-panel";
import { FormComponent } from "../forms/form-component";
import { Loader } from "../common/loader";
import { StorageHelper, StorageItem } from "../common/storage-helper";

export class TimesheetPageCreator {
    private readonly form: FormComponent;
    private shortcuts: TimesheetShortcutsPanel;
    
    constructor(form: FormComponent) {
        this.form = form;
    }

    async show(data: TimesheetData, parent: any) {
        Loader.startGlobally();
        try {
            if (!this.shortcuts) {
                this.shortcuts = new TimesheetShortcutsPanel(this.form.getFormElement());
            }
            const shortcuts = new TimesheetShortcutsSet(data.Shortcuts, data.ShortcutTypeNames);
            this.shortcuts.set(shortcuts);

            const timesheetDiv = C.div.addClass("row schedule schedule-main").appendTo(parent);
            const width = StorageHelper.get(StorageItem.timesheetWidth) || 4;
            this.addResizeButtons(data, timesheetDiv, width);
            const timesheet = new Timesheet(timesheetDiv, shortcuts, width);
            timesheet.setData(data);
            C.p.addClass("text-center").text(data.Footer).appendTo(parent);
        } finally {
            Loader.stopGlobally();
        }
    }

    private addResizeButtons(data: TimesheetData, parent: any, width: number) {
        const resizeButtonsRow = C.row.addClass("print-no").css("margin-left", "0px").css("margin-right", "0px")
            .appendTo(parent);
        const leftColumn = C.div.addClass(`col-xs-${width} zero-padding`).appendTo(resizeButtonsRow);
        C.normalButton(Icon.Left).css("float", "right").css("padding", "1px 10px 1px 10px")
            .appendTo(leftColumn).click(() => {
            if (width > 1) {
                StorageHelper.set(StorageItem.timesheetWidth, width - 1);
                parent.empty();
                this.show(data, parent);
            }
        });
        const rightColumn = C.row.addClass(`col-xs-${12 - width} zero-padding`).css("margin-left", "0px")
            .appendTo(resizeButtonsRow);
        C.normalButton(Icon.Right).css("float", "left").css("padding", "1px 10px 1px 10px")
            .appendTo(rightColumn).click(() => {
            if (width < 11) {
                StorageHelper.set(StorageItem.timesheetWidth, width + 1);
                parent.empty();
                this.show(data, parent);
            }
        });
    }
}
