export enum Urls {
    authenticationLogOut = "authentication/logOut",
    authenticationShouldLogOut = "authentication/shouldLogOut",

    navbar = "common/NavigationBar",
    registerWebPushNotifications = "common/RegisterWebPushNotifications",
    notifications = "common/notifications",
    markNotificationsAsSeen = "common/markNotificationsAsSeen",

    itemPage = "items/ItemPage",
    timerState = "timer/State",
    seatingPlan = "seating/plan",
    uploadUrl = "files/uploadUrl"
}
