import "core-js";
import * as $ from "jquery";
import { AuthenticationHandler } from "../common/authentication-handler";
import { Pwa } from "./pwa";
import { Application } from "./application";

window["jQuery"] = $;
require("bootstrap");
require("datatables.net-bs");
require("datatables.net-buttons-bs");
require("datatables.net-fixedheader-bs");
require("datatables.net-responsive-bs");

if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(function (registration) {
            console.log("ServiceWorker registration successful with scope: ", registration.scope);
        }, function (err) {
            console.log("ServiceWorker registration failed: ", err);
        });
    });
    window.addEventListener("beforeinstallprompt", function (e) {
        e.preventDefault();
        Pwa.deferredPrompt = e;
    });
}

$(async () => {
    $.ajaxSetup({
        beforeSend(xhr) {
            xhr.setRequestHeader(AuthenticationHandler.controlTokenParamName, localStorage[AuthenticationHandler.controlTokenParamName]);
        }
    });

    if (await AuthenticationHandler.isLoggedIn()) {
        if (window.location.hostname === "user.wemply.com") {
            await AuthenticationHandler.logout();
            location.reload();
        } else {
            await Application.initialize();
        }
    } else {
        window.location.replace(window.location.origin + "/auth.html" + window.location.search + window.location.hash);
    }
});
