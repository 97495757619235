import { C } from "../../common/component";
import SignaturePad from "signature_pad";
import { Icon, IconHelper } from "../../common/icon-helper";
import { Txt } from "../../common/text";
import { EditControl } from "../form-row";

export class SignatureEditControl implements EditControl {
    private input: JQuery;
    private signaturePad: SignaturePad;
    private signature: string;
    private clearButton: JQuery;
    private canvas: JQuery;

    constructor() {
        this.input = C.div;
        this.canvas = C.canvas.addClass("form-control signature input-group").appendTo(this.input);
        const canvas = this.canvas[0];
        if (window.innerWidth > 600) {
            canvas.setAttribute("width", "500px");
        }
        this.signaturePad = new SignaturePad(<HTMLCanvasElement> canvas);
        this.clearButton = C.button
            .addClass("btn btn-default")
            .attr("type", "button")
            .click(() => this.signaturePad.clear())
            .appendTo(this.input);
        IconHelper.addIconWithText(this.clearButton, Icon.remove, Txt.clear);
    }

    initialize(parent: HTMLElement) { parent.append(this.input[0]); }

    async setValue(value) {
        if (value) {
            const [signature] = value.split("|");
            this.signature = value;
            this.signaturePad.fromDataURL(signature);
            this.signaturePad.off();
            this.canvas.css("background", `
                repeating-linear-gradient(
                    45deg,
                    darkgray,
                    darkgray 10px,
                    lightgray 10px,
                    lightgray 20px
                )
            `);
            this.clearButton.hide();
        }
    }

    getValue() {
        const value = this.signaturePad.toDataURL();
        if (this.signature) {
            return this.signature;
        }
        if (this.signaturePad.isEmpty()) {
            return "";
        }
        return value;
    }

    onChange() {}

    focus() {
        this.input.focus();
    }
}
