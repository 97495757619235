import { C } from "../common/component";
import { Urls } from "../common/urls";
import { Requester } from "../common/requester";
import { Form, FormDisplayer } from "../forms/form";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { Txt } from "../common/text";
import { ItemPage } from "./item-page";

// ReSharper disable InconsistentNaming
export interface EmployeesViewEmployeeData {
    Name: string;
    Picture: string;
    Id: number;

    Url: string;
    OpenAsForm: boolean;
}
// ReSharper restore InconsistentNaming

export class EmployeesView {
    private readonly url: string;
    private readonly container;

    constructor(url: string) {
        this.url = url;
        this.container = C.div;
    }

    async appendTo(parent) {
        this.container.appendTo(parent);
        await this.refresh();
    }

    async refresh() {
        this.container.empty();
        const innerContainer = C.container.appendTo(this.container);
        const employeeDashboardContainer = C.div.appendTo(this.container);

        const row = C.row.appendTo(innerContainer);
        const data = await Requester.get(this.url);
        const panels = {};

        this.addFilter(row, panels);

        if (data.newEmployeeFormUrl) {
            const plusButton = C.plusButton.css("margin-top", "10px");
            EmployeesView.createPanel(row, plusButton, data.newEmployeeButtonText, () => {
                const form = new Form(data.newEmployeeFormUrl);
                form.onSave = itemId => {
                    NavigationUrlHelper.setSubPage(itemId.split("@")[0]);
                    this.refresh();
                };
                FormDisplayer.openInModal(form);
            });
        }

        const currentPage = NavigationUrlHelper.getCurrentPage();
        let isSubPageOpened = false;
        const employees = <EmployeesViewEmployeeData[]>data.employees;
        for (let i = 0; i < employees.length; i++) {
            const employee = employees[i];
            const image = C.img.addClass("img-circle img-responsive")
                .css("height", "128px")
                .css("margin-top", "10px")
                .attr("src", employee.Picture || "images/profile.png");
            panels[employee.Id] = {
                panel: EmployeesView.createPanel(row, image, employee.Name, () => {
                    this.openEmployeePageOrForm(employees, i, innerContainer, employeeDashboardContainer);
                }),
                name: employee.Name
            };
            if (currentPage.subPage === employee.Id.toString()) {
                this.openEmployeePageOrForm(employees, i, innerContainer, employeeDashboardContainer);
                isSubPageOpened = true;
            }
        }
        if (currentPage.subPage && !isSubPageOpened) {
            this.openEmployeePage(Requester.getUrl(Urls.itemPage, {id: currentPage.subPage + "@Employee"}),
                null, null, innerContainer, employeeDashboardContainer);
        }
    }

    private static createPanel(parent, image, name: string, onClick: () => void) {
        const panel = C.column(2, true).addClass("col-sm-4 panel panel-default img-center hover-panel")
            .appendTo(parent);
        image.appendTo(panel);
        C.h4.css("height", "34px").text(name).appendTo(panel);
        panel.click(onClick);
        return panel;
    }

    private openEmployeePageOrForm(employees: EmployeesViewEmployeeData[], index: number, innerContainer, employeeDashboardContainer) {
        const employee = employees[index];
        NavigationUrlHelper.setSubPage(employee.Id.toString());
        if (employee.OpenAsForm) {
            innerContainer.show();
            FormDisplayer.openInModal(new Form(employee.Url));
            return;
        }
        this.openEmployeePage(employee.Url, index + 1 === employees.length ? null : () => {
            employeeDashboardContainer.empty();
            this.openEmployeePageOrForm(employees, index + 1, innerContainer, employeeDashboardContainer);
        }, index === 0 ? null : () => {
            employeeDashboardContainer.empty();
            this.openEmployeePageOrForm(employees, index - 1, innerContainer, employeeDashboardContainer);
        }, innerContainer, employeeDashboardContainer);
    }

    private async openEmployeePage(url: string, onRightClick: () => void, onLeftClick: () => void, innerContainer,
                                   employeeDashboardContainer) {
        innerContainer.hide();
        await new ItemPage(url, () => {
            employeeDashboardContainer.empty();
            innerContainer.show();
            NavigationUrlHelper.setSubPage(null);
        }, onRightClick, onLeftClick).appendTo(employeeDashboardContainer);
    }

    private addFilter(parent, panels: { [id: string]: any }): void {
        const filterInput = C.input.addClass("form-control").attr("placeholder", Txt.search)
            .appendTo(C.column(12, true).appendTo(parent));
        filterInput.change(() => {
            const filterText = <string>filterInput.val();
            if (!filterText || filterText === "") {
                for (const panel in panels) {
                    panels[panel].panel.show();
                }
            } else {
                const parts = filterText.toLowerCase().split(" ");
                for (const panel in panels) {
                    let hasAllParts = true;
                    const name = panels[panel].name.toLowerCase();
                    for (const part of parts) {
                        if (!name.includes(part)) {
                            hasAllParts = false;
                            break;
                        }
                    }
                    if (hasAllParts) {
                        panels[panel].panel.show();
                    } else {
                        panels[panel].panel.hide();
                    }
                }
            }
        });
    }
}
