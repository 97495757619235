import { Icon, IconHelper } from "../common/icon-helper";
import { Txt } from "../common/text";
import { C } from "../common/component";
import { PageInfo, PageNavigator } from "./page-navigator";
import { SelectControl, SelectOption } from "../forms/edit-controls/select-control";

export class SearchBox {
    private readonly pages: PageInfo[];

    constructor(pages: PageInfo[]) { this.pages = pages; }

    appendTo(parent): void {
        const container = C.li.addClass("dropdown").appendTo(parent);

        const button = this.getButton().appendTo(container);

        const searchBoxContainer = C.div.addClass("dropdown-menu").appendTo(container);
        const choices: SelectOption[] = [];
        for (const page of this.pages) {
            choices.push({ id: page.Name, text: page.DisplayName, disabled: false });
        }
        const control = new SelectControl(false, choices);
        control.initialize(searchBoxContainer[0]);
        control.hideSelectedItemsControl();
        
        button.click(() => {
            searchBoxContainer.show();
            control.focus();
        });
        control.onBlur(() => {
            searchBoxContainer.hide();
            const value = control.getValue();
            if (value) {
                control.setValue(null);
                PageNavigator.navigateToPage(value);
            }
        });
    }

    private getButton() {
        const button = C.a.addClass("dropdown-toggle notification-button");
        C.span.addClass(IconHelper.getIconClass(Icon.Search) + " notification-icon").attr("title", Txt.search)
            .appendTo(button);
        C.span.addClass("mobile").text(` ${Txt.search}`).appendTo(button);
        return button;
    }
}
