import { Helper } from "../common/helper";

export class Google {
    private static authKey = "AIzaSyAUs8opIVIgYDgUGI628p8ohTe-VMioVbE";
    static maps: any;

    static async initialize() {
        await Helper.loadScript(`https://maps.google.com/maps/api/js?libraries=places&key=${Google.authKey}`);
        Google.maps = (<any>window).google.maps;
    }
}
