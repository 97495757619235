import { C } from "../../../common/component";
import { Icon, IconHelper } from "../../../common/icon-helper";
import { Txt } from "../../../common/text";
import { FileUploader } from "./file-uploader";
import { FileControl } from "./file-control";
import { FileInfo } from "./file-info";
import { FileValidator } from "./file-validator";
import { EditControl } from "../../form-row";

export class MultiFileEditControl implements EditControl {
    private readonly parentContainer: JQuery;
    private readonly row: JQuery;
    private readonly fileInput: JQuery;

    private readonly onChangeActions: (() => void)[] = [];
    private controls: FileControl[] = [];

    constructor() {
        this.row = C.div;
        this.parentContainer = C.div.appendTo(this.row);
        const inputGroup = C.div.addClass("input-group").appendTo(this.row);
        const buttonsContainer = C.span.appendTo(inputGroup);
        const browseButton = C.div.addClass("btn btn-default").appendTo(buttonsContainer);
        IconHelper.addIconWithText(browseButton, Icon.folderOpen, Txt.add);
        this.fileInput = C.input.addClass("file-input file-hide").attr("type", "file").change(e => this.uploadFile(e))
            .appendTo(browseButton);
        this.fileInput.attr("multiple", "");
    }

    private uploadFile(e) {
        for (const file of e.target.files) {
            if (!FileValidator.validateFile(file, false)) {
                return;
            }
        }

        for (const file of e.target.files) {
            const uploader = new FileUploader(file);
            const control = new FileControl(new FileInfo(file.name, uploader), () => {
                for (const action of this.onChangeActions) {
                    action();
                }
            });
            this.controls.push(control);
            control.appendTo(this.parentContainer);
            control.configureImagePreview(file);
        }
    }

    initialize(parent: HTMLElement) { parent.append(this.row[0]); }

    async setValue(value) {
        for (const control of this.controls) {
            if (!control.isRemoved()) {
                control.remove();
            }
        }
        this.controls = [];
        if (value) {
            const references: string[] = JSON.parse(value);
            for (const reference of references) {
                const control = new FileControl(FileInfo.parseFromReference(reference), () => {
                    for (const action of this.onChangeActions) {
                        action();
                    }
                });
                this.controls.push(control);
                control.appendTo(this.parentContainer);
            }
        }
    }

    getValue() {
        const files = [];
        for (const control of this.controls) {
            if (!control.isRemoved()) {
                files.push(control.getFile().getReference());
            }
        }
        return JSON.stringify(files);
    }

    onChange(action: () => void) { this.onChangeActions.push(action); }

    getInputs(): JQuery[] { return []; }

    validate(): boolean {
        for (const control of this.controls) {
            if (!control.isRemoved() && control.getFile().hasUploader() &&
                !FileValidator.validateUpload(control.getFile().getUploader())) {
                return false;
            }
        }
        return true;
    }

    focus() { this.fileInput.focus(); }
}
