import { C } from "../common/component";
import { Form, FormDisplayer } from "../forms/form";
import { Helper } from "../common/helper";
import { Icon, IconHelper } from "../common/icon-helper";
import { Requester } from "../common/requester";
import { Txt } from "../common/text";
import { ViewTable } from "./view-table";
import { PageInfo, PageNavigator } from "../navigation/page-navigator";
import { ItemsMap } from "../maps/items-map";
import { Calendar } from "../common/calendar";
import { NavigationUrlHelper } from "../navigation/navigation-url-helper";
import { ViewSettings } from "./view-settings";
import { ButtonBuilder } from "../common/button-builder";
import { PanelDefinition } from "../dashboard/panels/dashboard-panel";
import { SimpleTableData } from "../common/simple-table";
import { DashboardHelper } from "../dashboard/dashboard-helper";
import { TablePanel } from "../dashboard/panels/table-panel";

// ReSharper disable InconsistentNaming
export interface ViewPageInfo extends PageInfo {
    AddButtonFormUrl: string;
    ViewColumnsUrl: string;
    ViewDataUrl: string;
    ViewSettingsUrl: string;
    ViewSettingsTooltip: string;
    ViewSettingsIcon: string;
    HideHeaderOnMobile: string;
    ViewFileUrl: string;
    ViewFilterFormUrl: string;
    SimpleTableUrls: string[];
    ViewMapUrl: string;
    CalendarDataUrl: string;
    CalendarEditUrl: string;
    CalendarResourcesUrl: string;
    HasEmployeeBasedCalendar: boolean;
    HasProjectBasedCalendar: boolean;
    CalendarWithYearlyViews: boolean;
    HasMobileEmployeeBasedCalendar: boolean;
    IsInactiveText?: string;
    Information: string;
    ShowLastRefreshTimeOnHeader: boolean;
}

// ReSharper restore InconsistentNaming

export class ViewPageCreator {
    static async createViewPage(data: ViewPageInfo, parent): Promise<void> {
        if (Helper.isMobileScreen()) {
            parent.css("margin-left", "-15px").css("margin-right", "-15px");
        }
        let panel = parent;
        let titleElement;

        if (!data.HideHeaderOnMobile || !Helper.isMobileScreen()) {
            panel = C.div.addClass("panel panel-default").appendTo(parent);
            const header = C.div.addClass("panel-heading").appendTo(panel);
            const headerRow = C.div.addClass("row").appendTo(header);

            const headerLeft = C.div.addClass("col-xs-6").appendTo(headerRow);
            titleElement = C.h4.text(data.DisplayName).appendTo(headerLeft);
            if (data.ViewSettingsUrl) {
                ViewSettings.addSettingsButton(titleElement, Icon[data.ViewSettingsIcon], data.ViewSettingsTooltip,
                    () => {
                        const form = new Form(data.ViewSettingsUrl);
                        // Do soft refresh to load view data again
                        form.onDataChanged = () => PageNavigator.navigateToPageUsingInfo(NavigationUrlHelper.getCurrentPage());
                        FormDisplayer.openInModal(form);
                    });
            }

            const headerRight = C.div.addClass("col-xs-6").appendTo(headerRow);
            if (data.AddButtonFormUrl) {
                C.button.addClass("btn btn-primary pull-right")
                    .attr("type", "button")
                    .text(Txt.add)
                    .click(() => {
                        const form = new Form(data.AddButtonFormUrl);
                        // TODO: If calendar or map is displayed instead of table, then them should be refreshed instead of table
                        form.onDataChanged = () => table.refresh();
                        FormDisplayer.openInModal(form);
                    }).appendTo(headerRight);
            }
        }

        if (data.Information) {
            C.div.addClass("panel-body").html(Helper.removeScriptTags(data.Information)).appendTo(panel);
        }

        const body = C.div.appendTo(panel);
        if (!Helper.isMobileScreen()) {
            body.addClass("panel-body");
        }
        body.hide();

        const tableBody = C.div.addClass("table-responsive").appendTo(panel);
        let table: ViewTable;
        if (data.ViewColumnsUrl) {
            const serverSide = Helper.hasValue(data.ViewFilterFormUrl);
            table = new ViewTable(tableBody, data.ViewColumnsUrl, data.ViewDataUrl, data.ViewFileUrl, serverSide);
            PageNavigator.addDisposableElement(table);
        }

        const mapContainer = C.div.height(800).appendTo(panel);
        mapContainer.hide();
        let map: ItemsMap;
        if (data.ViewMapUrl) {
            map = new ItemsMap(data.ViewMapUrl);
            map.appendTo(mapContainer);
        }

        const calendarContainer = C.div.appendTo(panel);
        calendarContainer.hide();
        let calendar: Calendar;
        if (data.CalendarDataUrl && data.HasEmployeeBasedCalendar) {
            calendar = new Calendar(data.CalendarDataUrl, data.CalendarResourcesUrl, false,
                data.CalendarWithYearlyViews, data.HasMobileEmployeeBasedCalendar, data.CalendarEditUrl);
            calendar.appendTo(calendarContainer);
        }

        const projectBasedCalendarContainer = C.div.appendTo(panel);
        projectBasedCalendarContainer.hide();
        let projectBasedCalendar: Calendar;
        if (data.CalendarDataUrl && data.HasProjectBasedCalendar) {
            projectBasedCalendar = new Calendar(data.CalendarDataUrl, data.CalendarResourcesUrl, true,
                data.CalendarWithYearlyViews, data.HasMobileEmployeeBasedCalendar, data.CalendarEditUrl);
            projectBasedCalendar.appendTo(projectBasedCalendarContainer);
        }

        const simpleTableContainers: JQuery[] = [];
        if (data.IsInactiveText) {
            body.show();
            body.addClass("panel-body");
            const formTemp = C.form.addClass("form-inline").appendTo(C.span.css("float", "left").appendTo(body));
            const checkboxContainer = C.div.addClass("checkbox viewParameterCheckbox").appendTo(formTemp);
            const checkboxLabel = C.label.text(data.IsInactiveText).appendTo(checkboxContainer);
            const input = C.input.attr("type", "checkbox").change(() => {
                const isChecked = input.is(":checked");
                table.refresh(Requester.getUrl(data.ViewDataUrl, {inactive: isChecked}), null, isChecked);
            }).appendTo(checkboxLabel);
            C.span.addClass("cr").append(C.i.addClass("cr-icon " + IconHelper.getIconClass(Icon.ok)))
                .appendTo(checkboxLabel);
        }

        if (data.ViewFilterFormUrl) {
            body.show();
            let formDiv: JQuery<HTMLElement>;
            const isMobileScreen = Helper.isMobileScreen();
            if (isMobileScreen) {
                const onlySingleCalendar = calendar && !table && !projectBasedCalendar && !map;
                if (calendar) {
                    const headerContainer = C.div.css("margin-bottom", "10px")
                        .css("margin-left", "10px").css("margin-right", "10px");
                    if (onlySingleCalendar) {
                        const filtersButton = ButtonBuilder.get({
                            name: Txt.filters,
                            icon: Icon.Filter,
                            isSecondary: true
                        }).appendTo(headerContainer);
                        formDiv = this.createCollapsiblePanel(filtersButton, body, headerContainer);
                    } else {
                        headerContainer.prependTo(calendarContainer);
                    }
                    calendar.addExternalHeader(headerContainer);
                }
                if (projectBasedCalendar) {
                    const headerContainer = C.div.css("margin-bottom", "10px")
                        .css("margin-left", "10px").css("margin-right", "10px");
                    headerContainer.prependTo(projectBasedCalendarContainer);
                    projectBasedCalendar.addExternalHeader(headerContainer);
                }
                if (!onlySingleCalendar) {
                    formDiv = this.createCollapsiblePanel(this.createCollapsiblePanelHeader(Txt.filters), body);
                }
            } else {
                formDiv = C.div.addClass("schedule row col-12").appendTo(body);
            }

            const form = new Form(data.ViewFilterFormUrl);
            form.isCompact = !isMobileScreen;
            form.customAction = (item, type) => {
                if (type === "Map") {
                    tableBody.hide();
                    calendarContainer.hide();
                    projectBasedCalendarContainer.hide();
                    mapContainer.show();
                    map.refresh(item);
                } else if (type === "Calendar") {
                    tableBody.hide();
                    mapContainer.hide();
                    projectBasedCalendarContainer.hide();
                    calendarContainer.show();
                    for (const simpleTable of simpleTableContainers) {
                        simpleTable.hide(); // Hide because it takes too much space and we do not refresh it on change
                    }
                    calendar.refresh(item);
                } else if (type === "ProjectBasedCalendar") {
                    tableBody.hide();
                    calendarContainer.hide();
                    mapContainer.hide();
                    projectBasedCalendarContainer.show();
                    projectBasedCalendar.refresh(item);
                } else {
                    const subPage = JSON.stringify(item);
                    if (subPage.length < 1900) {
                        NavigationUrlHelper.setSubPage(subPage);
                    } else {
                        NavigationUrlHelper.setSubPage(null);
                    }
                    mapContainer.hide();
                    calendarContainer.hide();
                    projectBasedCalendarContainer.hide();
                    tableBody.show();
                    for (const simpleTable of simpleTableContainers) {
                        simpleTable.show();
                    }
                    table.refresh(data.ViewDataUrl, item);
                }
            };
            const formDefaultData = NavigationUrlHelper.getCurrentPage().subPage;
            if (formDefaultData) {
                form.defaultItem = JSON.parse(formDefaultData);
            }

            const formComponent = await FormDisplayer.addForm(form, formDiv);
            if (table) {
                table.postData = await formComponent.getItem();
            } else if (calendar) {
                tableBody.hide();
                calendarContainer.show();
                calendar.refresh(await formComponent.getItem());
            }
        }

        for (const simpleTableUrl of data.SimpleTableUrls) {
            body.show();
            const definition = await Requester.get(simpleTableUrl);
            const simpleTable = new ViewPageTable(definition,
                data.ShowLastRefreshTimeOnHeader
                    ? () => titleElement?.text(data.DisplayName + " " + new Date().toLocaleTimeString("et-EE"))
                    : null);
            const container = C.div.appendTo(body);
            simpleTableContainers.push(container);
            if (data.ShowLastRefreshTimeOnHeader) {
                simpleTable.appendTo(container);
            } else {
                const collapsiblePanel = this.createCollapsiblePanel(this.createCollapsiblePanelHeader(definition.Title), container);
                simpleTable.appendTo(C.div.addClass("col-md-12").appendTo(collapsiblePanel));
            }
            table?.onRefresh.push(() => simpleTable.refresh());
        }

        if (table) {
            table.init();
        } else if (calendar && !data.ViewFilterFormUrl) {
            tableBody.hide();
            calendarContainer.show();
            calendar.refresh(null);
        }
    }

    private static createCollapsiblePanel(header, parent, headerContainer?) {
        const panelDefault = C.div.addClass("table panel item-panel row col-12")
            .css("margin-top", "20px").appendTo(parent);
        (headerContainer ?? header).appendTo(panelDefault);
        const panelCollapse = C.div.addClass("panel-collapse collapse").css("margin-top", "-15px")
            .css("margin-bottom", "-15px").appendTo(panelDefault);
        const panelBody = C.div.addClass("panel-body").appendTo(panelCollapse);
        header.click(function () {
            panelCollapse.collapse("toggle");
        });
        return panelBody;
    }

    private static createCollapsiblePanelHeader(title: string) {
        const panelHeading = C.div.addClass("panel-heading panel-click");
        panelHeading.css("background-color", "#663399").css("color", "#ffffff").css("border-radius", "50px")
            .css("padding-top", "5px").css("padding-bottom", "5px")
            .css("margin-left", "15px").css("margin-right", "15px").css("margin-bottom", "10px");
        C.span.addClass("panel-title").text(title).appendTo(panelHeading);
        return panelHeading;
    }
}

class ViewPageTable {
    private readonly definition: PanelDefinition;
    private readonly table: TablePanel;
    private filterControls = {};

    constructor(definition: PanelDefinition, onRefresh?: () => void) {
        this.definition = definition;
        this.table = new TablePanel(onRefresh);
        PageNavigator.addDisposableElement(this.table);
    }

    async appendTo(parent: JQuery): Promise<void> {
        this.filterControls =
            DashboardHelper.addFiltersRow(this.definition.Filters, parent, () => this.refresh());
        this.table.appendTo(parent, () => this.getData());
        await this.refresh();
    }

    async refresh(): Promise<void> {
        await this.table.refresh();
    }

    private getData(): Promise<SimpleTableData> {
        const filterValues = {};
        for (const filterName in this.filterControls) {
            filterValues[filterName] = this.filterControls[filterName].getValue();
        }
        return Requester.post(this.definition.DataUrl, filterValues);
    }
}
