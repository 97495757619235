import * as $ from "jquery";
import { Helper } from "../common/helper";
import { ViewCellInfo } from "./view-cell-builder";
import { StorageHelper, StorageItem } from "../common/storage-helper";
import { Language } from "../common/text";

export class ViewTableHelper {
    private static readonly estonianLanguage = {
        sProcessing: "Palun oota, koostan nimekirja...",
        sLengthMenu: "Näita kirjeid _MENU_ kaupa",
        sZeroRecords: "Otsitavat vastet ei leitud",
        sInfo: "Kuvatakse kirjeid _START_ kuni _END_ kokku _TOTAL_ kirjest",
        sInfoEmpty: "Otsinguvasteid ei leitud",
        sInfoFiltered: "(filteeritud _MAX_ kirje seast)",
        sInfoPostFix: "",
        sSearch: "Otsi kõiki kirjeid",
        oPaginate: {
            sFirst: "Algus",
            sPrevious: "Eelmine",
            sNext: "Järgmine",
            sLast: "Viimane"
        }
    };

    static getLanguage() {
        return (StorageHelper.get(StorageItem.language) === Language.estonian) ? ViewTableHelper.estonianLanguage : "";
    }

    static setColumns(columnsInfo, options) {
        const names = {};
        const columns = [];
        let order: any = [];
        $.each(columnsInfo.Columns,
            function (nr, columnData) {
                names[columnData.DisplayName] = columnData.Name;
                const column = <any>{
                    data: columnData.Name,
                    name: columnData.Name,
                    title: `${columnData.DisplayName} `,
                    orderable: columnData.Sortable,
                    // Needed for browser side sorting and filtering
                    render: function (value: ViewCellInfo) {
                        if (columnData.ContainsBooleans) {
                            return Helper.escapeHtml(value.DisplayValue.Text ?? value.DisplayValue.Description ??
                                value.DisplayValue.Icons[0]?.Description);
                        }
                        return Helper.escapeHtml(value.DisplayValue.Text);
                    },
                    responsivePriority: columnData.PrioritizeVisibility ? 0 : 1
                };
                if (columnData.ContainsDates) {
                    column.type = "customDate";
                }
                if (columnData.ContainsRealNumbers &&
                    (StorageHelper.get(StorageItem.language) === Language.estonian ||
                        StorageHelper.get(StorageItem.language) === Language.russian)) {
                    column.type = "numeric-comma";
                }
                columns.push(column);
                if (columnData.Name === columnsInfo.DefaultSortColumn) {
                    order = [
                        [nr, columnsInfo.DefaultSortDescending ? "desc" : "asc"]
                    ];
                }
            });
        options.columns = columns;
        options.order = order;
        return names;
    }

    static addSortingMethods() {
        $.fn.dataTableExt.oSort["customDate-asc"] = (x, y) => {
            if (!Helper.hasValue(x)) {
                x = 0;
            } else {
                x = this.parseDate(x.split(" ")[0]).getTime();
            }
            if (!Helper.hasValue(y)) {
                y = 0;
            } else {
                y = this.parseDate(y.split(" ")[0]).getTime();
            }
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        };
        $.fn.dataTableExt.oSort["customDate-desc"] = (x, y) => {
            if (!Helper.hasValue(x)) {
                x = 0;
            } else {
                x = this.parseDate(x.split(" ")[0]).getTime();
            }
            if (!Helper.hasValue(y)) {
                y = 0;
            } else {
                y = this.parseDate(y.split(" ")[0]).getTime();
            }
            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        };
        $.fn.dataTableExt.oSort["numeric-comma-pre"] = a => {
            if (a === null || a === "") {
                return Number.MIN_VALUE;
            }
            a = +(a.replace(",", "."));
            a = (isNaN(a)) ? Number.MAX_VALUE : a;
            return a;
        };
        $.fn.dataTableExt.oSort["numeric-comma-asc"] = (a, b) => {
            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
        };
        $.fn.dataTableExt.oSort["numeric-comma-desc"] = (a, b) => {
            return ((a < b) ? 1 : ((a > b) ? -1 : 0));
        };
    }

    private static parseDate(dateString) {
        const day = dateString.split(".")[0];
        const month = dateString.split(".")[1];
        const year = dateString.split(".")[2];
        return new Date(`${year}-${month}-${day}`);
    }
}
