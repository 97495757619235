import { Helper } from "./helper";
import { Requester } from "./requester";

export class VersionChecker {
    private static lastCheckTime: number;
    private static version: string;

    static async checkAndRefreshIfNeeded() {
        const currentTime: number = Date.now();
        if (!this.version) {
            try {
                this.version = await Requester.get("public/versionHash");
            } catch {
                return;
            }
            this.lastCheckTime = currentTime;
            return;
        }
        // Check version if last check time is older than 2 minutes
        if ((currentTime - this.lastCheckTime) > 120 * 1000) {
            let newVersion: string;
            try {
                newVersion = await Requester.get("public/versionHash");
            } catch {
                return;
            }
            if (newVersion !== this.version) {
                Helper.refreshPage();
            }
            this.lastCheckTime = currentTime;
        }
    }
}
