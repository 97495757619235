import { C } from "../../common/component";
import { Txt } from "../../common/text";
import { MessageDisplayer } from "../../common/message-displayer";
import { EditControl } from "../form-row";

export class PasswordEditControl implements EditControl {
    private prefix = "<|<?<|";
    private suffix = "|>?>|>";

    private readonly input: JQuery;
    private readonly inputRow: JQuery;
    private readonly input2: JQuery;

    constructor(twoInputs?: boolean) {
        this.inputRow = C.div;
        this.input = C.input.addClass("form-control");

        this.input.attr("type", "password")
            .attr("placeholder", Txt.password)
            .attr("autocomplete", "new-password")
            .appendTo(this.inputRow);

        if (twoInputs === true) {
            this.input2 = C.input
                .addClass("form-control")
                .attr("type", "password")
                .attr("placeholder", Txt.repeatPassword)
                .attr("autocomplete", "new-password")
                .appendTo(this.inputRow);
        }
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow[0]);
    }

    async setValue(value) {
        if (value && value.startsWith(this.prefix) && value.endsWith(this.suffix)) {
            value = value.substring(this.prefix.length, value.length - this.suffix.length);
        }
        this.input.val(value);
    }

    getValue() {
        return this.prefix + this.input.val().toString() + this.suffix;
    }

    onChange(action) {
        this.input.on("input", action);
    }

    validate(): boolean {
        if (!this.input2 || this.input.val() == this.input2.val()) {
            return true;
        }
        MessageDisplayer.showError(Txt.passwordsDontMatch);
        return false;
    }

    focus() {
        this.input.focus();
    }
}
