import * as Chart from "chart.js";
import { C } from "./component";

interface Dimension {
    DataText: string;
    Colors: string[];
    Values: number[];
}

export interface Chart {
    Labels: string[];
    Dimensions: Dimension[];
    Title: string;
    Doughnut: boolean;
    Line: boolean;
}

export class ChartTableCreator {
    static createChart(parent, chart: Chart) {
        const ctx = C.canvas.attr("width", "400").attr("height", "200").appendTo(parent);
        const chartData: any = {
            labels: chart.Labels,
            datasets: []
        };
        for (const dimension of chart.Dimensions) {
            const dataset: any = {label: dimension.DataText, data: dimension.Values, backgroundColor: dimension.Colors};
            if (chart.Line) {
                dataset.backgroundColor = dimension.Colors[0];
                dataset.borderColor = dimension.Colors[0] + "30";
                dataset.fill = false;
            }
            chartData.datasets.push(dataset);
        }
        const options: any = {};
        if (chart.Title) {
            options.title = {
                display: true,
                text: chart.Title
            };
        }
        new Chart(ctx, {
            type: chart.Doughnut ? "doughnut" : chart.Line ? "line" : "bar",
            data: chartData,
            options: options
        });
    }
}
