import { C } from "./component";
import { Icon, IconHelper } from "./icon-helper";

export interface Button {
    name: string;
    icon?: Icon;
    isSubmit?: boolean;
    onClick?: () => Promise<void>;
    isSecondary?: boolean;
    cssClass?: string;
}

export class ButtonBuilder {
    static get(button: Button): JQuery {
        const element = C.button.attr("type", button.isSubmit ? "submit" : "button")
            .addClass(button.isSecondary ? "btn btn-default" : "btn btn-primary");
        if (button.icon) {
            IconHelper.addIconWithText(element, button.icon, button.name);
        } else {
            element.text(button.name);
        }
        if (button.onClick) {
            let inMiddleOfClick = false;
            element.on("click", async () => {
                if (!inMiddleOfClick) {
                    inMiddleOfClick = true;
                    await button.onClick();
                    inMiddleOfClick = false;
                }
            });
        }
        if (button.cssClass) {
            element.addClass(button.cssClass);
        }
        return element;
    }
}
