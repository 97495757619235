export class Dom {
    static create<T extends keyof HTMLElementTagNameMap>(type: T, ...classes: string[]): HTMLElementTagNameMap[T] {
        return Dom.createElement(type, classes);
    }

    static createDiv(...classes: string[]): HTMLDivElement {
        return Dom.createElement("div", classes);
    }

    static createSpan(...classes: string[]): HTMLSpanElement {
        return Dom.createElement("span", classes);
    }

    static add<T extends keyof HTMLElementTagNameMap>(type: T, parent: HTMLElement, ...classes: string[]): HTMLElementTagNameMap[T] {
        return Dom.addElement(type, parent, classes);
    }

    static addDiv(parent: HTMLElement, ...classes: string[]): HTMLDivElement {
        return Dom.addElement("div", parent, classes);
    }

    static addSpan(parent: HTMLElement, ...classes: string[]): HTMLSpanElement {
        return Dom.addElement("span", parent, classes);
    }

    private static addElement<T extends keyof HTMLElementTagNameMap>(type: T, parent: HTMLElement, classes: string[]): HTMLElementTagNameMap[T] {
        const element = Dom.createElement(type, classes);
        parent.append(element);
        return element;
    }

    private static createElement<T extends keyof HTMLElementTagNameMap>(type: T, classes: string[]): HTMLElementTagNameMap[T]  {
        const element = document.createElement(type);
        for (const className of classes) {
            element.classList.add(className);
        }
        return element;
    }
}
