import { C } from "../../common/component";
import { Icon, IconHelper } from "../../common/icon-helper";
import { ModalBuilder } from "../../common/modal-builder";
import { Txt } from "../../common/text";
import { EditControl } from "../form-row";

export class TranslationsEditControl implements EditControl {
    private readonly inputRow: JQuery;
    private readonly inputs: { [code: string]: JQuery } = {};

    private modal;

    constructor(title: string, languages: { [code: string]: string }, numberOfTextBoxRows: number) {
        this.inputRow = C.div;
        function getInput() {
            if (numberOfTextBoxRows && numberOfTextBoxRows > 1) {
                return C.textarea.addClass("form-control").attr("rows", numberOfTextBoxRows);
            }
            return C.input.addClass("form-control").attr("type", "text");
        }
        this.inputs["default"] = getInput();
        for (const language in languages) {
            this.inputs[language] = getInput();
        }

        const inputGroup = C.div.addClass("input-group").appendTo(this.inputRow);
        this.inputs["default"].appendTo(inputGroup);
        const addon = C.span.addClass("input-group-addon").appendTo(inputGroup);
        C.span.addClass(IconHelper.getIconClass(Icon.language)).appendTo(addon);

        addon.click(() => {
            if (!this.modal) {
                this.modal = ModalBuilder.createModal(title);
                for (const language in languages) {
                    const group = C.div.addClass("form-group").appendTo(this.modal.body);
                    C.label.text(languages[language]).addClass("control-label").appendTo(group);
                    this.inputs[language].appendTo(group);
                }
                C.button.addClass("btn btn-primary main-button").attr("type", "button").text(Txt.close)
                    .click(() => this.modal.container.modal("hide")).appendTo(this.modal.body);
            }
            this.modal.container.modal({show: true});
        });
    }

    initialize(parent: HTMLElement) {
        parent.append(this.inputRow[0]);
    }

    async setValue(value: string) {
        const dict = value ? JSON.parse(value) : {};
        for (const language in this.inputs) {
            this.inputs[language].val(dict[language]);
        }
    }

    getValue() {
        const result = {};
        for (const language in this.inputs) {
            result[language] = this.inputs[language].val().toString();
        }
        return JSON.stringify(result);
    }

    onChange(action) {
        this.inputs["default"].on("input", action);
    }

    focus() {
        this.inputs["default"].focus();
    }
}
