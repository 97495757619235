import "bootstrap-notify";
import Swal, { SweetAlertOptions } from "sweetalert2";
import * as $ from "jquery";
import { Icon, IconHelper } from "./icon-helper";
import { Txt } from "./text";
import { Helper } from "./helper";

export enum MessageType {
    success = "Success",
    warning = "Warning",
    error = "Error",
    info = "Info",
    dialog = "Dialog"
}

export interface Message {
    Text: string;
    Type: MessageType;
}

export interface Dialog {
    Title: string;
    Text: string;
    ShowSuccessIcon: boolean;
    IsTextHtml: boolean;
}

export class MessageDisplayer {
    static showErrorFromServer(error) {
        if (error.status === 0) {
            Swal.fire({
                title: Txt.networkError,
                imageUrl: "images/error.png"
            });
        } else if (error.status === 400) {
            MessageDisplayer.showError(error.responseText);
        } else {
            this.showGenericError();
        }
    }

    static showGenericError() {
        Swal.fire({
            text: Txt.someError,
            imageUrl: "images/error.png"
        });
    }

    static showSuccessDialog(message): void {
        Swal.fire({title: message.Title, text: message.Text, icon: "success"});
        return;
    }

    static showDialog(dialog: Dialog): void {
        const options: SweetAlertOptions = {};
        if (dialog.IsTextHtml) {
            options.html = Helper.removeScriptTags(dialog.Text);
        } else {
            options.text = dialog.Text;
        }
        if (dialog.Title) {
            options.title = dialog.Title;
        }
        if (dialog.ShowSuccessIcon) {
            options.icon = "success";
        }
        Swal.fire(options);
    }

    static showSuccess(message: string): void { this.showMessage({ Text: message, Type: MessageType.success }); }

    static showWarning(message: string): void { this.showMessage({ Text: message, Type: MessageType.warning }); }

    static showError(message: string): void { this.showMessage({ Text: message, Type: MessageType.error }); }

    static showMessage(message: Message): void {
        if (message.Type === MessageType.dialog) {
            Swal.fire({ text: message.Text, allowOutsideClick: false });
            return;
        }
        let icon: Icon;
        let type: string;
        let delay = 5000;
        if (message.Type === MessageType.success) {
            icon = Icon.thumbsUp;
            type = "success";
            delay = 2000;
        } else if (message.Type === MessageType.warning) {
            icon = Icon.warning;
            type = "warning";
        } else if (message.Type === MessageType.error) {
            icon = Icon.alert;
            type = "danger";
        } else if (message.Type === MessageType.info) {
            icon = Icon.InfoCircle;
            type = "info";
        } else {
            throw new Error("Unexpected message type");
        }
        $.notify({
            icon: IconHelper.getIconClass(icon),
            message: message.Text
        }, {
            type: type,
            offset: 20,
            spacing: 10,
            z_index: 2000,
            delay: delay,
            timer: 1000,
            placement: {
                from: "top",
                align: "left"
            },
        });
    }
}
