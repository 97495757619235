import { C } from "../common/component";
import { FormRowType } from "./form-row-type";
import { BooleanEditControl } from "./edit-controls/boolean-edit-control";
import { DateRangeEditControl } from "./edit-controls/date-range-edit-control";
import { DateEditControl } from "./edit-controls/date-edit-control";
import { DateTimeEditControl } from "./edit-controls/date-time-edit-control";
import { InputControl } from "./edit-controls/input-control";
import { MarkdownEditControl } from "./edit-controls/markdown-edit-control";
import { SuggestionsEditControl } from "./edit-controls/suggestions-edit-control";
import { TextareaControl } from "./edit-controls/textarea-control";
import { TimeEditControl } from "./edit-controls/time-edit-control";
import { ViewCellEditControl } from "./edit-controls/view-cell-edit-control";
import { FileEditControl } from "./edit-controls/files/file-edit-control";
import { MultiFileEditControl } from "./edit-controls/files/multi-file-edit-control";
import { SignatureEditControl } from "./edit-controls/signature-edit-control";
import { SeatingPlanEditControl } from "./edit-controls/seating-plan-edit-control";
import { ColorEditControl } from "./edit-controls/color-edit-control";
import { PasswordEditControl } from "./edit-controls/password-edit-control";
import { TranslationsEditControl } from "./edit-controls/translations-edit-control";
import { Icon, IconHelper } from "../common/icon-helper";
import { EditControl } from "./form-row";
import { SelectControl } from "./edit-controls/select-control";

export class EditControlBuilder {
    static create(typeData, unit?, getFieldValue?: (fieldName: string) => string, placeholder?: string, isCompact: boolean = false, isRequired: boolean = true, triggerMainButton: () => void = null): EditControl {
        if (getFieldValue == null) {
            getFieldValue = _ => null;
        }
        if (typeData.Type === FormRowType.FilterChoice || typeData.Type === FormRowType.Choice) {
            return new SelectControl(typeData.CanSelectMany, typeData.Choices ?? [], typeData.SearchUrl, typeData.ChoicesDisplayValuesUrl, getFieldValue, isCompact, placeholder, typeData.NoInputHeightLimit);
        }
        if (typeData.Type === FormRowType.Suggestions) {
            return new SuggestionsEditControl(typeData, getFieldValue);
        }
        if (typeData.Type === FormRowType.Date) {
            return new DateEditControl(placeholder);
        }
        if (typeData.Type === FormRowType.Time) {
            return new TimeEditControl(typeData.Accuracy);
        }
        if (typeData.Type === FormRowType.DateRange) {
            return new DateRangeEditControl(isRequired);
        }
        if (typeData.Type === FormRowType.DateTime) {
            return new DateTimeEditControl(typeData.Accuracy);
        }
        if (typeData.Type === FormRowType.Boolean) {
            return new BooleanEditControl(isCompact, typeData.EmptySpaceLeft ?? false);
        }
        if (typeData.Type === FormRowType.Signature) {
            return new SignatureEditControl();
        }
        if (typeData.Type === FormRowType.File) {
            return new FileEditControl(false);
        }
        if (typeData.Type === FormRowType.UnlimitedFile) {
            return new FileEditControl(false, true);
        }
        if (typeData.Type === FormRowType.Files) {
            return new MultiFileEditControl();
        }
        if (typeData.Type === FormRowType.Image) {
            return new FileEditControl(true);
        }
        if (typeData.Type === FormRowType.Note) {
            return new TextareaControl();
        }
        if (typeData.Type === FormRowType.Markdown) {
            return new MarkdownEditControl();
        }
        if (typeData.Type === FormRowType.ViewCell) {
            return new ViewCellEditControl();
        }
        if (typeData.Type === FormRowType.SeatingPlan) {
            return new SeatingPlanEditControl(getFieldValue);
        }
        if (typeData.Type === FormRowType.Color) {
            return new ColorEditControl();
        }
        if (typeData.Type === FormRowType.Password) {
            return new PasswordEditControl(typeData.TwoInputs);
        }
        if (typeData.Type === FormRowType.Translations) {
            return new TranslationsEditControl(typeData.Title, typeData.Languages, typeData.NumberOfTextBoxRows);
        }
        return new InputControl(typeData.Type, unit, typeData.MinValue, typeData.MaxValue, typeData.TriggerMainButtonOnEnter,
            triggerMainButton);
    }

    static createGroupElement(parent, name, collapseByDefault: boolean) {
        const header = C.legend.addClass("group-element clickable").appendTo(parent);
        const container = C.fieldset.appendTo(parent);
        IconHelper.addIconWithText(header, collapseByDefault ? Icon.angleRight : Icon.angleDown, name);
        let shown = !collapseByDefault;
        container.collapse();
        header.click(function () {
            if (shown) {
                IconHelper.addIconWithText(header, Icon.angleRight, name);
                container.collapse("hide");
                shown = false;
            } else {
                IconHelper.addIconWithText(header, Icon.angleDown, name);
                container.collapse("show");
                shown = true;
                // This is hack because collapse("hide") does not work initially for some reason
                container.show();
            }
        });
        if (collapseByDefault) {
            // This is hack because collapse("hide") does not work initially for some reason
            container.hide();
        }
        return container;
    }
}
