import { C } from "../../../common/component";
import { Icon, IconHelper } from "../../../common/icon-helper";
import { Txt } from "../../../common/text";
import { FileInfo } from "./file-info";
import { FileProgressControls } from "./file-progress-controls";
import { ImagePreviewControl } from "./image-preview-control";

export class FileControl {
    private readonly file: FileInfo;
    private readonly onChange: () => void;
    private isControlRemoved: boolean;

    constructor(file: FileInfo, onChange: () => void) {
        this.file = file;
        this.onChange = onChange;
    }

    private container: JQuery;
    private progressControls: FileProgressControls;
    private imagePreview: ImagePreviewControl;

    appendTo(parent): void {
        this.container = C.div.css("margin-bottom", "5px").appendTo(parent);
        const inputGroup = C.div.addClass("input-group").appendTo(this.container);

        this.progressControls = new FileProgressControls(inputGroup, this.container);
        this.imagePreview = new ImagePreviewControl(inputGroup);

        C.input.attr("type", "text").addClass("form-control").attr("disabled", "disabled")
            .val(this.file.getFileName()).appendTo(inputGroup);

        const clearButtonContainer = C.span.addClass("input-group-btn").appendTo(inputGroup);
        const clearButton = C.button.addClass("btn btn-default")
            .attr("type", "button")
            .click(() => this.remove())
            .appendTo(clearButtonContainer);
        IconHelper.addIconWithText(clearButton, Icon.remove, Txt.remove);

        if (this.file.hasUploader()) {
            this.onProgress();
            this.file.getUploader().upload(() => this.onProgress(), () => this.onProgress(), () => {
                this.onProgress();
                this.onChange();
            });
        }
    }

    configureImagePreview(file) {
        if (this.imagePreview) {
            this.imagePreview.configure(file);
        }
    }

    getFile(): FileInfo { return this.file; }

    isRemoved(): boolean { return this.isControlRemoved; }

    remove() {
        if (this.file.hasUploader()) {
            this.file.getUploader().cancel();
        }
        this.container.remove();
        this.isControlRemoved = true;
        this.onChange();
    }

    private onProgress() {
        if (this.progressControls && this.file.hasUploader()) {
            this.progressControls.onProgress(this.file.getUploader());
        }
    }
}
