import { C } from "../common/component";
import { Navbar } from "./navbar";
import { InitializeClockPicker } from "../external/clockpicker";
import { InitializeDateRangePicker } from "../external/daterangepicker";
import { InitializeDateTimePicker } from "../external/datetimepicker";
import { Urls } from "../common/urls";
import { Helper } from "../common/helper";
import { Requester } from "../common/requester";
import { Language, TxtInitialize } from "../common/text";
import { PageNavigator } from "./page-navigator";
import { NavigationUrlHelper } from "./navigation-url-helper";
import { Form, FormDisplayer } from "../forms/form";
import { Loader } from "../common/loader";
import { MessageDisplayer } from "../common/message-displayer";
import { Google } from "../maps/google-maps";
import { StorageHelper, StorageItem } from "../common/storage-helper";
import { PushNotifications } from "../common/push-notifications";

export class Application {
    public static defaultRadius = 500;
    public static mainElement = C.div.addClass("main");
    public static secondaryElement = C.div;

    static async initialize() {
        C.body.addClass("scrollbar");

        // Hack to prevent Chrome autofilling random textboxes with username
        C.body.append(C.input.attr("type", "text").attr("id", "username")
            .css("display", "none"));

        Loader.create(C.body);
        Loader.startGlobally(true);

        let navData;
        try {
            navData = await Requester.get(Requester.getUrl(Urls.navbar, { isMobileScreen: Helper.isMobileScreen() }));
        } catch (err) {
            MessageDisplayer.showErrorFromServer(err);
            return;
        }

        StorageHelper.set(StorageItem.language, navData.language);
        await TxtInitialize(navData.language);

        // HACK: Make back and forward button to work
        setInterval(() => {
            if (NavigationUrlHelper.hasPageChanged()) {
                // TODO: Use this (currently the problem is that it does not close forms)
                // const currentPage = NavigationUrlHelper.getCurrentPage();
                // PageNavigator.navigateToCurrentPage(currentPage);
                Helper.refreshPage();
            }
        }, 100);

        InitializeClockPicker();
        InitializeDateTimePicker();
        InitializeDateRangePicker();
        await Google.initialize();

        if (navData.helpChatInfo) {
            this.loadHelpChat(navData.helpChatInfo, navData.language);
        }
        if (navData.debtText) {
            C.h5.text(navData.debtText).css("color", "white").appendTo(
                C.div.addClass("demo-data-info")
                    .css("background-color", "red")
                    .css("cursor", "pointer")
                    .click(() => PageNavigator.navigateToPage(navData.debtPage)).appendTo(C.body));
        }
        if (navData.environment === "Development" || navData.environment === "Test") {
            C.div.addClass("demo-data-info")
                .css("background-color", navData.environment === "Development" ? "black" : "blue").appendTo(C.body);
        }

        PageNavigator.initialize(navData.pages, navData.navigationBar, navData.homePage);
        new Navbar(navData.navigationBar, navData.hasTimer, navData.timerPage, navData.pages, navData.profile, navData.notificationsPage)
            .appendTo(C.body);
        PageNavigator.navigateToPageUsingInfo(NavigationUrlHelper.getCurrentPage());
        if (navData.initialForm) {
            FormDisplayer.openInModal(new Form(navData.initialForm));
        }

        PushNotifications.ensureRegistration();
    }

    private static async loadHelpChat(info, language): Promise<void> {
        try {
            await Helper.loadScript("https://widget.intercom.io/widget/zyol1sbh");
                (<any>window).Intercom("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "zyol1sbh",
                    user_id: info.Identifier,
                    name: info.Name,
                    email: info.Email,
                    created_at: info.CreatedAt,
                    language_override: language === Language.estonian ? "et" : "en"
                });
        } catch {
            console.log("Loading help chat failed");
        }
    }
}
