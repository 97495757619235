import { C } from "../common/component";
import { ModalBuilder } from "../common/modal-builder";
import { SheetClip } from "../external/sheetclip";
import { Txt } from "../common/text";
import { TimesheetShortcutsSet } from "./timesheet-shortcuts-set";
import { TimesheetRightTableDataManager } from "./timesheet-right-table-data-manager";
import { TimesheetCommentsManager } from "./timesheet-comments-manager";

export class TimesheetSelectedCells {
    startRow: number;
    endRow: number;
    startColumn: number;
    endColumn: number;

    isSingleCell() {
        return this.startRow === this.endRow && this.startColumn === this.endColumn;
    }
}

export class TimesheetRightTableConfigurer {
    static configureCopyAndPasteButtons(configuration): void {
        // This clipboardCache is needed for Paste button to work.
        // Browser security measures prevent us from accessing operating system clipboard.
        // So we have to store clipboard data ourself. Ctrl+V is working without this hack.
        let clipboardCache = "";
        configuration.afterCopy = changes => clipboardCache = SheetClip.stringify(changes);
        configuration.afterCut = changes => clipboardCache = SheetClip.stringify(changes);
        configuration.afterPaste = changes => clipboardCache = SheetClip.stringify(changes);
        configuration.contextMenu.items.copy = {name: Txt.copy};
        configuration.contextMenu.items.paste = {
            key: "paste",
            name: Txt.paste,
            disabled: function () {
                return clipboardCache.length === 0;
            },
            callback: function (item, item2, item3) {
                console.log(item);
                const plugin = this.getPlugin("copyPaste");
                this.listen();
                plugin.paste(clipboardCache);
            }
        };
    }

    static configureComments(configuration, commentsManager: TimesheetCommentsManager,
                             dataManager: TimesheetRightTableDataManager): void {
        const disabledFunction = function () {
            const coordinates = this.getSelectedLast();
            const rowNumber = coordinates[0];
            return !commentsManager.isCommentEditable(rowNumber);
        };
        configuration.contextMenu.items["editComment"] = {
            name:  Txt.addComment,
            callback: function () {
                const cells = TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast());
                const cell = C.create(this.getCell(cells.startRow, cells.startColumn));
                // setTimeout is needed to ensure that the contextMenu is closed first and will not take focus away from comment input
                setTimeout(() => commentsManager.openNewComment(cell, cells.startRow, cells.startColumn), 0);
            },
            disabled: disabledFunction,
            hidden: function () {
                return !TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast()).isSingleCell();
            }
        };
        configuration.contextMenu.items.changeRowComment = {
            name: Txt.changeRowComment,
            disabled: disabledFunction,
            callback: function () {
                const table = this;
                const rowNumber = table.getSelected()[0][0];
                const modal = ModalBuilder.createModal(Txt.comment);
                modal.body.addClass("change-password");
                const comment = C.input.addClass("form-control")
                    .attr("type", "text")
                    .attr("placeholder", Txt.comment)
                    .val(commentsManager.getComment(rowNumber, null))
                    .appendTo(modal.body);
                C.button
                    .addClass("btn btn-primary main-button")
                    .attr("type", "button")
                    .text(Txt.save)
                    .click(async function () {
                        const commentString = <string>comment.val();
                        await commentsManager.saveComment(rowNumber, null, commentString);
                        table.updateSettings({
                            rowHeaders: (rn) => dataManager.getRowHeader(rn, commentsManager),
                        });
                        ModalBuilder.removeModal(modal.container);
                    }).appendTo(modal.body);
                modal.container.modal({
                    backdrop: "static",
                    keyboard: true,
                    show: true
                });
            }
        };
    }

    static addCustomButton(configuration, internalName: string,
                           getName: (selectedCells: TimesheetSelectedCells) => string,
                           onClick: (selectedCells: TimesheetSelectedCells) => void,
                           isDisabled: (selectedCells: TimesheetSelectedCells) => boolean,
                           isHidden: (selectedCells: TimesheetSelectedCells) => boolean): void {
        configuration.contextMenu.items[internalName] = {
            name: function () {
                return getName(TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast()));
            },
            callback: function () {
                onClick(TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast()));
            },
            disabled: function () {
                return isDisabled(TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast()));
            },
            hidden: function () {
                return isHidden(TimesheetRightTableConfigurer.getSelectedCells(this.getSelectedLast()));
            }
        };
    }

    static configureShortcuts(configuration, shortcuts: TimesheetShortcutsSet): void {
        for (const group of shortcuts.getDisplayGroups()) {
            configuration.contextMenu.items[group.type] = {
                name: group.displayName,
                submenu: {
                    items: group.displayedShortcuts.map((shortcut) => {
                        return {
                            key: group.type + ":" + shortcut.Abbreviation,
                            name: shortcut.Name + (shortcut.Abbreviation ? " (" + shortcut.Abbreviation + ")" : null),
                            callback: function(key) {
                                const value = key.split(":")[1];
                                const coordinates = this.getSelectedLast();
                                const row = coordinates[0];
                                const col = coordinates[1];
                                if (value) {
                                    this.setDataAtCell(row, col, value);
                                }
                            },
                        };
                    }),
                },
            };
        }
    }

    private static getSelectedCells(coordinates: number[]): TimesheetSelectedCells {
        const selectedCells = new TimesheetSelectedCells();
        if (coordinates[0] > coordinates[2]) {
            selectedCells.startRow = coordinates[2];
            selectedCells.endRow = coordinates[0];
        } else {
            selectedCells.startRow = coordinates[0];
            selectedCells.endRow = coordinates[2];
        }
        if (coordinates[1] > coordinates[3]) {
            selectedCells.startColumn = coordinates[3];
            selectedCells.endColumn = coordinates[1];
        } else {
            selectedCells.startColumn = coordinates[1];
            selectedCells.endColumn = coordinates[3];
        }
        return selectedCells;
    }
}
