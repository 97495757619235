import { DomainHelper } from "../common/domain-helper";
import { Loader } from "../common/loader";
import { Txt } from "../common/text";

export class LocationProvider {
    private static MAX_LOCATION_QUERIES = 2;
    private static MAX_ACCURACY = 100;
    private static MAX_AGE = 1000;

    static async tryGetLocation(): Promise<any> {
        if (!navigator.geolocation) {
            return null;
        }
        Loader.startGlobally(false, Txt.waitingGPS);
        let pos;
        for (let i = 0; i < LocationProvider.MAX_LOCATION_QUERIES; i++) {
            pos = await LocationProvider.tryGetPosition();
            if (!pos ||
                (DomainHelper.getDomain() !== "vanalinnaehitus" && DomainHelper.getDomain() !== "techgroup" ||
                    pos.coords.accuracy <= LocationProvider.MAX_ACCURACY) && (new Date().getTime() - pos.timestamp) <= LocationProvider.MAX_AGE) {
                break;
            }
        }
        Loader.stopGlobally();
        if (!pos) {
            return null;
        }
        return {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            accuracy: pos.coords.accuracy
        };
    }

    private static tryGetPosition(): Promise<GeolocationPosition> {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
                pos => resolve(pos),
                () => resolve(null),
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        });
    }
}
