import { autoUpdate, computePosition, flip } from "@floating-ui/dom";
import { Requester } from "../common/requester";
import { TimesheetRightTableColumn } from "./timesheet-models";
import { C } from "../common/component";

class CommentPopup {
    private readonly cell;
    private comment: string;
    private readonly save: (comment: string) => Promise<void>;

    private tooltipElement;
    private timeout;
    private hasFocus = false;

    constructor(cell, comment: string, save: (comment: string) => Promise<void>) {
        this.cell = cell;
        this.comment = comment;
        this.save = save;

        if (this.comment) {
            this.cell.addClass("timesheetCommentCell");
        }
        this.cell.hover(() => this.timeout = setTimeout(() => {
                if (!this.hasFocus && this.comment) {
                    if (!this.tooltipElement) {
                        this.open(false);
                    } else {
                        this.tooltipElement.css("display", "block");
                    }
                }
            }, 300),
            () => {
                if (this.comment) {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    this.timeout = setTimeout(() => {
                        if (!this.hasFocus) {
                            this.tooltipElement?.css("display", "none");
                        }
                    }, 300);
                }
            });
    }

    remove() {
        this.tooltipElement?.remove();
        console.log("removed " + this.comment);
    }

    open(focus: boolean) {
        const input = C.textarea.val(this.comment)
            .attr("rows", "5").css("resize", "none").css("width", "200px");
        this.tooltipElement = C.div.append(input)
            .css({
                "position": "absolute",
                "width": "200px",
                "top": "0",
                "left": "0"
            }).appendTo(C.body);
        autoUpdate(this.cell[0], this.tooltipElement[0], () => {
            computePosition(this.cell[0], this.tooltipElement[0], {
                placement: "right-start",
                middleware: [flip()]
            }).then(({x, y}) => {
                Object.assign(this.tooltipElement[0].style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });
            });
        });
        this.tooltipElement.hover(() => {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        }, () => {
            this.timeout = setTimeout(() => {
                if (!this.hasFocus) {
                    this.tooltipElement.css("display", "none");
                }
            }, 300);
        });
        input.on("focus", () => {
            console.log("focus");
            this.hasFocus = true;
        });
        input.on("blur", async () => {
            console.log("blur called");
            const newComment = <string>input.val();
            await this.save(newComment);
            this.tooltipElement.css("display", "none");
            this.comment = newComment;
            if (newComment) {
                this.cell.addClass("timesheetCommentCell");
            } else {
                this.cell.removeClass("timesheetCommentCell");
            }
            console.log("blur ended");
            this.hasFocus = false;
        });
        if (focus) {
            // setTimeout is needed because otherwise browser will scroll up
            // (because initially input is in the beginning of page, so we need to focus it after it has been set to right place)
            setTimeout(() => {
                this.tooltipElement.css("display", "block");
                input[0].focus();
            }, 0);
        }
    }
}

export class TimesheetCommentsManager {
    private readonly columns: TimesheetRightTableColumn[];
    private readonly rows: string[];
    private readonly comments: { [rowId: string]: { [date: string]: string } };
    private readonly commentSaveUrl: string;
    private readonly editableComments: string[];

    private readonly commentPopups: { [rowNumber: number]: { [columnNumber: number]: CommentPopup } } = {};

    constructor(columns: TimesheetRightTableColumn[], rows: string[],
                comments: { [rowId: string]: { [date: string]: string } }, commentSaveUrl: string,
                editableComments: string[]) {
        this.columns = columns;
        this.rows = rows;
        this.comments = comments;
        this.commentSaveUrl = commentSaveUrl;
        this.editableComments = editableComments;
        for (let i = 0; i < rows.length; i++) {
            this.commentPopups[i] = {};
        }
    }

    configureComment(cell, rowNumber: number, columnNumber: number) {
        this.addPopup(cell, rowNumber, columnNumber, false);
    }

    openNewComment(cell, rowNumber: number, columnNumber: number) {
        this.addPopup(cell, rowNumber, columnNumber, true).open(true);
    }

    private addPopup(cell, rowNumber: number, columnNumber: number, addEvenIfNoComment: boolean): CommentPopup {
        const comment = this.getComment(rowNumber, columnNumber);
        const popup = addEvenIfNoComment || comment
            ? new CommentPopup(cell, comment, x => this.saveComment(rowNumber, columnNumber, x))
            : null;
        const oldPopup = this.commentPopups[rowNumber][columnNumber];
        this.commentPopups[rowNumber][columnNumber] = popup;
        if (oldPopup) {
            oldPopup.remove();
        }
        return popup;
    }

    getComment(rowNumber: number, columnNumber: number) {
        const rowComments = this.comments[this.rows[rowNumber]];
        if (rowComments) {
            const date = columnNumber == null ? "" : this.columns[columnNumber].Date;
            return rowComments[date];
        }
        return null;
    }

    isCommentEditable(rowNumber: number): boolean {
        return this.editableComments.includes(this.rows[rowNumber]);
    }

    async saveComment(rowNumber: number, columnNumber: number, comment: string): Promise<void> {
        const date = columnNumber == null ? "" : this.columns[columnNumber].Date;
        const row = this.rows[rowNumber];
        this.comments[row][date] = comment;
        await Requester.post(
            Requester.getUrl(this.commentSaveUrl, { date: date, row: row }),
            comment, { dataType: null });
    }
}
