import { Helper } from "../common/helper";
import { C } from "../common/component";
import { PageNavigator } from "./page-navigator";
import { NavigationItem } from "./navbar";
import { NavigationUrlHelper } from "./navigation-url-helper";

export class MenuBuilder {
    static createMenuElement(data: NavigationItem, menuParent, percentage) {
        const menuSmallParent = C.li.appendTo(menuParent);
        const link = C.a.text(data.DisplayName).appendTo(menuSmallParent);
        if (Helper.isMobileScreen()) {
            link.attr("data-toggle", "collapse").attr("data-target", "#navbar");
        }

        link.click(() => {
            PageNavigator.navigateToPage(data.Pages[0], data.Name);
            C.openedDropdown.removeClass("open");
            return false;
        }).attr("href",
            `#${NavigationUrlHelper.getPageHash(PageNavigator.getPageInfo(data.Pages[0], data.Name))}`);

        if (data.Pages.length > 1) {
            const submenu = C.ul.addClass("dropdown-menu sub-menu").css("top", `${percentage}%`)
                .appendTo(menuSmallParent);
            for (const page of data.Pages) {
                const linkSupport = C.li.appendTo(submenu);
                C.a.click(() => {
                    PageNavigator.navigateToPage(page, data.Name);
                    C.openedDropdown.removeClass("open");
                    return false;
                }).text(PageNavigator.getDisplayName(page)).appendTo(linkSupport)
                    .attr("href",
                        `#${NavigationUrlHelper.getPageHash(PageNavigator.getPageInfo(page, data.Name))}`);
            }
        }
    }
}
