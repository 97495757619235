import { Helper } from "../common/helper";
import { Icon, IconHelper } from "../common/icon-helper";
import { C } from "../common/component";
import { Txt } from "../common/text";
import { PageInfo, PageNavigator, PageType } from "./page-navigator";
import { NavigationUrlHelper } from "./navigation-url-helper";
import { ButtonBuilder } from "../common/button-builder";

export class TabsBuilder {
    static async build(pageInfos: PageInfo[], selectedPage: string, parent, headerName?: string) {
        if (headerName) {
            C.h5.addClass("header-experimental").text(headerName).appendTo(parent);
        }

        const tabLinks = C.ul.addClass("nav nav-tabs").appendTo(parent);

        if (headerName) {
            tabLinks.addClass("nav-tabs-experimental");
        }

        const tabContent = C.div.addClass("tab-content").appendTo(parent);

        let includesDashboard = false;
        for (const tab of pageInfos) {
            const nr = Helper.guid();
            const tabLink = C.li;
            const tabPanel = C.div
                .addClass("tab-pane")
                .attr("role", "tabpanel")
                .attr("id", nr)
                .appendTo(tabContent);
            tabLink.attr("role", "presentation").on("shown.bs.tab", async () => {
                tabPanel.empty();
                NavigationUrlHelper.setSubPage(null);
                await PageNavigator.navigateToTab(tab.Name, tabPanel);
            }).appendTo(tabLinks);
            const innerLink = C.a.attr("href", `#${nr}`)
                .attr("aria-controls", nr)
                .attr("data-toggle", "tab")
                .appendTo(tabLink);
            IconHelper.addIconWithText(innerLink, Icon[tab.Icon], tab.DisplayName);

            if (selectedPage === tab.Name) {
                tabLink.addClass("active");
                tabPanel.addClass("active");
                await PageNavigator.navigateToTab(tab.Name, tabPanel);
            }
            if (tab.Type === PageType.Dashboard) {
                includesDashboard = true;
            }
        }

        const lastTabLink = C.li.addClass("dropdown more-tabs").appendTo(tabLinks);
        C.a.attr("data-toggle", "dropdown")
            .text(Txt.moreUppercase + " ").append(C.span.addClass("caret"))
            .appendTo(lastTabLink);
        const dropdownMenu = C.ul.addClass("dropdown-menu dropdown-menu-right").appendTo(lastTabLink);

        if (!includesDashboard) {
            const rightTab = C.li.addClass("rightside").appendTo(tabLinks);
            ButtonBuilder.get({name: Txt.toDashboard, icon: Icon.home, cssClass: "btn-dashboard"})
                .attr("role", "presentation")
                .click(async () => PageNavigator.navigateToHome()).appendTo(rightTab);
        }

        const collapseTabs = (currentHeight) => {
            if (currentHeight > 50) {
                const children = tabLinks.children("li");
                C.create(children[children.length - 3]).prependTo(dropdownMenu);
                if (children.length > 3) {
                    collapseTabs(tabLinks.height());
                }
            }
        };

        const height = tabLinks.height();
        if (height <= 50) {
            lastTabLink.hide();
        } else {
            collapseTabs(height);
        }
    }
}
